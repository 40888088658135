import "../../../Assets/Styles/ForPatients.css";
import { useTranslation } from "react-i18next";
import { ConfirmFailedImg } from "../../../Assets/Images/img";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../Utils/Routes";


export default function ClinicConfirmRejected() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="list">
        <div className="list-heading">
          <p>{i18n.t("Failed to register clinic")}</p>
          <div className="list-subtitle">
            {i18n.t("Clinic account with this email is already registered. Use your username and password to log in.")}
          </div>
        </div>
        <img alt="logo" className="list-img" width="224"
             src={ConfirmFailedImg}
        />
        <div className="list-img-heading">{i18n.t("This clinic account is already exist.")}</div>
        <button className="for-patients-btn btn scale-btn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate(paths.clinicAd);
                }}
        >
          {i18n.t("OK")}
        </button>
      </div>
    </>
  );
}
