import "../../Assets/Styles/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Store/actions/userActions";
import { HeaderNavigation } from "./HeaderNavigation";
import { paths } from "../../Utils/Routes";
import { EmptyUserProfileImgLight, LogoImg } from "../../Assets/Images/img";
import { useEffect, useRef, useState } from "react";
import { bellTypes, PermissionsArray, RoleArray } from "../../Utils/constants";
import { Bell } from "./сomponents/Bell";

export default function Header({ isHeaderHidden }) {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  
  const userInfo = useSelector(state => state.userReducer.user);
  
  const dropdownRef = useRef();
  const [openDropdown, setOpenDropdown] = useState(null);
  
  useEffect(() => {
    if (openDropdown) {
      document.addEventListener('mousedown', closeOpenDropdown)
    } else {
      document.removeEventListener('mousedown', closeOpenDropdown)
    }
  }, [openDropdown])
  
  const logout = (event) => {
    event.preventDefault();
    
    dispatch(logOut());
    navigate(paths.login);
  };
  
  const isLinkActive = (headerItem) => {
    return window.location.pathname === headerItem ? "header-actions-item-active" : "header-actions-item"
  }
  
  const closeOpenDropdown = (event) => {
    if (dropdownRef?.current && openDropdown && !dropdownRef.current?.contains(event.target)) {
      setOpenDropdown(false)
    }
  }
  
  return (
    <header>
      <div className='header-sect'>
        {(!isHeaderHidden && userInfo.role) ?
          <Link to={paths.main}>
            <img alt="logo" src={LogoImg}/>
          </Link>
          :
          <img alt="logo" src={LogoImg}/>
        }
        <div className="header-actions">
          <div className="header-actions-items">
            {!isHeaderHidden && (
              <>
                {(userInfo?.role === RoleArray.SUPER_ADMIN) &&
                (
                  <>
                    <Link to={paths.main} className={isLinkActive(paths.main)}>
                      <Trans>
                        All Patients
                      </Trans>
                    </Link>
                    <Bell type={bellTypes.clinics}/>
                    <Link to={paths.clinics} className={isLinkActive(paths.clinics)}>
                      <Trans>
                        All Clinics
                      </Trans>
                    </Link>
                    <Link to={paths.employees}
                          className={isLinkActive(paths.employees)}
                    >
                      <Trans>
                        All Employees
                      </Trans>
                    </Link>
                    <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
                      <Trans>
                        All Feedbacks
                      </Trans>
                    </Link>
                  </>
                )
                }
                {userInfo.role === RoleArray.EMPLOYEE &&
                <HeaderNavigation permissions={userInfo.permissions} isLinkActive={isLinkActive}/>
                }
              </>
            )}
          </div>
          {(!isHeaderHidden && userInfo.role && userInfo.role !== RoleArray.PATIENT) &&
          <div ref={dropdownRef} className='position-relative header-avatar'>
            <div className="circle-crop" onClick={() => setOpenDropdown(openDropdown ? null : 'header-dropdown')}>
              <img alt="avatar" src={userInfo.photo_url || EmptyUserProfileImgLight}/>
            </div>
            <div className="header-dropdown"
                 style={openDropdown === 'header-dropdown' ? { display: "flex" } : { display: "none" }}
            >
              <Link to={`${paths.profile}/edit`} className="header-dropdown-item">
                <Trans>
                  My Profile
                </Trans>
              </Link>
              <Link to={`${paths.profile}/settings`} className="header-dropdown-item">
                <Trans>
                  My Settings
                </Trans>
              </Link>
              {userInfo?.permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN) &&
              (
                <>
                  <Link to={`${paths.office}/edit`} className="header-dropdown-item">
                    <Trans>
                      Clinic Profile
                    </Trans>
                  </Link>
                  <Link to={`${paths.office}/settings`} className="header-dropdown-item">
                    <Trans>
                      Clinic Settings
                    </Trans>
                  </Link>
                </>
              )}
              <div onClick={(event) => logout(event)} className="header-dropdown-item">
                <Trans>
                  Log Out
                </Trans>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </header>
  );
}
