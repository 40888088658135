import "dayjs/locale/de";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { editStatus, partialApproval, rejectDoc2DocTransfer } from "../../Store/actions/appointmentActions";
import { appointmentsTypes } from "../../Utils/constants";

export default function Reject({ item, pendingItems, setPendingItems, openDropdown, setOpenDropdown }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const [rejectMessage, setRejectMessage] = useState("");
  
  return (
    <div className="position-relative"
         style={
           openDropdown === `${item?.id}-reject` ? { display: "block" } : { display: "none" }
         }
    >
      <div className="item-dropdown dropdown-reject">
              <textarea
                name="reject_message"
                value={rejectMessage}
                maxLength="100"
                placeholder={i18n.t("Type rejection...")}
                onChange={(event) =>
                  setRejectMessage(event.target.value)
                }
              />
        <div className="dropdown-btns">
          <div
            className="cancel-btn dropdown-reject-btn btn scale-btn"
            onClick={() => {
              setOpenDropdown(null);
            }}
          >
            <Trans>Cancel</Trans>
          </div>
          <div
            className="dropdown-reject-btn btn scale-btn"
            onClick={() => {
              //we do not need to overwrite date after rejection
              const newDate = null;
  
              switch (true) {
                case item?.type === "doctor transfer" && (!item?.doctor_id || !item?.sender_id):
                  return dispatch(editStatus(appointmentsTypes[item?.type], item?.id, 2, item.user_id, pendingItems, setPendingItems, newDate, rejectMessage, i18n));
                case item?.type === "doctor transfer" && !!item?.doctor_id && !!item?.sender_id:
                  return dispatch(rejectDoc2DocTransfer(item?.id, item?.sender_id, rejectMessage, i18n, item?.doctor_id, pendingItems, setPendingItems));
                case item?.type === "appointment":
                  return dispatch(editStatus(appointmentsTypes[item?.type], item?.id, 2, item.user_id, pendingItems, setPendingItems, newDate, rejectMessage, i18n));
                case item?.type === "drug order":
                  return dispatch(partialApproval(item?.id, 2, newDate, rejectMessage, item?.drugToOrders, item.user_id, pendingItems, setPendingItems, i18n))
              }
            }}
          >
            <Trans>Confirm rejection</Trans>
          </div>
        </div>
        <div className="dropdown-message"
             onClick={() => setRejectMessage(i18n.t("Not the right medicine"))}
        >
          <Trans>Not the right medicine</Trans>
        </div>
        <div className="dropdown-message"
             onClick={() => setRejectMessage(i18n.t("Bad for patient"))}
        >
          <Trans>Bad for patient</Trans>
        </div>
        <div className="dropdown-message"
             onClick={() => setRejectMessage(i18n.t("Not the right amount"))}
        >
          <Trans>Not the right amount</Trans>
        </div>
        <div className="dropdown-message"
             onClick={() => setRejectMessage(i18n.t("Patient is allergic to this medicine"))}
        >
          <Trans>Patient is allergic to this medicine</Trans>
        </div>
      </div>
    </div>
  );
}
