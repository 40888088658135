import { ACTIONS } from "../../Utils/constants";
import { InviteEmployeeComponentKeys } from "../../Utils/ConctantsInviteEmployee";

const initialState = {
  component: InviteEmployeeComponentKeys.WELCOME_EMPLOYEE,
  data: {}
};


const inviteEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.INVITE_EMPLOYEE.SET_EMPLOYEE_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    }
    case ACTIONS.INVITE_EMPLOYEE.SET_EMPLOYEE_COMPONENT: {
      return {
        ...state,
        component: action.payload
      }
    }
    
    default:
      return state;
  }
};

export default inviteEmployeeReducer;
