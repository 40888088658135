import "../../../Assets/Styles/ForPatients.css";
import Header from "../../../Layouts/Header/Header";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ClinicConfirmRejected from "./ClinicConfirmRejected";
import ClinicConfirmPending from "./ClinicConfirmPending";

const ConfirmSect = ({ confirmStatus }) => {
  switch (confirmStatus) {
    case 'pending':
      return <ClinicConfirmPending/>;
    case 'rejected':
      return <ClinicConfirmRejected/>;
  }
}


export default function ClinicConfirm({ confirmStatus }) {
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='for-patients-sect'>
            <AppointmentStepChain active='confirm' type='add-clinic'/>
            <ConfirmSect confirmStatus={confirmStatus}/>
          </div>
        </section>
      </main>
    </>
  );
}
