import { ACTIONS } from "../../Utils/constants";

const initialState = {
  isReady: false,
  user: {
    id: "",
    first_name: "",
    last_name: "",
    birth_date: "",
    telephone: "",
    photo_url: null,
    location: "",
    role: 0,
    permissions: 0,
    email: "",
  },
  fails: 0
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.USER.SET_TOKENS: {
      const { accessToken, refreshToken, userUUID, role, permissions } = action.payload;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      return {
        ...state,
        user: {
          ...state.user,
          uuid: localStorage.getItem('uuid'),
          role,
          permissions: permissions
        },
        isReady: true
      };
    }
    case ACTIONS.USER.INCREASE_FAILS: {
      return {
        ...state,
        fails: action.payload
      }
    }
    case ACTIONS.USER.SET_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        isReady: true
      };
    }
    case ACTIONS.USER.ADD_PHOTO: {
      return {
        ...state,
        user: {
          ...state.user,
          photo_url: action.payload
        }
      };
    }
    case ACTIONS.USER.LOG_OUT: {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('uuid');
      return {
        ...state,
        user: {},
        isReady: true
      };
    }
    default:
      return state;
  }
};

export default userReducer;