import { createTheme } from "@mui/material/styles";
import { Trans } from "react-i18next";
import {
  ArchivedIco,
  CloseRedIco,
  EmptyUserProfileImgForTable,
  PartialApprovedIco,
  PendingIco
} from "../Assets/Images/img";
import React from "react";

export const isProd = window.location.host === "www.meplano.de";
export const isPreProd = window.location.host === "dev.meplano.de";

export const ACTIONS = {
  USER: {
    SET_TOKENS: "SET_TOKENS",
    SET_USER: "SET_USER",
    ADD_PHOTO: "ADD_PHOTO",
    REMOVE_PHOTO: "REMOVE_PHOTO",
    LOG_OUT: "LOG_OUT",
    INCREASE_FAILS: "INCREASE_FAILS"
  },
  DOCTOR: {
    SET_DOCTOR: "SET_DOCTOR",
    ADD_DOCTOR: "ADD_DOCTOR",
    SET_DOCTOR_APPOINTMENT_TYPES_LIST: "SET_DOCTOR_APPOINTMENT_TYPES_LIST",
    ADD_TO_DOCTOR_APPOINTMENT_TYPES_LIST: "ADD_TO_DOCTOR_APPOINTMENT_TYPES_LIST",
    REMOVE_FROM_DOCTOR_APPOINTMENT_TYPES_LIST: "REMOVE_FROM_DOCTOR_APPOINTMENT_TYPES_LIST",
    SET_DOCTORS_GROUPED: "SET_DOCTORS_GROUPED",
  },
  CLINIC: {
    SET_CLINICS: "SET_CLINICS",
    SET_PAGE: "SET_PAGE",
    SET_FILTERED_ARRAY: "SET_CLINICS_FILTERED_ARRAY",
    CLEAR_FILTERED_ARRAY: "CLEAR_FILTERED_ARRAY",
    FILTER_CLINIC_FILTERED_ARRAY: "FILTER_CLINIC_FILTERED_ARRAY",
    SET_OLD_ITEMS: "SET_OLD_ITEMS",
    SET_READY_CLINICS: "SET_READY_CLINICS",
    SET_APPOINTMENT_TYPES_LIST: "SET_APPOINTMENT_TYPES_LIST",
    SET_CLINIC_APPOINTMENT_TYPES_LIST: "SET_CLINIC_APPOINTMENT_TYPES_LIST",
    CLEAR_CLINIC_APPOINTMENT_TYPES_LIST: "CLEAR_CLINIC_APPOINTMENT_TYPES_LIST",
    ADD_TO_CLINIC_APPOINTMENT_TYPES_LIST: "ADD_TO_CLINIC_APPOINTMENT_TYPES_LIST",
    REMOVE_FROM_CLINIC_APPOINTMENT_TYPES_LIST: "REMOVE_FROM_CLINIC_APPOINTMENT_TYPES_LIST",
    SET_CLINIC: "SET_CLINIC",
    ADD_CLINIC_PHOTO: "ADD_CLINIC_PHOTO"
  },
  SPECIALTiES: {
    SET_SPECIALTiES: "SET_SPECIALTiES",
  },
  FEEDBACK: {
    SET_FILTERED_ARRAY: "SET_FILTERED_ARRAY",
    CLEAR_ARRAY: "CLEAR_ARRAY",
    SET_PAGE: "SET_PAGE"
  },
  APPOINTMENT: {
    SET_USERS_HAVING_PENDING: "SET_USERS_HAVING_PENDING",
    CLEAR_USERS: "CLEAR_USERS",
    SET_DONE_APPOINTMENTS: "SET_DONE_APPOINTMENTS",
    SET_FILTERED_DONE_APPOINTMENTS: "SET_FILTERED_DONE_APPOINTMENTS",
    SET_PENDING_APPOINTMENTS: "SET_PENDING_APPOINTMENTS",
    DELETE_USER_WITHOUT_PENDING: "DELETE_USER_WITHOUT_PENDING",
    REMOVE_FROM_LIST: "REMOVE_FROM_LIST",
    SET_PAGE: "SET_PAGE",
    CLEAR_ARRAY: "CLEAR_ARRAY",
    UPDATE_NOTICE: "UPDATE_NOTICE",
    SET_PATIENT: "SET_PATIENT",
    SET_APPOINTMENT_TYPES_LIST: "SET_APPOINTMENT_TYPES_LIST"
  },
  PATIENTS: {
    SET_PATIENTS: "SET_PATIENTS",
    SET_PAGE: "SET_PAGE",
    SET_OLD_ITEMS: "SET_OLD_ITEMS",
    SET_FILTERED_PATIENTS_ARRAY: "SET_FILTERED_PATIENTS_ARRAY",
    CLEAR_FILTERED_ARRAY: "CLEAR_FILTERED_ARRAY",
    FILTER_FILTERED_ARRAY: "FILTER_FILTERED_ARRAY",
    SET_READY_PATIENTS: "SET_READY_PATIENTS",
    SET_PATIENT_GENERAL_INFO: "SET_PATIENT_GENERAL_INFO",
    SET_PATIENT_ADDITIONAL_INFO: "SET_PATIENT_ADDITIONAL_INFO",
    SET_PATIENT_DOC2DOC_TRANSFER: "SET_PATIENT_DOC2DOC_TRANSFER",
    SET_DOC2DOC_TRANSFER: "SET_DOC2DOC_TRANSFER",
    SET_PATIENT_CARETAKERS: "SET_PATIENT_CARETAKERS",
  },
  BREADCRUMBS: {
    SET_BREADCRUMBS: "SET_BREADCRUMBS"
  },
  BOOK_APPOINTMENT: {
    SET_BREADCRUMBS: "SET_BREADCRUMBS",
    SET_DATA: "SET_DATA",
    SET_COMPONENT: "SET_COMPONENT",
    CLEAR_DATA: "CLEAR_DATA"
  },
  INVITE_EMPLOYEE: {
    SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
    SET_EMPLOYEE_COMPONENT: "SET_EMPLOYEE_COMPONENT"
  },
  INVITE_CLINIC: {
    SET_CLINIC_DATA: "SET_CLINIC_DATA",
    SET_CLINIC_COMPONENT: "SET_CLINIC_COMPONENT",
    SET_DATA: "SET_DATA",
    SET_COMPONENT: "SET_COMPONENT"
  },
  INVITE_USER: {
    SET_INVITE_USER: "SET_INVITE_USER",
    SET_CAPTCHA_TOKEN: "SET_CAPTCHA_TOKEN"
  },
  EMPLOYEES: {
    SET_EMPLOYEES: "SET_EMPLOYEES",
    SET_PAGE: "SET_PAGE",
    SET_OLD_ITEMS: "SET_OLD_ITEMS",
    SET_FILTERED_EMPLOYEES_ARRAY: "SET_FILTERED_EMPLOYEES_ARRAY",
    CLEAR_FILTERED_ARRAY: "CLEAR_FILTERED_ARRAY",
    FILTER_EMPLOYEES_FILTERED_ARRAY: "FILTER_EMPLOYEES_FILTERED_ARRAY",
    CLEAR_DATA: "CLEAR_DATA",
    SET_READY_EMPLOYEES: "SET_READY_PATIENTS",
    SET_EMPLOYEE: "SET_EMPLOYEE",
    EDIT_EMPLOYEE_STATUS: "EDIT_EMPLOYEE_STATUS",
    EDIT_EMPLOYEE_PHOTO: "EDIT_EMPLOYEE_PHOTO",
    EDIT_EMPLOYEE_PERMISSIONS: "EDIT_EMPLOYEE_PERMISSIONS",
    REMOVE_EMPLOYEE: "REMOVE_EMPLOYEE",
  }
}

export const LIMIT_ITEM_PER_PAGE = 15;

export const calculateOffset = (page) => {
  return page * LIMIT_ITEM_PER_PAGE;
}

export const theme = createTheme({
  palette: {
    success: {
      light: '#36B37E',
      main: '#36B37E',
    },
    error: {
      light: '#C14847',
      main: '#C14847',
    },
    primary: {
      main: '#47C0C1',
      light: '#47C0C1',
      dark: '#47C0C1',
      contrastText: '#fff',
    },
    info: {
      main: '#47C0C1',
      light: '#47C0C1',
      dark: '#47C0C1',
      contrastText: '#fff',
    }
  },
});

export const updateTime = (e, day, index, days, setDays) => {
  const timeValue = e.target.value;
  setDays({
    type: actionTimeTypes.UPDATE_TIME, payload: {
      index, timeValue, day
    }
  })
}

export const updateCheckedDay = (day, days, setDays) => {
  setDays({ type: actionTimeTypes.UPDATE_CHECKED_DAY, payload: day })
};

export const checkDay = (day, days, setDays) => {
  setDays({ type: actionTimeTypes.CHECK_DAY, payload: day })
};
export const addTime = (index, day, setDays, setFieldCount) => {
  setFieldCount((count) => count + 1);
  setDays({ type: actionTimeTypes.ADD_TIME, payload: { index, day } })
}

export const deleteTime = (index, day, setDays, setFieldCount) => {
  setFieldCount((count) => count - 1);
  setDays({ type: actionTimeTypes.DELETE_TIME, payload: { index, day } })
}

export const actionTimeTypes = {
  CHANGE_IS_SHOWN: 'changeIsShown',
  UPDATE_TIME: 'updateTime',
  ADD_TIME: 'ADD_TIME',
  DELETE_TIME: 'DELETE_TIME',
  UPDATE_CHECKED_DAY: 'updateCheckedDay',
  CHECK_DAY: 'CHECK_DAY',
}

export const appointmentsTypes = {
  "drug order": "drug-orders",
  "appointment": "appointments",
  "doctor transfer": "transfers"
}

export const timeBlocks = {
  1: {
    heading: "Morning Block",
    time: "10:30",
    message: "Choose time from 8:00 to 11:30",
  },
  2: {
    heading: "Afternoon Block",
    time: "14:30",
    message: "Choose time from 13:00 to 15:30"
  },
  3: {
    heading: "Evening Block",
    time: "17:30",
    message: "Choose time from 16:00 to 19:30"
  },
}

export const DaysArray = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]

export const PermissionsArray = {
  DOCTOR: 1,
  REQUEST_MANAGER: 2,
  CLINIC_ADMIN: 3,
  CAN_FIRE: 4
}

export const RoleArray = {
  SUPER_ADMIN: 1,
  EMPLOYEE: 2,
  GHOST_USER: 3,
  PATIENT: 4
}

export const StatusArray = {
  APPROVED: 1,
  CANCELED: 2,
  PENDING: 3,
  ARCHIVED: 4,
  READY_TO_PICK: 5,
  PART_APPROVE: 6
}

export const StatusInfo = ({ statusId }) => {
  switch (statusId) {
    case StatusArray.APPROVED:
      return <div className="status-approved">✓ <Trans>Approved</Trans></div>;
    case StatusArray.CANCELED:
      return (
        <div className="status-rejected">
          <img alt="icon" src={CloseRedIco}/>
          <Trans>Rejected</Trans>
        </div>
      )
    case StatusArray.PENDING:
      return (
        <div className="status-pending">
          <img alt="icon" src={PendingIco}/>
          <Trans>Pending</Trans>
        </div>
      )
    case StatusArray.ARCHIVED:
      return (
        <div className="status-archived">
          <img alt="icon" src={ArchivedIco}/>
          <Trans>Archived</Trans>
        </div>
      )
    case StatusArray.PART_APPROVE:
      return (
        <div className="status-approved">
          <img alt="icon" src={PartialApprovedIco}/>
          <Trans>Partial Approved</Trans>
        </div>
      )
  }
}

export const decodeURIFragments = (url) => {
  const regex = /%[0-9A-Fa-f]{2}/g;
  return url?.replace(regex, function (match) {
    return decodeURIComponent(match);
  });
}

export const inviteEmployeeReducerTypes = {
  UPDATE_USER_PHOTO: "UPDATE_USER_PHOTO",
  UPDATE_USER_BIRTH_DATE: "UPDATE_USER_BIRTH_DATE",
  UPDATE_NEW_PASSWORD_TYPE: "UPDATE_NEW_PASSWORD_TYPE",
  UPDATE_CONFIRM_PASSWORD_TYPE: "UPDATE_CONFIRM_PASSWORD_TYPE",
  UPDATE_IS_SHOW_ADDITIONAL_INFO: "UPDATE_IS_SHOW_ADDITIONAL_INFO",
  UPDATE_CAPTCHA_TOKEN: "UPDATE_CAPTCHA_TOKEN",
  UPDATE_SPECIALTIES: "UPDATE_SPECIALTIES"
}

export const hidePhoneNumber = (phoneNumber) => {
  return phoneNumber?.replace(/(\d{3})\d+(\d{2})/, "$1***$2");
};

export const hideEmail = (email) => {
  const atIndex = email.indexOf('@');
  if (atIndex > 0) {
    const [username, domain] = [email.slice(0, atIndex), email.slice(atIndex)];
    const hiddenUsername = username.slice(0, Math.max(1, Math.floor(username.length / 2))) + '*'.repeat(Math.max(0, username.length - 2)) + username.slice(-1);
    return hiddenUsername + domain;
  }
  return email;
};

export const countDateForDatePicker = (years) => {
  const today = new Date();
  
  return new Date(today.getFullYear() - years, today.getMonth(), today.getDate())
}

export const bellTypes = {
  request: "request",
  clinics: "clinics",
  employees: "employees"
}

export const AppointmentTypeText = (item) => {
  switch (true) {
    case item?.type === "doctor transfer" && !!item?.doctor_id && !!item?.sender_id:
      return <Trans>Doc2Doc Transfer</Trans>;
    case item?.type === "doctor transfer" && (!item?.doctor_id || !item?.sender_id):
      return <Trans>Transfer Request</Trans>;
    case item?.type === "drug order":
      return <Trans>Medication Order</Trans>;
    case item?.type === "appointment":
      return <Trans>Appointment Request</Trans>;
    default:
      return null;
  }
};

export const RequestText = (item) => {
  switch (item?.type) {
    case "doctor transfer":
      return <Trans>{item?.specialty?.description}</Trans>;
    case "drug order":
      return <Trans>Drug prescription</Trans>;
    case "appointment":
      return <Trans>{item?.appointment_type.description}</Trans>;
    default:
      return null;
  }
};

export const DoctorInfoBlockCol = ({ doctor, appointmentType, doctorType, specialty = null }) => {
  return (
    <div className="request-card-user">
      <div className="circle-crop">
        <img alt='avatar'
             src={doctor?.user?.photo_url || EmptyUserProfileImgForTable(2)}
        />
      </div>
      <div className="request-card-user-info">
        <div className='request-card-text'>
          {appointmentType === "doctor transfer" ?
            <><Trans>{doctorType} Dr</Trans>.</>
            :
            <><Trans>Dr</Trans>.</>
          }
          {` ${doctor?.publicName ?? '-'}`}
        </div>
        <div className='request-card-text'>
          <span><Trans>Specialty</Trans>: </span>
          {doctor?.specialtyToDoctors?.length || !doctorType ?
            doctor?.specialtyToDoctors?.map(el =>
              <p><Trans>{el?.specialty?.description}</Trans></p>
            )
            :
            <p><Trans>{specialty?.description}</Trans></p>
          }
        </div>
        <div className='request-card-text'>
          <span><Trans>BSNR</Trans>: </span>
          {`${doctor?.bsnr ?? '-'}`}
        </div>
      </div>
    </div>
  );
};

export const UrgencyArray = {
  1: {
    color: "#C14847",
    label: "ASAP 10 Days"
  },
  2: {
    color: "#FF8E33",
    label: "ASAP 20 Days"
  },
  3: {
    color: "#36B37E",
    label: "ASAP 30 Days"
  }
}

export const capitalizeFirstLetter = (input) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const termsOfUseLink = "https://www.iubenda.com/nutzungsbedingungen/30690780";
export const privacyPolicyLink = "https://www.iubenda.com/privacy-policy/30690780";
