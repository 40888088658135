import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import ConfirmCanceled from "../Confirm/ConfirmCanceled";
import ConfirmPending from "../Confirm/ConfirmPending";
import { Trans, useTranslation } from "react-i18next";
import { postDoctorTransfer } from "../../../Store/actions/appointmentActions";

const ConfirmSect = ({ confirmStatus, setShow, setConfirmStatus, setActiveStepChainIco }) => {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  const clinic = useSelector(state => state.clinicReducer.clinic);
  const { user } = useSelector(state => state.inviteUserReducer);
  
  switch (confirmStatus) {
    case '':
      return (
        <div className="list">
          <div className="list-heading">
            <p>{i18n.t("Transfer specialist details")}</p>
            <div className="list-subtitle">{i18n.t("Please, confirm specialist transfer")}</div>
          </div>
          <div className="list-details">
            <p><span>{i18n.t("Patient")}: </span>
              {data.user?.firstName ?? user?.firstName} {data.user?.lastName ?? user?.lastName}
            </p>
            <p><span>{i18n.t("Specialist Transfer")}: </span>{i18n.t(data?.specialist.description)}</p>
            <p><span>{i18n.t("Clinic")}: </span>{clinic?.name}</p>
          </div>
          <button className="for-patients-btn btn scale-btn"
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(postDoctorTransfer(data, data?.user_id, clinic?.id, setActiveStepChainIco, setConfirmStatus, i18n));
                  }}
          >
            {i18n.t("Send Request")}
          </button>
          <button className="for-patients-cancel-btn btn scale-btn"
                  onClick={(event) => {
                    event.preventDefault();
                    setShow(true);
                  }}
          >
            {i18n.t("Cancel")}
          </button>
        </div>
      );
    case 'pending':
      return <ConfirmPending type="doctor-transfer"/>;
    case 'canceled':
      return <ConfirmCanceled type="doctor-transfer"/>;
  }
};


export default function ConfirmDoctorTransfer() {
  const { i18n } = useTranslation();
  
  const [confirmStatus, setConfirmStatus] = useState('');
  const [show, setShow] = useState(false);
  const [activeStepChainIco, setActiveStepChainIco] = useState('confirm');
  
  const handleClose = () => setShow(false);
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className="breadcrumbs">
            <div className='breadcrumbs-item confirmation-breadcrumbs-item'><Trans>Confirmation</Trans></div>
          </div>
          <div className="for-patients-sect">
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active={activeStepChainIco} type="doctor-transfer"/>
            <ConfirmSect confirmStatus={confirmStatus} setConfirmStatus={setConfirmStatus}
                         setShow={setShow} setActiveStepChainIco={setActiveStepChainIco}
            />
          </div>
        </section>
      </main>
      
      <div className="popup" style={show ? { display: 'flex' } : { display: 'none' }}>
        <div className="popup-container">
          <div className="popup-approve-heading">{i18n.t("Cancel Specialist Transfer")}</div>
          <div>{i18n.t("Do you really want to cancel Specialist Transfer?")}</div>
          <div className="popup-btns">
            <div className="popup-approve-btn"
                 onClick={() => {
                   handleClose();
                 }}
            >
              No
            </div>
            <div className="popup-delete-btn popup-delete-btn-2"
                 onClick={() => {
                   handleClose();
                   setActiveStepChainIco('confirm');
              
                   setConfirmStatus('canceled');
                 }}
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
