import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../Utils/constants";
import { paths } from "../../Utils/Routes";
import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/Loading.css";

export default function Loading() {
  const navigate = useNavigate();
  useEffect(() => {
    // Start a timer to redirect after 5 seconds
    const redirectTimer = setTimeout(() => {
      navigate(paths.main);
    }, 10000);
    
    // Clear the timer if the component unmounts before the 5 seconds
    return () => clearTimeout(redirectTimer);
  }, []);
  return (
    <>
      <main>
        <section className="sect manage-patient-sect loader-sect">
          <ThemeProvider theme={theme}>
            <CircularProgress color="primary"/>
          </ThemeProvider>
        </section>
      </main>
    </>
  );
}
