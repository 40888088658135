import { useLayoutEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Components/ScrollToTop";
import AppRouter from "./AppRouter";
import { checkAuth } from "./Store/actions/userActions";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  
  
  useLayoutEffect(() => {
    if (!user.uuid) {
      dispatch(checkAuth());
    }
  }, []);
  
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <AppRouter/>
        </ScrollToTop>
      </BrowserRouter>
      <ToastContainer/>
    </div>
  );
}

export default App;
