import "../../../Assets/Styles/ForPatients.css";
import { useTranslation } from "react-i18next";
import { ConfirmPendingImg } from "../../../Assets/Images/img";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../Utils/Routes";


export default function ClinicConfirmPending() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="list">
        <div className="list-heading">
          <p>{i18n.t("Pending")}</p>
          <div className="list-subtitle">
            {i18n.t("The clinic was registered, but the account has not yet been activated. You will receive a letter when your account is activated.")}
          </div>
        </div>
        <img alt="logo" className="list-img" width="224"
             src={ConfirmPendingImg}
        />
        <div className="list-img-heading">
          {i18n.t("You have to wait a while")}
        </div>
        <button className="for-patients-btn btn scale-btn"
                onClick={(event) => {
                  event.preventDefault();
                  navigate(paths.clinicAd)
                }}
        >
          {i18n.t("OK")}
        </button>
      </div>
    </>
  );
}
