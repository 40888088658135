import "../../Assets/Styles/ForPatients.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";
import { EmptyUserProfileImg } from "../../Assets/Images/img";
import { useTranslation } from "react-i18next";


export default function ForPatientsDoctorInfo() {
  const { i18n } = useTranslation();
  const { data } = useSelector(state => state.bookAppointmentReducer);
  const clinic = useSelector(state => state.clinicReducer.clinic);
  
  return (
    <>
      <div className='info-sect'>
        <div className="manage-img for-patients-img">
          <div className="circle-crop-169">
            <img alt="avatar"
                 src={data?.appointmentDoctor?.user?.photo_url || EmptyUserProfileImg}
            />
          </div>
        </div>
        <div className='details'>
          <p>{data?.appointmentDoctor?.publicName}</p>
          <div>
            {data?.appointmentDoctor?.specialtyToDoctors.map((item) =>
              <div key={`${item?.specialty.uuid} ${item?.specialty.description}`}>
                {i18n.t(`${item?.specialty.description}`)}
              </div>
            )}
          </div>
          <p>{clinic?.name}</p>
          <p>{clinic?.city}</p>
          <p>{clinic?.location}</p>
          {clinic?.telephone && <p>{i18n.t("Phone")}: {clinic?.telephone}</p>}
          {clinic?.website && <Link to={`https://${clinic?.website}`} target="_blank">{clinic?.website}</Link>}
        </div>
      </div>
    </>
  );
}
