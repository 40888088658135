import "../../Assets/Styles/Login.css";
import { Trans } from "react-i18next";
import { PasswordKeyImg, StartingLogo } from "../../Assets/Images/img";

export default function ResetPasswordSuccess() {
  return (
    <main className="auth">
      <div className="starting-logo">
        <img alt="starting_logo"
             src={StartingLogo}
        />
      </div>
      <section className="auth-sect">
        <h1 className="auth-heading"><Trans>Password Recovery</Trans> <Trans>has been sent</Trans></h1>
        <p className="auth-subtitle">
          <Trans>Your password request has been</Trans><br/><Trans>sent we will reply to you asap</Trans>
        </p>
        <img className="auth-reset_img" alt="reset_pw_key"
             src={PasswordKeyImg}
        />
      </section>
    </main>
  );
}
