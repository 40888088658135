export const EmptyUserProfileImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687958928/PA/Empty_avatar_placeholder_1_l5w89y.svg";

export const EmptyUserProfileImgLight = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687958928/PA/Empty_avatar_placeholder_f4akag.svg";

export const EmptyUserProfileImgForTable = (i) => {
    if (i % 2 === 0) {
        return EmptyUserProfileImg;
    } else {
        return EmptyUserProfileImgLight;
    }
}

export const EmptyClinicProfileImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687958928/PA/Icon_1_cdyxot.svg";

export const EmptyClinicProfileImgLight = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687958928/PA/Icon_ihcfkz.svg";

export const EmptyClinicProfileImgForTable = (i) => {
    if (i % 2 === 0) {
        return EmptyClinicProfileImg;
    } else {
        return EmptyClinicProfileImgLight;
    }
}

export const FeedbackImg = "https://res.cloudinary.com/loksblnine/image/upload/v1663757535/PatientApp/assets_front/Feedback_icon_vi42j6.svg";

export const NoFoundImg = "https://res.cloudinary.com/loksblnine/image/upload/v1663757535/PatientApp/assets_front/no_found_j6smhb.svg";

export const AppointmentStepChainArrow = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1684852741/PA/Vector_3_wmdsxl.svg";

export const BellImg = "https://res.cloudinary.com/loksblnine/image/upload/v1674669033/PatientApp/assets_front/bell_accos7.svg";

export const StartingLogo = "https://res.cloudinary.com/loksblnine/image/upload/v1676463420/PatientApp/assets_front/Logo_ouqbfu.svg";

export const PencilImg = "https://res.cloudinary.com/loksblnine/image/upload/v1665474445/PatientApp/assets_front/pencil_mkcrma.svg";

export const CopyImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1686150600/PA/Vector_2_xkxyfv.svg";

export const AccordionArrow = 'https://res.cloudinary.com/dvjdkgzbe/image/upload/v1684836666/PA/Vector_oyyunk.svg';

export const LogoImg = "https://res.cloudinary.com/loksblnine/image/upload/v1676463427/PatientApp/assets_front/Logo_meplano_loqyoy.svg";

export const CheckmarkImg = "https://res.cloudinary.com/loksblnine/image/upload/v1676463410/PatientApp/assets_front/checkmark_llfvaw.svg";

export const PendingIco = "https://res.cloudinary.com/loksblnine/image/upload/v1674669033/PatientApp/assets_front/pending_jthmiy.svg";

export const RequestAgainIco = "https://res.cloudinary.com/dq9w8bxge/image/upload/v1695117447/Autorenew_swnjys.svg";

export const CloseRedIco = "https://res.cloudinary.com/dq9w8bxge/image/upload/v1695117698/Close_l2zxnz.svg";

export const ArchivedIco = "https://res.cloudinary.com/loksblnine/image/upload/v1671429305/PatientApp/assets_front/Group_86_f0rvtq.svg";

export const PartialApprovedIco = "https://res.cloudinary.com/loksblnine/image/upload/v1677514050/PatientApp/assets_front/partial_approval_uk7wcj.svg";

export const SuccessFeedbackImg = "https://res.cloudinary.com/loksblnine/image/upload/v1663757537/PatientApp/assets_front/Success_uisuzc.svg";

export const PasswordKeyImg = "https://res.cloudinary.com/loksblnine/image/upload/v1663757663/PatientApp/assets_front/reset_pw_key_iknzuc.svg";

export const BlockedToEditImg = "https://res.cloudinary.com/loksblnine/image/upload/v1666939698/PatientApp/assets_front/disabled_input_pxhxmp.svg";

export const ConfirmFailedImg = "https://res.cloudinary.com/loksblnine/image/upload/v1688396858/PatientApp/assets_front/Icon_3_biowh1.svg";

export const ConfirmFailedCodeImg = "https://res.cloudinary.com/loksblnine/image/upload/v1688122927/PatientApp/assets_front/Icon_1_fifoa2.svg";

export const ConfirmPendingImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1685461546/PA/Done_1_wh8mna.svg";

export const PasswordIco = "https://res.cloudinary.com/loksblnine/image/upload/v1663757663/PatientApp/assets_front/view_password_cbpq1d.svg";

export const CrossedPasswordIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1688638295/PA/Group_1_g4z6e4.svg";

export const CopyBannerIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687255127/PA/Vector_1_vcajaf.svg";

export const LogoHeartImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1685083585/PA/heart_kwvrcb.svg";

export const BannerArrowIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687255126/PA/Vector_tqnr9e.svg";

export const LoadMoreIco = "https://res.cloudinary.com/loksblnine/image/upload/v1665474445/PatientApp/assets_front/load_more_mg9qmt.svg";

export const ClinicAdsImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1685989738/PA/Group_1_1_uzgqfl.png";

export const AppStoreIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1685462603/PA/App_Store_tomdrj.png";

export const GooglePlayIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1685462605/PA/play_tyi6v4.png";

export const MorningBlockImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1684938608/PA/Morning_szksly.svg";

export const AfternoonBlockImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1684938610/PA/Afternoon_r8ks2a.svg";

export const EveningBlockImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1684938612/PA/Evening_voxmnq.svg";

export const HolidayInfoIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1686238847/PA/Vector_4_kisjwd.svg";

export const PatientVerifyFailedImg = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1687254118/PA/Done_w4x2ya.svg";

export const StarIco = "https://res.cloudinary.com/loksblnine/image/upload/v1668511100/PatientApp/assets_front/filled_Star_vt9skm.svg";

export const InfoIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1686072382/PA/Vector_1_ncotqg.svg";

export const SelectCloseIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1712231142/PA/Vector33_gbr464.svg";

export const InputWarningIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1712245027/PA/Warning_uqk0ww.svg";

export const FlagIco = "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1712309187/PA/twemoji_flag-germany_hswult.svg";

