import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/PatientInfo.css";
import { Trans } from "react-i18next";
import { Field, Form, Formik } from "formik";
import dayjs from "dayjs";


export default function PatientInfoForm({ patient }) {
  const formatDate = dayjs(new Date(patient?.birth_date)).format("DD.MM.YYYY");
  
  return (
    <>
      <div className="manage-form">
        <Formik
          enableReinitialize
          initialValues={{
            first_name: patient?.firstName,
            last_name: patient?.lastName,
            birthDate: formatDate,
            location: patient?.location,
            telephone: patient?.telephone,
            email: patient?.email
          }}
          onSubmit={() => {
            console.log("submit")
          }}
        >
          {() => (
            <Form className="manage-form-col">
              <div className="input-container">
                <label><Trans>First Name</Trans></label>
                <Field
                  type="text"
                  name="first_name"
                  maxLength="30" required disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Last Name</Trans></label>
                <Field
                  type="text"
                  name="last_name"
                  maxLength="30" required disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Birth date</Trans></label>
                <Field
                  type="text"
                  name="birthDate"
                  placeholder="DD.MM.YYYY"
                  maxLength="10" required disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Telephone</Trans></label>
                <Field
                  type="tel"
                  name="telephone"
                  maxLength="20" required disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Email</Trans></label>
                <Field
                  type="text"
                  name="email"
                  maxLength="40" required disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Address</Trans></label>
                <Field
                  type="text"
                  name="location"
                  maxLength="5" required disabled
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
