import "../../Assets/Styles/ManagePatient.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import Header from "../../Layouts/Header/Header";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import Loading from "../../Layouts/Loading/Loading";
import { ACTIONS, StatusArray, StatusInfo } from "../../Utils/constants";
import { getClinicsList } from "../../Store/actions/clinicActions";
import { Link } from "react-router-dom";
import { InvitePopup } from "../../Components/InvitePopup";
import { paths } from "../../Utils/Routes";
import { EmptyClinicProfileImgForTable } from "../../Assets/Images/img";
import PaginationButton from "../../Components/PaginationButton";


export default function AllClinics() {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  const { filteredItems, page, loadNext, isReady, items } = useSelector(state => state.clinicReducer);
  
  const [query, setQuery] = useState("");
  const [show, setShow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const inputRef = useRef(null);
  
  const changeHandler = (event) => {
    if (!event.target.value) {
      dispatch({
        type: ACTIONS.CLINIC.SET_OLD_ITEMS
      });
    } else {
      dispatch({
        type: ACTIONS.CLINIC.SET_PAGE, payload: 0
      });
    }
    setQuery(event.target.value.trim().toLowerCase());
  };
  
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);
  
  useEffect(() => {
    if (query) {
      dispatch({
        type: ACTIONS.CLINIC.CLEAR_FILTERED_ARRAY
      });
      dispatch(getClinicsList(i18n, query, 0));
      if (filteredItems.length > 15) {
        setTimeout(() => {
          dispatch(getClinicsList(i18n, query, 1));
        }, 150);
      }
    }
  }, [query]);
  
  useEffect(() => {
    if (!isReady && !query) {
      if (!!items.length) {
        dispatch({
          type: ACTIONS.CLINIC.FILTER_CLINIC_FILTERED_ARRAY
        });
      } else {
        dispatch(getClinicsList(i18n, query, 0));
        if (filteredItems.length > 15) {
          setTimeout(() => {
            dispatch(getClinicsList(i18n, query, 1));
          }, 150);
        }
      }
    }
  }, [isReady, query]);
  
  const handleNextClinics = useCallback(() => {
    dispatch(getClinicsList(i18n, query, page));
  }, [query, page]);
  
  const handleClose = () => setShow(false);
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading">
            <div className="manage-patient-add-btn" onClick={() => setShow(!show)}>
              + <Trans>Invite clinic</Trans>
            </div>
            <div className="search">
              <div className="search-input-wrap">
                <input className="search-input"
                       ref={inputRef}
                       placeholder={i18n.t("Search")}
                       maxLength="30"
                       onChange={debouncedChangeHandler}
                />
                {query &&
                  <div className="search-clear"
                       onClick={() => {
                         dispatch({
                           type: ACTIONS.CLINIC.FILTER_CLINIC_FILTERED_ARRAY
                         });
                         setQuery("");
                         inputRef.current.value = "";
                       }}
                  >
                    ✕
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="manage-patient-table">
            <div
              className="table-heading"
              style={!filteredItems.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
            >
              <div className="table-col-user ">
                <Trans>Clinic</Trans>
              </div>
              <div className="table-col ">
                <Trans>Address</Trans>
              </div>
              <div className="table-col">
                <Trans>Email</Trans>
              </div>
              <div className="table-col">
                <Trans>Phone</Trans>
              </div>
              <div className="table-col table-col-status">
                <Trans>Status</Trans>
              </div>
            </div>
            {isReady ?
              !filteredItems.length ?
                <SearchNoFound/>
                :
                filteredItems.map((clinic, i) =>
                  <div className='card' key={`clinicCard ${i}`}>
                    <div className='card-header'>
                      <Link
                        to={`${paths.clinics}/${clinic?.uuid}${clinic?.status === StatusArray.PENDING ? '/approve' : ''}`}
                        className="table-link">
                        <div className="table-col-user">
                          <div className="circle-crop">
                            <img alt="avatar"
                                 src={clinic.image_url || EmptyClinicProfileImgForTable(i)}
                            />
                          </div>
                          <div className="ml-10 mx-w-200">
                            <div>{clinic.name}</div>
                          </div>
                        </div>
                      </Link>
                      <div className="table-col ">{clinic.location}</div>
                      <div className="table-col">{clinic.email}</div>
                      <div className="table-col">{clinic.telephone}</div>
                      <div className="table-col table-col-status">
                        <StatusInfo statusId={clinic?.status}/>
                      </div>
                    </div>
                  </div>
                )
              :
              <Loading/>
            }
          </div>
          
          {(!!filteredItems.length && loadNext) &&
            <PaginationButton handleNext={handleNextClinics}/>
          }
        </section>
      </main>
      
      <InvitePopup type='Clinic' isSent={isSent} setIsSent={setIsSent} show={show} handleClose={handleClose}/>
    </>
  );
}
