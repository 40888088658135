import { useTranslation } from "react-i18next";
import React from "react";

export default function ValidationError({ errors, touched }) {
  const { i18n } = useTranslation();
  
  return (
    <>
      <span className="error">
        {(errors && touched) &&
        <div>{i18n.t(`${errors}`)}</div>
        }
      </span>
    </>
  );
}
