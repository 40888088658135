import "../Assets/Styles/Loading.css";
import "../Assets/Styles/ManagePatient.css";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "../Utils/constants";
import { ThemeProvider } from "@mui/material/styles";

export default function Loader() {
  return (
    <div className="row-loader">
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary"/>
      </ThemeProvider>
    </div>
  );
}
