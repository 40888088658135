import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { paths } from "../../Utils/Routes";
import { bellTypes, PermissionsArray } from "../../Utils/constants";
import { Bell } from "./сomponents/Bell";

export const HeaderNavigation = ({ permissions, isLinkActive }) => {
  return (
    <>
      {(permissions?.length === 1 && permissions?.some(obj => obj.id === PermissionsArray.DOCTOR)) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Link to={paths.submitFeedback} className={isLinkActive(paths.submitFeedback)}>
              <Trans>
                Submit Feedback
              </Trans>
            </Link>
          </>
        )}
      {(permissions?.length === 1 && permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER)) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
          </>
        )}
      {(permissions?.length === 1 && permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)) &&
        (
          <>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Bell type={bellTypes.employees}/>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
          </>
        )}
      {(permissions?.length > 1 && !permissions?.some(obj => obj.id === PermissionsArray.DOCTOR)) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
          </>
        )}
      {(permissions?.length > 1 && !permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER)) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
            <Link to={paths.submitFeedback} className={isLinkActive(paths.submitFeedback)}>
              <Trans>Submit Feedback</Trans>
            </Link>
          </>
        )}
      {(permissions?.length > 1 && !permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Bell type={bellTypes.employees}/>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
            <Link to={paths.submitFeedback} className={isLinkActive(paths.submitFeedback)}>
              <Trans>Submit Feedback</Trans>
            </Link>
          </>
        )}
      {(
          permissions?.some(obj => obj.id === PermissionsArray.DOCTOR) &&
          permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER) &&
          permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)
        ) &&
        (
          <>
            <Bell type={bellTypes.request}/>
            <Link to={paths.allRequests} className={isLinkActive(paths.allRequests)}>
              <Trans>Requests</Trans>
            </Link>
            <Link to={paths.patients} className={isLinkActive(paths.patients)}>
              <Trans>Patients</Trans>
            </Link>
            <Bell type={bellTypes.employees}/>
            <Link to={paths.employees} className={isLinkActive(paths.employees)}>
              <Trans>Employees</Trans>
            </Link>
            <Link to={paths.feedbacks} className={isLinkActive(paths.feedbacks)}>
              <Trans>Feedbacks</Trans>
            </Link>
            <Link to={paths.submitFeedback} className={isLinkActive(paths.submitFeedback)}>
              <Trans>Submit Feedback</Trans>
            </Link>
          </>
        )}
    </>
  )
}
