import React from "react";
import { ACTIONS } from "./constants";
import ClinicsDoctors from "../Pages/ForPatients/Clinic/ClinicsDoctors";
import ChooseAppointment from "../Pages/ForPatients/Appointments/ChooseAppointment";
import ChooseAppointmentType from "../Pages/ForPatients/Appointments/ChooseAppointmentType";
import ChooseAppointmentDate from "../Pages/ForPatients/Appointments/ChooseAppointmentDate";
import PatientDetails from "../Pages/ForPatients/Patient/PatientDetails";
import AddDrugToOrder from "../Pages/ForPatients/DrugOrders/AddDrugToOrder";
import ConfirmAppointment from "../Pages/ForPatients/Appointments/ConfirmAppointment";
import ConfirmDrugOrder from "../Pages/ForPatients/DrugOrders/ConfirmDrugOrder";
import ConfirmDoctorTransfer from "../Pages/ForPatients/DoctorTransfer/ConfirmDoctorTransfer";
import ChooseDoctor from "../Pages/ForPatients/DoctorTransfer/ChooseDoctor";
import AdvertisingForRegistered from "../Pages/Advertising/AdvertisingForRegistered";
import AdvertisingForGuest from "../Pages/Advertising/AdvertisingForGuest";
import { useTranslation } from "react-i18next";


export const BreadcrumbsListPatientAccountType = (type) => {
  switch (type) {
    case 'appointment':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.APPOINTMENT_TYPE,
          label: 'Appointment'
        },
        {
          component: ForPatientComponentKeys.DATE,
          label: 'Date'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        }
      ]
    case 'drug-order':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_DRUGS,
          label: 'Drug Prescription'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        }
      ]
    case 'specialist-transfer':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_SPECIALIST,
          label: 'Specialist Transfer'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        }
      ]
  }
}

export const BreadcrumbsListPatientDetails = (type) => {
  switch (type) {
    case 'appointment':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.APPOINTMENT_TYPE,
          label: 'Appointment'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Date'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Patient Details'
        }
      ]
    case 'drug-order':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_DRUGS,
          label: 'Drug Prescription'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Patient Details'
        }
      ]
    case 'specialist-transfer':
      return [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_SPECIALIST,
          label: 'Specialist Transfer'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Account type'
        },
        {
          component: ForPatientComponentKeys.PATIENT_DETAILS,
          label: 'Patient Details'
        }
      ]
  }
}

export const ConfirmationComponent = (type, dispatch) => {
  switch (type) {
    case 'appointment':
      return dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
        payload: ForPatientComponentKeys.CONFIRM_APPOINTMENT
      });
    case 'drug-order':
      return dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
        payload: ForPatientComponentKeys.CONFIRM_DRUG_ORDER
      });
    case 'specialist-transfer':
      return dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
        payload: ForPatientComponentKeys.CONFIRM_SPECIALIST_TRANSFER
      });
  }
}

export const ConfirmationBreadcrumbsComponent = ({ item, i, dispatch = null, isLogin = null }) => {
  const { i18n } = useTranslation();
  
  return (
    <div className={`breadcrumbs-item ${item.label === 'Patient Details' ? 'confirmation-breadcrumbs-item' : ''}`}
         key={item.label + i}
         onClick={() => {
           if ((item.label === 'Patient Details') && !isLogin && dispatch) {
             dispatch({
               type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
               payload: {
                 isRegistered: 'signUp'
               }
             });
           } else {
             dispatch({
               type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
               payload: {
                 isRegistered: false
               }
             });
           }
           dispatch({
             type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
             payload: item.component
           });
         }}
    >
      {i18n.t(item.label)}
    </div>
  )
}

export const ForPatientComponentKeys = {
  CLINIC: 'CLINIC',
  DOCTOR: 'DOCTOR',
  APPOINTMENT_TYPE: 'APPOINTMENT_TYPE',
  CHOOSE_DRUGS: 'CHOOSE_DRUGS',
  CHOOSE_SPECIALIST: 'CHOOSE_SPECIALIST',
  DATE: 'DATE',
  PATIENT_DETAILS: 'PATIENT_DETAILS',
  CONFIRM_APPOINTMENT: 'CONFIRM_APPOINTMENT',
  CONFIRM_DRUG_ORDER: 'CONFIRM_DRUG_ORDER',
  CONFIRM_SPECIALIST_TRANSFER: 'CONFIRM_SPECIALIST_TRANSFER',
  ADS_REGISTERED: 'ADS_REGISTERED',
  ADS_GUEST: 'ADS_GUEST',
}

export const GetForPatientComponentByKey = (key) => {
  switch (key) {
    case ForPatientComponentKeys.CLINIC:
      return <ClinicsDoctors/>;
    case ForPatientComponentKeys.DOCTOR:
      return <ChooseAppointment/>;
    case ForPatientComponentKeys.APPOINTMENT_TYPE:
      return <ChooseAppointmentType/>;
    case ForPatientComponentKeys.CHOOSE_DRUGS:
      return <AddDrugToOrder/>;
    case ForPatientComponentKeys.CHOOSE_SPECIALIST:
      return <ChooseDoctor/>;
    case ForPatientComponentKeys.DATE:
      return <ChooseAppointmentDate/>;
    case ForPatientComponentKeys.PATIENT_DETAILS:
      return <PatientDetails/>;
    case ForPatientComponentKeys.CONFIRM_APPOINTMENT:
      return <ConfirmAppointment/>;
    case ForPatientComponentKeys.CONFIRM_DRUG_ORDER:
      return <ConfirmDrugOrder/>;
    case ForPatientComponentKeys.CONFIRM_SPECIALIST_TRANSFER:
      return <ConfirmDoctorTransfer/>;
    case ForPatientComponentKeys.ADS_REGISTERED:
      return <AdvertisingForRegistered/>;
    case ForPatientComponentKeys.ADS_GUEST:
      return <AdvertisingForGuest/>;
  }
}

export const DayBlocks = {
  10: "Morning Block",
  14: "Afternoon Block",
  17: "Evening Block",
}


