import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { ACTIONS } from "../../../Utils/constants";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";
import { useTranslation } from "react-i18next";


export default function ChooseAppointmentType() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  console.log("step 3");
  const { breadcrumbs, data: { appointmentDoctor } } = useSelector(state => state.bookAppointmentReducer);
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.APPOINTMENT_TYPE,
          label: 'Appointment'
        }
      ]
    });
  }, [])
  
  const makeTypeActive = (event) => {
    const elements = document.getElementsByClassName("active-type");
    for (let el of elements) {
      el.classList.remove("active-type")
    }
    event.currentTarget.classList.add("active-type");
  }
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <div className='breadcrumbs-item' key={item + i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='type' type='appointment'/>
            <div className='list'>
              <div className='list-heading'>{i18n.t("Choose Type of Appointment")}</div>
              {appointmentDoctor?.doctor_appointment_types?.map((type, i) =>
                <div className={`list-item ${appointmentDoctor?.doctor_appointment_types?.id === type?.id ? "active-type" : ""}`}
                     key={type.description + i}
                     onClick={(event) => {
                       makeTypeActive(event);
                       dispatch({
                         type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                         payload: {
                           appointmentType: type
                         }
                       });
                     }}
                >
                  {i18n.t(type.description)}
                </div>
              )}
              <button className='for-patients-btn btn scale-btn' disabled={!document.getElementsByClassName("active-type").length}
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                          payload: ForPatientComponentKeys.DATE
                        });
                      }}
              >
                {i18n.t("Continue")}
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}