import { ACTIONS, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";

const initialState = {
  ascOrder: {
    isReady: false,
    items: [],
    nextItems: [],
    filteredItems: [],
    nextFilteredItems: [],
    page: 0,
    loadNext: true
  },
  descOrder: {
    isReady: false,
    items: [],
    nextItems: [],
    filteredItems: [],
    nextFilteredItems: [],
    page: 0,
    loadNext: true
  }
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FEEDBACK.SET_PAGE: {
      if (action.payload.order === 'ASC') {
        return {
          ...state,
          ascOrder: {
            ...state.ascOrder,
            page: action.payload.page
          }
        }
      } else {
        return {
          ...state,
          descOrder: {
            ...state.descOrder,
            page: action.payload.page
          }
        }
      }
    }
    case ACTIONS.FEEDBACK.CLEAR_ARRAY: {
      return {
        ...state,
        ascOrder: {
          isReady: false,
          items: [],
          nextItems: [],
          filteredItems: [],
          nextFilteredItems: [],
          page: 0,
          loadNext: true
        },
        descOrder: {
          isReady: false,
          items: [],
          nextItems: [],
          filteredItems: [],
          nextFilteredItems: [],
          page: 0,
          loadNext: true
        }
      }
    }
    case ACTIONS.FEEDBACK.SET_FILTERED_ARRAY: {
      if (action.payload.data.length === LIMIT_ITEM_PER_PAGE) {
        if (action.payload.order === 'ASC') {
          return {
            ...state,
            ascOrder: {
              filteredItems: state.ascOrder.filteredItems.concat(state.ascOrder.nextFilteredItems),
              nextFilteredItems: action.payload.data,
              isReady: true,
              loadNext: true,
              page: state.ascOrder.page + 1
            }
          }
        } else {
          return {
            ...state,
            descOrder: {
              filteredItems: state.descOrder.filteredItems.concat(state.descOrder.nextFilteredItems),
              nextFilteredItems: action.payload.data,
              isReady: true,
              loadNext: true,
              page: state.descOrder.page + 1
            }
          }
        }
      }
      if (action.payload.order === 'ASC') {
        return {
          ...state,
          ascOrder: {
            filteredItems: state.ascOrder.filteredItems.concat(state.ascOrder.nextFilteredItems, action.payload.data),
            nextFilteredItems: [],
            isReady: true,
            loadNext: false
          }
        }
      } else {
        return {
          ...state,
          descOrder: {
            filteredItems: state.descOrder.filteredItems.concat(state.descOrder.nextFilteredItems, action.payload.data),
            nextFilteredItems: [],
            isReady: true,
            loadNext: false
          }
        }
      }
    }
    
    default:
      return state;
  }
};

export default feedbackReducer;