import { toast } from "react-toastify";
import { apiGet, apiPut } from "../../http/headerPlaceholder.instance";
import { ACTIONS } from "../../Utils/constants";
import { checkAuth } from "./userActions";


export const getDoctorsListByClinic = (clinicId, i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/doctors/all?clinicId=${clinicId}`
      });
      dispatch({
        type: ACTIONS.DOCTOR.SET_DOCTORS_GROUPED,
        payload: data
      });
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
}

export const getDoctorById = (id, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/doctors/all?uuid=${id}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.DOCTOR.SET_DOCTOR,
          payload: data[0]
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getDoctorAppointmentTypesList = (doctor_uuid, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/doctors/appointment-types?uuid=${doctor_uuid}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.DOCTOR.SET_DOCTOR_APPOINTMENT_TYPES_LIST,
          payload: data
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const updateDoctorAppointmentTypesList = (userId, data, i18n) => {
  return async () => {
    apiPut({
      url: `/doctors/appointment-types`,
      data: {
        userId,
        appointmentTypesList: Array.from(new Set(data.map(obj => obj.id)))
      }
    })
      .then((res) => {
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("You should fill your personal info first"));
      });
  };
};

export const updateDoctorHolidays = (doctor_id, isWorking, workingStatus, i18n, accountFormikRef) => {
  return async () => {
    apiPut({
      url: `/doctors/working-status`,
      data: {
        userId: doctor_id,
        isWorking,
        workingStatus
      }
    })
      .then((res) => {
        if (isWorking) {
          accountFormikRef.current?.resetForm();
        }
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}
