import { ACTIONS, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";

const initialState = {
  isReady: false,
  items: [],
  nextItems: [],
  filteredItems: [],
  nextFilteredItems: [],
  employee: {
    photo_url: '',
    firstName: '',
    lastName: '',
    telephone: '',
    email: ''
  },
  page: 0,
  loadNext: true
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.EMPLOYEES.SET_EMPLOYEES: {
      if (action.payload.length === LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          items: state.items.concat(state.nextItems),
          filteredItems: state.filteredItems.concat(state.nextItems),
          nextItems: action.payload,
          isReady: true,
          loadNext: true,
          page: state.page + 1
        };
      }
      return {
        ...state,
        items: state.items.concat(state.nextItems, action.payload),
        filteredItems: state.filteredItems.concat(state.nextItems, action.payload),
        nextItems: [],
        isReady: true,
        loadNext: false
      };
    }
    case ACTIONS.EMPLOYEES.SET_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case ACTIONS.EMPLOYEES.SET_FILTERED_EMPLOYEES_ARRAY: {
      return {
        ...state,
        filteredItems: action.payload,
        nextFilteredItems: [],
        isReady: true,
        loadNext: false
      };
    }
    case ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY: {
      return {
        ...state,
        isReady: false,
        filteredItems: [],
        nextFilteredItems: [],
        page: 0,
        loadNext: true
      };
    }
    case ACTIONS.EMPLOYEES.FILTER_EMPLOYEES_FILTERED_ARRAY: {
      return {
        ...state,
        isReady: false,
        filteredItems: state.items,
        nextFilteredItems: [],
        page: 0,
        loadNext: true
      };
    }
    case ACTIONS.EMPLOYEES.CLEAR_DATA: {
      return {
        ...state,
        isReady: false,
        items: [],
        nextItems: [],
        filteredItems: [],
        nextFilteredItems: [],
        page: 0,
        loadNext: true
      };
    }
    case ACTIONS.EMPLOYEES.SET_OLD_ITEMS: {
      return {
        ...state,
        isReady: true,
        filteredItems: state.items,
        page: Math.ceil(state.items.length / LIMIT_ITEM_PER_PAGE) + 1,
        loadNext: state.items.length % LIMIT_ITEM_PER_PAGE === 0
      };
    }
    case ACTIONS.EMPLOYEES.SET_READY_EMPLOYEES: {
      return {
        ...state,
        isReady: action.payload
      };
    }
    case ACTIONS.EMPLOYEES.SET_EMPLOYEE: {
      return {
        ...state,
        employee: action.payload
      };
    }
    case ACTIONS.EMPLOYEES.EDIT_EMPLOYEE_STATUS: {
      const newArr = state.filteredItems.map(obj => {
        if (obj.id === action.payload.userId) {
          return { ...obj, account_status: action.payload.statusId };
        }
        
        return obj;
      });
      
      return {
        ...state,
        filteredItems: newArr
      };
    }
    case ACTIONS.EMPLOYEES.EDIT_EMPLOYEE_PERMISSIONS: {
      const newArr = state.filteredItems.map(obj => {
        if (obj.id === action.payload.userId) {
          return { ...obj, permission_id_permissions: action.payload.permissions };
        }
        
        return obj;
      });
      
      return {
        ...state,
        filteredItems: newArr
      };
    }
    case ACTIONS.EMPLOYEES.REMOVE_EMPLOYEE: {
      const newArr = state.filteredItems.filter((item) => {
        return item.id !== action.payload
      });
      
      return {
        ...state,
        filteredItems: newArr
      }
    }
    case ACTIONS.EMPLOYEES.EDIT_EMPLOYEE_PHOTO: {
      const editedEmployee = {
        ...state.employee,
        photo_url: action.payload
      }
      return {
        ...state,
        employee: editedEmployee,
      };
    }
    
    default:
      return state;
  }
};

export default employeesReducer;