import "dayjs/locale/de";
import { Trans, useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ACTIONS } from "../Utils/constants";
import { getPatientsList } from "../Store/actions/patientActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

export default function PatientsSearchFilter({ name, setName, phone, setPhone, birthDate, setBirthDate }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const [isSearched, setIsSearched] = useState(false);
  const [isDateInvalid, setDateInvalid] = useState(false);
  
  const nameFilterRef = useRef(null);
  const phoneFilterRef = useRef(null);
  
  const searchHandler = () => {
    if (!!birthDate || !!name || !!phone) {
      if (!isDateInvalid) {
        dispatch({
          type: ACTIONS.PATIENTS.CLEAR_FILTERED_ARRAY
        });
        dispatch(getPatientsList(name, birthDate, phone, 0, i18n));
        setTimeout(() => {
          dispatch(getPatientsList(name, birthDate, phone, 1, i18n));
        }, 150);
      }
    }
  }
  
  return (
    <div className="search">
      <div className="search-inputs-wrap">
        <div className="search-input-wrap">
          <input className="search-input"
                 ref={phoneFilterRef}
                 placeholder={i18n.t("Search by phone")}
                 maxLength="20"
                 onChange={(event => setPhone(encodeURIComponent(event.target.value)))}
          />
          {phone &&
          <div className="search-clear"
               onClick={() => {
                 setPhone("");
                 phoneFilterRef.current.value = "";
               }}
          >
            ✕
          </div>
          }
        </div>
        <div className="search-input-wrap">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <DatePicker
              sx={{
                width: 280,
              }}
              openTo={"year"}
              className='date-picker patient-date-picker'
              views={['year', 'month', 'day']}
              inputFormat="DD.MM.YYYY"
              disableFuture
              toolbarPlaceholder="hello"
              value={birthDate}
              onChange={(value) => {
                setDateInvalid(isNaN(value?.$D))
                setBirthDate(new Date(value));
              }}
              renderInput={(params) =>
                <TextField {...params} helperText={null}
                           sx={{
                             input: {
                               "&::placeholder": {
                                 opacity: 0.5,
                               },
                             }
                           }}
                           inputProps={{
                             ...params.inputProps,
                             placeholder: i18n.t("Search by date of birth"),
                             style: {
                               opacity: 1,
                               fontStyle: 'normal',
                             },
                           }}
                />
              }
            />
          </LocalizationProvider>
          {birthDate &&
          <div className="search-clear"
               onClick={() => {
                 setBirthDate(null);
                 setDateInvalid(false)
               }}
          >
            ✕
          </div>
          }
          {isDateInvalid &&
          <span className="small-error">
            <Trans>invalid_date</Trans>
          </span>}
        
        </div>
        <div className="search-input-wrap">
          <input className="search-input"
                 ref={nameFilterRef}
                 placeholder={i18n.t("Search by name")}
                 maxLength="25"
                 onChange={event => setName(event.target.value)}
          />
          {name &&
          <div className="search-clear"
               onClick={() => {
                 setName("");
                 nameFilterRef.current.value = "";
               }}
          >
            ✕
          </div>
          }
        </div>
      </div>
      
      <button className="search-btn btn scale-btn"
              disabled={!name && !phone && !birthDate}
              onClick={(event) => {
                event.preventDefault()
                setIsSearched(true)
                searchHandler()
              }}
      >
        <Trans>Search</Trans>
      </button>
      
      {(name || birthDate || phone) &&
        <button className="search-btn btn scale-btn"
                onClick={(event) => {
                  event.preventDefault()
  
                  if (isSearched) {
                    dispatch(getPatientsList('', '', '', 0, i18n))
                  }
  
                  phoneFilterRef.current.value = ""
                  setPhone("")
                  nameFilterRef.current.value = ""
                  setName("")
                  setBirthDate(null)
                  setDateInvalid(false)
                  setIsSearched(false)
                }}
        >
          <Trans>Clear All</Trans>
        </button>
      }
    </div>
  );
}
