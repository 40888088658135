import "../../../Assets/Styles/ForPatients.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmFailedImg } from "../../../Assets/Images/img";
import { ACTIONS } from "../../../Utils/constants";
import { useDispatch } from "react-redux";


export default function PatientVerifyFailed() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  return (
    <>
      <div className='list'>
        <div className='list-heading'>
          <p>{i18n.t('Failed to register account')}</p>
          <div className='list-subtitle'>
            {i18n.t('Clinic account with this email is already registered. Use your username and password to log in.')}
          </div>
        </div>
        <img alt='logo' className='list-img' width='224'
             src={ConfirmFailedImg}
        />
        <button className='for-patients-btn btn scale-btn'
                onClick={(event) => {
                  event.preventDefault();
                  localStorage.removeItem("accessToken");
                  dispatch({
                    type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                    payload: {
                      isRegistered: null
                    }
                  });
                }}
        >
          {i18n.t('OK')}
        </button>
      </div>
    </>
  );
}
