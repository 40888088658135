import "../../Assets/Styles/Banners.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  bannerLinkStyles,
  bannerLogoStyles,
  bannerStyles,
  bannerTitleStyles,
  CopyHtmlToClipboard,
  smallBannerStyles
} from "../../Utils/ConstantsBanners";
import { BannerArrowIco, CopyBannerIco, LogoHeartImg } from "../../Assets/Images/img";


export default function SmallBanner({ id, bg, link }) {
  const { i18n } = useTranslation();
  
  return (
    <>
      <div className='banner-wrap'>
        <div id={id}
             style={bg === "dark" ?
               { background: "#343760", color: "#FFFFFF", ...smallBannerStyles, ...bannerStyles }
               :
               { background: "#FFFFFF", color: "#343760", ...smallBannerStyles, ...bannerStyles }
             }
        >
          <div style={bannerLogoStyles}>
            <img alt='heart' width='44.5px'
                 src={LogoHeartImg}
            />
            <p style={{ margin: "0px" }}>{i18n.t("meplano")}</p>
          </div>
          <div style={bannerTitleStyles}>
            <p style={{ margin: "0px" }}>{i18n.t("Appointments. Medications. Requests.")}</p>
          </div>
          <Link to={link} style={bannerLinkStyles}>
            <img alt='ico' width='24px'
                 src={BannerArrowIco}
            />
          </Link>
        </div>
        <div className='banner-btn'
             onClick={() => CopyHtmlToClipboard(id, i18n)}
        >
          <img alt='btn'
               src={CopyBannerIco}
          />
        </div>
      </div>
    </>
  );
}
