import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import ConfirmPending from "../Confirm/ConfirmPending";
import ConfirmCanceled from "../Confirm/ConfirmCanceled";
import { Trans, useTranslation } from "react-i18next";
import { postDrugOrder } from "../../../Store/actions/appointmentActions";

const ConfirmSect = ({ confirmStatus, setConfirmStatus, setShow }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  const clinic = useSelector(state => state.clinicReducer.clinic);
  
  switch (confirmStatus) {
    case '':
      return (
        <div className='list'>
          <div className='list-heading'>
            <p>{i18n.t("Drug Prescription details")}</p>
            <div className='list-subtitle'>{i18n.t("Please, confirm order")}</div>
          </div>
          {data?.drugs.map((drug, i) =>
            <div className='list-drug' key={drug.description + i}>
              <p>{drug.description} - {drug.count} pack(s)</p>
            </div>
          )}
          <button className='for-patients-btn btn scale-btn'
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(postDrugOrder(data, data?.user_id, clinic?.id, setConfirmStatus, i18n))
                  }}
          >
            {i18n.t("Confirm Order")}
          </button>
          <button className='for-patients-cancel-btn btn scale-btn'
                  onClick={(event) => {
                    event.preventDefault();
                    setShow(true)
                  }}
          >
            {i18n.t("Cancel Order")}
          </button>
        </div>
      )
    case 'pending':
      return <ConfirmPending type='drug-order'/>;
    case 'canceled':
      return <ConfirmCanceled type='drug-order'/>;
  }
}


export default function ConfirmDrugOrder() {
  const { i18n } = useTranslation();
  
  const [confirmStatus, setConfirmStatus] = useState('');
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            <div className='breadcrumbs-item confirmation-breadcrumbs-item'><Trans>Confirmation</Trans></div>
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='confirm' type='drug-order'/>
            <ConfirmSect confirmStatus={confirmStatus} setConfirmStatus={setConfirmStatus}
                         setShow={setShow}/>
          </div>
        </section>
      </main>
      
      <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
        <div className='popup-container'>
          <div className="popup-approve-heading">{i18n.t("Cancel Drug Order?")}</div>
          <div>{i18n.t("Do you really want to cancel your order?")}</div>
          <div className="popup-btns">
            <div className="popup-approve-btn"
                 onClick={() => {
                   handleClose();
                 }}
            >
              {i18n.t("No")}
            </div>
            <div className="popup-delete-btn popup-delete-btn-2"
                 onClick={() => {
                   handleClose();
                   setConfirmStatus('canceled')
                 }}
            >
              {i18n.t("Yes")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
