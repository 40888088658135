import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/ClinicEmployees.css";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
import { ACTIONS, PermissionsArray, RoleArray, StatusArray, StatusInfo } from "../../Utils/constants";
import Header from "../../Layouts/Header/Header";
import { DropdownDots, RemoveSVG } from "../../Assets/Images/svg";
import EmployeeDropdown from "../../Components/EmployeeDropdown";
import { InvitePopup } from "../../Components/InvitePopup";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeesList } from "../../Store/actions/employeeActions";
import { getClinicById } from "../../Store/actions/clinicActions";
import { EmptyClinicProfileImgLight, EmptyUserProfileImgForTable, InfoIco } from "../../Assets/Images/img";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import { paths } from "../../Utils/Routes";
import { RemoveEmployeePopup } from "../../Components/RemoveEmployeePopup";
import PaginationButton from "../../Components/PaginationButton";


export default function ManageEmployees() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { filteredItems, page, loadNext, isReady, items } = useSelector(state => state.employeesReducer);
  const { clinic_id_clinics_clinicEmployees, role } = useSelector(state => state.userReducer.user);
  const { clinic } = useSelector(state => state.clinicReducer);
  const registeredEmployees = filteredItems.filter((employee) => !!employee.firstName);
  
  const [query, setQuery] = useState("");
  
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [isSent, setIsSent] = useState(false);
  
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);
  
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (role && clinic_id_clinics_clinicEmployees && role !== RoleArray.SUPER_ADMIN) {
      dispatch(getClinicById(clinic_id_clinics_clinicEmployees[0]?.uuid, i18n))
    }
  }, [role, clinic_id_clinics_clinicEmployees])
  
  useEffect(() => {
    if (query) {
      dispatch({
        type: ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY
      });
      setTimeout(() => {
        dispatch({
          type: ACTIONS.EMPLOYEES.SET_FILTERED_EMPLOYEES_ARRAY,
          payload: items.filter(item =>
            (item?.firstName + item?.lastName).replaceAll(" ", "").toLowerCase().includes(query)
          )
        });
      }, 150);
    }
  }, [query]);
  
  useEffect(() => {
    if (!isReady && !query) {
      if (!!items.length) {
        dispatch({
          type: ACTIONS.EMPLOYEES.FILTER_EMPLOYEES_FILTERED_ARRAY
        });
      } else {
        dispatch({
          type: ACTIONS.EMPLOYEES.CLEAR_DATA
        });
        dispatch(getEmployeesList(query, 0, i18n));
        setTimeout(() => {
          dispatch(getEmployeesList(query, 1, i18n));
        }, 150);
      }
    }
  }, [isReady, query]);
  const handleNextEmployees = useCallback(() => {
    dispatch(getEmployeesList(query, page, i18n));
  }, [query, page]);
  
  const changeHandler = (event) => {
    if (!event.target.value) {
      dispatch({
        type: ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY
      });
    } else {
      dispatch({
        type: ACTIONS.EMPLOYEES.SET_PAGE, payload: 0
      });
    }
    setQuery(event.target.value.replaceAll(" ", "").toLowerCase());
  };
  
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);
  
  const handleCloseInvitePopup = () => setShowInvitePopup(false);
  const handleCloseRemovePopup = () => setShowRemovePopup(false);
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading">
            <h1 className="margin-text"><Trans>{clinic?.name}</Trans></h1>
            <div className="search small-search">
              <div className="search-input-wrap">
                <input className="search-input"
                       ref={inputRef}
                       placeholder={i18n.t("Search")}
                       maxLength="30"
                       onChange={debouncedChangeHandler}
                />
                {query &&
                  <div className="search-clear"
                       onClick={() => {
                         setQuery("");
                         inputRef.current.value = "";
                         dispatch({
                           type: ACTIONS.EMPLOYEES.FILTER_EMPLOYEES_FILTERED_ARRAY
                         });
                       }}
                  >
                    ✕
                  </div>
                }
              </div>
            </div>
          </div>
          {(role !== RoleArray.SUPER_ADMIN) &&
            <div className='employee-subtitle-sect'>
              <div>
                <div className="manage-img">
                  <div className="circle-crop-100">
                    <img alt="avatar"
                         src={clinic?.image_url || EmptyClinicProfileImgLight}
                    />
                  </div>
                </div>
                <h2 className='employee-subtitle'>Related employees</h2>
              </div>
              <div className="manage-patient-add-btn mt-20"
                   onClick={() => setShowInvitePopup(!showInvitePopup)}>
                + <Trans>Invite New Employee</Trans>
              </div>
            </div>
          }
          <div className="manage-patient-table">
            <div
              className="table-heading"
              style={!registeredEmployees.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
            >
              <div className="table-col-user">
                <Trans>Name</Trans>
              </div>
              <div className="table-col table-col-user-clinic">
                <Trans>Email</Trans>
              </div>
              <div className="table-col table-role-col">
                <Trans>Doctor</Trans>
              </div>
              <div className="table-col table-role-col">
                <Trans>Manager</Trans>
              </div>
              <div className="table-col">
                <Trans>Clinic Administrator</Trans>
              </div>
              <div className="table-col">
                <Trans>Status</Trans>
              </div>
              {(role !== RoleArray.SUPER_ADMIN) &&
                <div className="table-col table-col-dots"/>
              }
            </div>
            {!registeredEmployees.length ?
              <SearchNoFound/>
              :
              registeredEmployees.map((employee, i) =>
                <div className='card' key={`doctorCard ${i}`}>
                  <div className='card-header'>
                    <Link className="table-link"
                          to={employee?.account_status === StatusArray.PENDING ?
                            `${paths.employees}/${employee?.uuid}/approve`
                            :
                            `${paths.employees}/${employee?.uuid}`
                          }
                    
                    >
                      <div className="table-col-user">
                        <img alt="avatar"
                             src={employee?.photo_url || EmptyUserProfileImgForTable(i)}
                        />
                        <div className="table-col ml-10">
                          <div>{employee?.firstName} {employee?.lastName}</div>
                        </div>
                      </div>
                    </Link>
                    <div className="table-col table-col-user-clinic">{employee?.email}</div>
                    <div className="table-col table-role-col">
                      {employee?.permission_id_permissions?.some(obj => obj.id === PermissionsArray?.DOCTOR ||
                        obj?.permission_id === PermissionsArray?.DOCTOR
                      ) ?
                        i18n.t('Yes')
                        :
                        i18n.t('No')
                      }
                    </div>
                    <div className="table-col table-role-col">
                      {employee?.permission_id_permissions?.some(obj => obj.id === PermissionsArray?.REQUEST_MANAGER ||
                        obj?.permission_id === PermissionsArray?.REQUEST_MANAGER
                      ) ?
                        i18n.t('Yes')
                        :
                        i18n.t('No')
                      }
                    </div>
                    <div className="table-col">
                      {employee?.permission_id_permissions?.some(obj => obj.id === PermissionsArray?.CLINIC_ADMIN ||
                        obj?.permission_id === PermissionsArray?.CLINIC_ADMIN
                      ) ?
                        i18n.t('Yes')
                        :
                        i18n.t('No')
                      }
                    </div>
                    <div className="table-col">
                      <StatusInfo statusId={employee?.account_status}/>
                    </div>
                    {(role !== RoleArray.SUPER_ADMIN) &&
                      <div className="table-col table-col-dots">
                        {(employee?.account_status !== StatusArray.CANCELED) &&
                          <Fragment>
                            <div onClick={() => setIsOpenDropdown(employee?.id)} className='dots'>
                              {DropdownDots}
                            </div>
                            <EmployeeDropdown item={employee} isOpenDropdown={isOpenDropdown}
                                              setIsOpenDropdown={setIsOpenDropdown}
                            />
                            
                          </Fragment>
                        }
                        <div className="table-remove-btn dots"
                             onClick={() => setShowRemovePopup(employee)}
                        >
                          {RemoveSVG}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )}
          </div>
          {(role !== RoleArray.SUPER_ADMIN) &&
            <div className='employee-info'>
              <img alt='info' src={InfoIco}/>
              <p>
                {i18n.t("Іf you want to stop being an administrator of the clinic, transfer your rights to another employee")}
              </p>
            </div>
          }
          {(!!registeredEmployees.length && loadNext) &&
            <PaginationButton handleNext={handleNextEmployees}/>
          }
        
        </section>
      </main>
      
      <InvitePopup type='Employee' isSent={isSent} setIsSent={setIsSent}
                   show={showInvitePopup} handleClose={handleCloseInvitePopup}
      />
      <RemoveEmployeePopup show={showRemovePopup} handleClose={handleCloseRemovePopup}/>
    </>
  );
}
