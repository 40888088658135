import { toast } from "react-toastify";
import { apiGet, apiPost } from "../../http/headerPlaceholder.instance";
import { ACTIONS, calculateOffset, LIMIT_ITEM_PER_PAGE, RoleArray } from "../../Utils/constants";
import { checkAuth } from "./userActions";

export const getFeedbackList = ( order = "DESC", value = '', page = 0, i18n, role, clinicId) => {
  return async (dispatch) => {
    try {
      if (role === RoleArray.EMPLOYEE) {
        const { data } = await apiGet({
          url: `/doctor-feedbacks/all?clinicId=${clinicId}`
        });
  
        dispatch({
          type: ACTIONS.FEEDBACK.SET_FILTERED_ARRAY,
          payload: {
            data,
            order
          }
        });
      }
      else {
        const { data } = await apiGet({
          url: `/feedbacks/all?text=${value}&order=${order}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}`
        });
  
        dispatch({
          type: ACTIONS.FEEDBACK.SET_FILTERED_ARRAY,
          payload: {
            data,
            order
          }
        });
      }
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const postFeedback = (userId, message, stars, i18n) => {
  return async (dispatch) => {
    await apiPost({
      url: `/feedbacks/`,
      data: {
        userId,
        message,
        stars
      }
    })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth())
        }
        toast.error(i18n.t("Something went wrong"));
      })
  }
}