import React from "react";
import { Trans } from "react-i18next";
import { Field } from "formik";
import ValidationError from "../../../../Components/ValidationError";
import { CrossedPasswordIco, FlagIco, InputWarningIco, PasswordIco } from "../../../../Assets/Images/img";


export const ClinicField = ({ errors, touched, type, displayName, name, maxLength = null, required = true }) => {
  return (
    <div className="clinic-input-container">
      <label className="clinic-label"><Trans>{displayName}</Trans></label>
      <Field
        type={type}
        name={name}
        maxLength={maxLength || undefined} required={required}
        className={`
        clinic-field ${displayName === 'Clinic Telephone' ? 'input-phone' : ''}
        ${(errors[name] && touched[name]) ? 'input-red-border' : ''}
                 `}
      />
      {displayName === 'Clinic Telephone' &&
        <div className='input-icons-start'>
          <img alt="ico"
               src={FlagIco}
          />
          <p>+49</p>
        </div>
      }
      <div className='input-icons'>
        {(errors[name] && touched[name]) &&
          <img alt="show_pw" src={InputWarningIco}/>
        }
      </div>
      <ValidationError errors={errors[name]} touched={touched[name]}/>
    </div>
  )
};
