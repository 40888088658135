import axios from "axios";
import { isProd } from "../Utils/constants";

// Create a new AbortController instance
const abortController = new AbortController();

const baseURL = isProd ?
  "https://app.meplano.de/" :
  "https://devserver.meplano.de/";
// const baseURL = "http://localhost:5001/";
export const apiGet = axios.create({ baseURL, signal: abortController.signal });
export const apiPost = axios.create({ baseURL, signal: abortController.signal });
export const apiDelete = axios.create({ baseURL, signal: abortController.signal });
export const apiPut = axios.create({ baseURL, signal: abortController.signal });

apiGet.interceptors.request.use((config) => {
  config.method = "get";
  const token = localStorage.getItem("accessToken");
  if (token)
    config.headers = {
      Authorization: "Bearer " + token,
    };
  return config;
});

apiPost.interceptors.request.use((config) => {
  config.method = "post";
  const token = localStorage.getItem("accessToken");
  if (token)
    config.headers = {
      Authorization: "Bearer " + token,
    };
  return config;
});

apiDelete.interceptors.request.use((config) => {
  config.method = "delete";
  const token = localStorage.getItem("accessToken");
  if (token)
    config.headers = {
      Authorization: "Bearer " + token,
    };
  return config;
});

apiPut.interceptors.request.use((config) => {
  config.method = "put";
  const token = localStorage.getItem("accessToken");
  if (token)
    config.headers = {
      Authorization: "Bearer " + token,
    };
  return config;
});
