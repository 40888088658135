import { ACTIONS } from "../../Utils/constants";

const initialState = {
  user: {
    role: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    telephone: "",
    userPhoto: "",
    LANR: "",
    specialties: [],
    primarySpecialty: ""
  },
  captchaToken: ""
};


const inviteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.INVITE_USER.SET_INVITE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    }
    case ACTIONS.INVITE_USER.SET_CAPTCHA_TOKEN: {
      return {
        ...state,
        captchaToken: action.payload
      }
    }
    
    
    default:
      return state;
  }
};

export default inviteUserReducer;
