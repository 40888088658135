import "../../Assets/Styles/ManageProfile.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { EditDoctorSchema, EditUserSchema } from "../../Utils/ValidationSchemas";
import { Trans, useTranslation } from "react-i18next";
import { getClinicsList } from "../../Store/actions/clinicActions";
import Header from "../../Layouts/Header/Header";
import Loading from "../../Layouts/Loading/Loading";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import {
  capitalizeFirstLetter,
  countDateForDatePicker,
  PermissionsArray,
  RoleArray,
  theme
} from "../../Utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { BlockedToEditImg, EmptyUserProfileImgLight } from "../../Assets/Images/img";
import { editEmployee, EditEmployeePhoto, getEmployee } from "../../Store/actions/employeeActions";
import { RemoveEmployeePopup } from "../../Components/RemoveEmployeePopup";
import ValidationError from "../../Components/ValidationError";
import dayjs from "dayjs";

export default function EditEmployeeInfo() {
  const { i18n } = useTranslation();
  
  const { id } = useParams();
  
  const dispatch = useDispatch();
  const reader = new FileReader();
  
  const { role } = useSelector(state => state.userReducer.user);
  const { employee } = useSelector(state => state.employeesReducer);
  const specialtiesList = useSelector(state => state.specialtyReducer.items);
  const isClinicsReady = useSelector(state => state.clinicReducer.isReady);
  
  const primarySpecialty = employee?.doctor?.specialty_id_specialties?.find(item => item.SpecialtyToDoctor.is_main);
  const secondarySpecialty = employee?.doctor?.specialty_id_specialties?.filter(item => !item.SpecialtyToDoctor.is_main) || [];
  
  
  const [show, setShow] = useState(false);
  const [birthDate, setBirthDate] = useState(employee?.birth_date);
  
  useEffect(() => {
    setBirthDate(employee?.birth_date);
  },[employee?.birth_date]);
  
  useEffect(() => {
    if (!isClinicsReady) {
      dispatch(getClinicsList(i18n));
    }
  }, []);
  
  useEffect(() => {
    dispatch(getEmployee(id, i18n));
  }, [id]);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(employee);
  
  if (id !== employee?.uuid) {
    return <Loading/>;
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect">
          <h1><Trans>Employee Profile</Trans></h1>
          <div className="manage-img">
            <div className="circle-crop-100">
              <img alt="avatar"
                   src={employee?.photo_url || EmptyUserProfileImgLight}
              />
            </div>
            {(role === RoleArray.SUPER_ADMIN) &&
              <>
                {employee?.photo_url &&
                  <label className="manage-delete_file"
                         onClick={() => dispatch(EditEmployeePhoto(employee?.uuid, '', i18n))}
                  />
                }
                <label htmlFor="files" className="manage-upload_file"/>
                <input
                  name="attachment"
                  type="file"
                  id="files"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onloadend = () => {
                      dispatch(EditEmployeePhoto(employee?.uuid, reader.result, i18n))
                    }
                  }}
                />
              </>
            }
          </div>
          <div className="manage-form">
            <Formik
              initialValues={{
                first_name: employee?.firstName,
                last_name: employee?.lastName,
                primarySpecialty: primarySpecialty?.id,
                secondarySpecialty: secondarySpecialty[0]?.id || "-1",
                location: employee?.location || "",
                telephone: employee?.telephone,
                email: employee?.email,
                bsnr: employee?.doctor?.bsnr
              }}
              validationSchema={employee?.permission_id_permissions?.some(obj => obj.id === PermissionsArray.DOCTOR) ?
                EditDoctorSchema
                :
                EditUserSchema
              }
              onSubmit={(values) => {
                dispatch(editEmployee(employee?.uuid, values, i18n, birthDate))
              }}
            >
              {({ errors, touched, handleChange }) => (
                <Form className="manage-form-col">
                  <h2>{`${employee.firstName} ${employee?.lastName}`}</h2>
                  <div className="input-container">
                    <label><Trans>First Name</Trans></label>
                    <Field
                      type="text"
                      name="first_name"
                      maxLength="25" required
                      disabled={role !== RoleArray.SUPER_ADMIN}
                    />
                    <ValidationError errors={errors.first_name} touched={touched.first_name}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Last Name</Trans></label>
                    <Field
                      type="text"
                      name="last_name"
                      maxLength="25" required
                      disabled={role !== RoleArray.SUPER_ADMIN}
                    />
                    <ValidationError errors={errors.last_name} touched={touched.last_name}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Birth date</Trans></label>
                    {(role === RoleArray.SUPER_ADMIN) ?
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                          <ThemeProvider theme={theme}>
                            <DatePicker
                              className='date-picker light-date-picker'
                              views={['year', 'month', 'day']}
                              inputFormat="DD.MM.YYYY"
                              openTo={"year"}
                              maxDate={countDateForDatePicker(18)}
                              disableFuture
                              value={birthDate || employee?.birth_date}
                              onChange={(value) => {
                                setBirthDate(new Date(value));
                              }}
                              renderInput={(params) =>
                                <TextField {...params} helperText={null}/>
                              }
                            />
                          </ThemeProvider>
                        </LocalizationProvider>
                      </>
                      :
                      <Field type="text" disabled
                             value={dayjs(employee?.birth_date).format('DD.MM.YYYY')}
                      />
                    }
                  </div>
                  {employee?.permission_id_permissions?.some(obj => obj.id === PermissionsArray.DOCTOR) &&
                    <>
                      <div className="input-container">
                        <label><Trans>Primary Specialty</Trans></label>
                        {role !== RoleArray.SUPER_ADMIN
                          ?
                          <Field type="text" disabled
                                 value={employee?.doctor?.specialty_id_specialties[0]?.description}
                          />
                          :
                          <>
                            <div className='select-input'>
                              <Field as="select" name="primarySpecialty"
                                     disabled={role !== RoleArray.SUPER_ADMIN}
                              >
                                {!primarySpecialty &&
                                  <option value=""/>
                                }
                                {specialtiesList.map(item =>
                                  <option value={item.id} key={`${item.id}${item.description}`}>
                                    {capitalizeFirstLetter(i18n.t(`${item.description}`))}
                                  </option>
                                )}
                              </Field>
                            </div>
                            <ValidationError errors={errors.primarySpecialty}
                                             touched={touched.primarySpecialty}
                            />
                          </>
                        }
                      
                      </div>
                      <div className="input-container">
                        <label><Trans>Secondary Specialty</Trans></label>
                        
                        {role !== RoleArray.SUPER_ADMIN
                          ?
                          <Field type="text" disabled
                                 value={employee?.doctor?.specialty_id_specialties[1]?.description}
                          />
                          :
                          <>
                            <div className='select-input'>
                              <Field as="select" name="secondarySpecialty"
                                     disabled={role !== RoleArray.SUPER_ADMIN}
                              >
                                {!secondarySpecialty &&
                                  <option value=""/>
                                }
                                {specialtiesList.map(item =>
                                  <option value={item.id} key={`${item.id}${item.description}`}>
                                    {capitalizeFirstLetter(i18n.t(`${item.description}`))}
                                  </option>
                                )}
                              </Field>
                            </div>
                            <ValidationError errors={errors.secondarySpecialty}
                                             touched={touched.secondarySpecialty}
                            />
                          </>
                        }
                      </div>
                      <div className="input-container">
                        <label><Trans>BSNR Number</Trans></label>
                        <Field
                          type="text"
                          name="bsnr"
                          maxLength="40" required
                          disabled={role !== RoleArray.SUPER_ADMIN}
                        />
                        <ValidationError errors={errors.bsnr} touched={touched.bsnr}/>
                      </div>
                    </>
                  }
                  <div className="input-container">
                    <label><Trans>Address</Trans></label>
                    <Field
                      type="text"
                      name="location"
                      required
                      disabled={role !== RoleArray.SUPER_ADMIN}
                    />
                    <ValidationError errors={errors.location} touched={touched.location}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Telephone</Trans></label>
                    <Field
                      type="tel"
                      name="telephone"
                      maxLength="20" required
                      disabled={role !== RoleArray.SUPER_ADMIN}
                      onChange={(event) => {
                        const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                        handleChange(event);
                        handleChange({ target: { name: 'telephone', value: cleanedValue } });
                      }}
                    />
                    <ValidationError errors={errors.telephone} touched={touched.telephone}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Email</Trans></label>
                    <Field
                      type="email"
                      name="email"
                      maxLength="40" required disabled
                    />
                    {(role === RoleArray.SUPER_ADMIN) &&
                      <img alt="show_pw"
                           src={BlockedToEditImg}
                           className="input-end-img"
                      />
                    }
                    <ValidationError errors={errors.email} touched={touched.email}/>
                  </div>
                  {(role === RoleArray.SUPER_ADMIN) &&
                    <>
                      <div className="button-container manage-btn">
                        <input type="submit"
                               value={i18n.t("Save Changes")}
                               className="submit-form-button scale-btn"
                        />
                      </div>
                      <p
                        className="warning-btn form-btn btn scale-btn"
                        onClick={() => handleShow()}
                      >
                        <Trans>Delete Account</Trans>
                      </p>
                    </>
                  }
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </main>
      
      <RemoveEmployeePopup handleClose={handleClose} show={show}/>
    
    </>
  );
}
