import "../../Assets/Styles/ManageProfile.css";
import "../../Assets/Styles/WorkingHours.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import Loading from "../../Layouts/Loading/Loading";
import { getClinicById, } from "../../Store/actions/clinicActions";
import { ActiveBtn } from "../../Components/ActiveBtn";
import ClinicProfileForm from "./ClinicProfileForm";
import Banners from "../Banners/Banners";
import { RoleArray } from "../../Utils/constants";


export default function ClinicProfile() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { uuid } = useParams();
  
  const user = useSelector(state => state.userReducer.user);
  const mangersClinic = useSelector(state => state.userReducer.user?.clinic_id_clinics_clinicEmployees)
  const clinic = useSelector(state => state.clinicReducer.clinic)
  
  const [display, setDisplay] = useState(<ClinicProfileForm/>);
  
  useEffect(() => {
    if (user?.role === RoleArray.SUPER_ADMIN) {
      dispatch(getClinicById(uuid, i18n))
    }
    
    if (mangersClinic) {
      dispatch(getClinicById(mangersClinic[0]?.uuid, i18n))
    }
  }, [uuid, mangersClinic]);
  
  if (!clinic || (uuid && uuid !== clinic?.uuid)) {
    return <Loading/>
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect">
          <div className='clinic-profile-header'>
            <h1><Trans>Clinic Profile</Trans></h1>
            <div className="patient-info-buttons">
              <div className="patient-info-btn active-btn"
                   onClick={(event) => {
                     setDisplay(<ClinicProfileForm/>);
                     ActiveBtn(event);
                   }}
              >
                <Trans>Clinic Profile</Trans>
              </div>
              <div className="patient-info-btn"
                   onClick={(event) => {
                     setDisplay(<Banners id={clinic?.uuid}/>);
                     ActiveBtn(event);
                   }}
              >
                <Trans>Banner</Trans>
              </div>
            </div>
          </div>
          {display}
        </section>
      </main>
    </>
  );
}
