import { useEffect } from 'react';

const CountdownTimer = ({ initialSeconds = 60, setInitialSeconds, setIsAvailable }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setInitialSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const reloadTimer = () => {
    setInitialSeconds(0);
    setIsAvailable(true);
  }
  
  return initialSeconds > 0 ? <p className='count-down'>in {initialSeconds}s</p> : reloadTimer()
};

export default CountdownTimer;