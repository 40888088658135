import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./Locales/En/translation.json";
import translationDE from "./Locales/De/translation.json";
import { isProd } from "./constants";

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: !isProd && !localStorage.getItem("i18n") ? "de" : localStorage.getItem("i18n"),
    keySeparator: false,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;