import "dayjs/locale/de";
import "../../Assets/Styles/Approve.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { editNotice } from "../../Store/actions/appointmentActions";
import {
  appointmentsTypes,
  AppointmentTypeText,
  DoctorInfoBlockCol,
  RequestText,
  timeBlocks,
  UrgencyArray
} from "../../Utils/constants";
import Approve from "./Approve";
import PartialApprove from "./PartialApprove";
import { PencilImg } from "../../Assets/Images/img";
import dayjs from "dayjs";
import { ARROWS } from "../../Assets/Images/svg";
import Reject from "./Reject";
import { Doctor2DoctorDropdown } from "./Doctor2DoctorDropdown";


const ApprovePopup = (item, pendingItems, setPendingItems, openDropdown, setOpenDropdown) => {
  switch (true) {
    case item?.type === "doctor transfer" && !!item?.doctor_id && !!item?.sender_id:
      return <Approve
        item={item}
        pendingItems={pendingItems} setPendingItems={setPendingItems}
        openDropdown={openDropdown} setOpenDropdown={setOpenDropdown}
      />
    case item?.type === "doctor transfer" && (!item?.doctor_id || !item?.sender_id):
      return <Doctor2DoctorDropdown setOpenDropdown={setOpenDropdown} item={item} openDropdown={openDropdown}
                                    setPendingItems={setPendingItems} pendingItems={pendingItems}
      />
    case item?.type === "drug order":
      return <PartialApprove
        item={item}
        pendingItems={pendingItems} setPendingItems={setPendingItems}
        openDropdown={openDropdown} setOpenDropdown={setOpenDropdown}
      />
    case item?.type === "appointment":
      return <Approve
        item={item}
        pendingItems={pendingItems} setPendingItems={setPendingItems}
        openDropdown={openDropdown} setOpenDropdown={setOpenDropdown}
      />
    default:
      return null;
  }
};

export default function PendingAppointment({
                                             item, i, open, setOpen,
                                             pendingItems, setPendingItems, openDropdown, setOpenDropdown
                                           }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [notice, setNotice] = useState(null);
  
  return (
    <>
      <div className='request-card-body-wrap'>
        <div className='request-card-body' key={`${i}-pending-card`}>
          <div className='request-card-col'>{AppointmentTypeText(item)}</div>
          <div className='request-card-col'>
            {item?.type === "drug order" ?
              <div className='request-card-col-drugs'>
                <div className='request-card-info-label'>
                  <Trans>Medicine</Trans>
                  {` (${item?.drugToOrders.length ?? 0} `}
                  <Trans>items</Trans>)
                </div>
                <div className='request-card-info-value'>
                  {item?.drugToOrders?.map((el, i) =>
                    <p key={`${i}-drug-item`}>{el.drug?.description}</p>
                  )}
                </div>
              </div>
              :
              RequestText(item)
            }
          </div>
          <div className='request-card-col'>{item?.reason ?? '-'}</div>
          <div className="request-card-col">
            <div>
              <div className='btn' onClick={() => setOpenDropdown(`${item?.id}-notice`)}>
                <img alt="pencil" src={PencilImg}/>
              </div>
              <div className="position-relative"
                   style={openDropdown === `${item?.id}-notice` ? { display: "block" } : { display: "none" }}
              >
                <div className="item-dropdown notice-dropdown">
                  <div className="approve-cancel btn"
                       onClick={(event) => {
                         event.preventDefault();
                         
                         setOpenDropdown(null);
                       }}
                  >
                    ✖
                  </div>
                  <textarea
                    maxLength="100"
                    defaultValue={!item?.doctor_notice ? "" : item?.doctor_notice}
                    onChange={(event) => {
                      setNotice(event.target.value)
                    }}
                  />
                  <div className="select-btn btn scale-btn"
                       onClick={() => {
                         dispatch(editNotice(appointmentsTypes[item?.type], item?.id, notice, i18n));
                         setPendingItems({
                           items: pendingItems.items?.map(el => {
                             if (el.id === item.id) {
                               return {
                                 ...item,
                                 doctor_notice: notice
                               }
                             } else {
                               return el
                             }
                           }),
                           filteredItems: pendingItems.filteredItems?.map(el => {
                             if (el.id === item.id) {
                               return {
                                 ...item,
                                 doctor_notice: notice
                               }
                             } else {
                               return el
                             }
                           })
                         })
                         setOpenDropdown(null);
                       }}
                  >
                    <Trans>Done</Trans>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {(!item?.doctor_notice || item?.doctor_notice === "") ?
                i18n.t("Add notice")
                :
                item?.doctor_notice
              }
            </div>
          </div>
          <div className='request-card-col'>
            {i18n.t(`${timeBlocks[item?.day_block_id].heading}`)}:<br/>
            {dayjs(item?.date).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className='request-card-col-btns'>
            <div className='request-card-col-btn request-card-approve' onClick={() => setOpenDropdown(item?.id)}>
              <Trans>Approve</Trans>
            </div>
            <div className='request-card-col-btn request-card-reject'
                 onClick={() => setOpenDropdown(`${item?.id}-reject`)}
            >
              <Trans>Reject</Trans>
            </div>
            
            {ApprovePopup(item, pendingItems, setPendingItems, openDropdown, setOpenDropdown)}
            <Reject item={item}
                    pendingItems={pendingItems} setPendingItems={setPendingItems}
                    openDropdown={openDropdown} setOpenDropdown={setOpenDropdown}
            />
          </div>
        </div>
        
        {(pendingItems.filteredItems.length === 1 || open === `${i}card`) &&
          <div className='request-card-body' key={`${i}card`}>
            <div className='request-card-col request-card-col-info'>
              <div className='request-card-info-label'>ID</div>
              <div className='request-card-info-value'>{item?.id}</div>
            </div>
            <div className='request-card-col request-card-col-info'>
              <div className='request-card-info-label'><Trans>Date / Time of sent request</Trans></div>
              <div className='request-card-info-value'>{dayjs(item?.created_at).format("DD.MM.YYYY HH:mm")}</div>
            </div>
            <div className='request-card-col'>
              <DoctorInfoBlockCol doctor={item?.type === 'doctor transfer' ? item?.sender : item?.doctor}
                                  appointmentType={item?.type}
                                  doctorType={item?.type === 'doctor transfer' ? 'Sender' : null}
              />
            </div>
            <div className="request-card-col request-card-col-info">
              <div className='request-card-info-label'>
                {item?.type === "drug order" ? i18n.t('Urgency') : i18n.t('Doctor Comment')}
              </div>
              <div className='request-card-info-value'
                   style={item?.type === "drug order" ?
                     { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                     :
                     { color: '#343760' }}
              >
                {item?.type === "drug order" ?
                  item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'
                  : (!item?.notice || item?.notice === "") ?
                    '-'
                    :
                    item?.notice
                }
              </div>
            </div>
            {item?.type === "drug order" ?
              <div className='request-card-col-drugs'/>
              :
              <div className={`request-card-col ${item?.type === 'appointment' ? 'request-card-col-info' : ''}`}>
                {item?.type === "doctor transfer" ?
                  <DoctorInfoBlockCol doctor={item?.doctor} appointmentType={item?.type}
                                      doctorType={item?.type === 'doctor transfer' ? 'Target' : null}
                                      specialty={item?.type === 'doctor transfer' ? item?.specialty : null}
                  />
                  :
                  <>
                    <div className='request-card-info-label'>
                      <Trans>Urgency</Trans>
                    </div>
                    <div className='request-card-info-value'
                         style={item?.type !== "drug order" ?
                           { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                           :
                           { color: '#343760' }}
                    >
                      {item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'}
                    </div>
                  </>
                }
              </div>
            }
            {item?.type === "doctor transfer" ?
              <div className='request-card-col-btns request-card-col-info'>
                <div className='request-card-info-label'><Trans>Urgency</Trans></div>
                <div className='request-card-info-value'
                     style={item?.type !== "drug order" ?
                       { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                       :
                       { color: '#343760' }}
                >
                  {item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'}
                </div>
              </div>
              : item?.type === "appointment" ?
                <div className='request-card-col-btns request-card-col-info'/>
                :
                null
            }
          </div>
        }
        
        {(pendingItems.filteredItems.length > 1) &&
          <div className='request-card-details-btn'>
            <div className='request-card-details-line'/>
            <p className="arrow-down request-card-arrow-down"
               onClick={() => setOpen(open === `${i}card` ? false : `${i}card`)}
            >
              {open === `${i}card` ? ARROWS.GREY_TOP : ARROWS.GREY_BOTTOM} <Trans>Details</Trans>
            </p>
            <div className='request-card-details-line'/>
          </div>
        }
      </div>
    </>
  );
}
