import "../../Assets/Styles/NoFound.css";
import { Trans } from "react-i18next";
import { NoFoundImg } from "../../Assets/Images/img";

export default function SearchNoFound() {
  return (
    <div className="no-found">
      <img alt="no_found" src={NoFoundImg}/>
      <div>
        <h1><Trans>No results found</Trans></h1>
        <p><Trans>Please check the correct spelling and search again</Trans></p>
      </div>
    </div>
  );
}
