import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/PatientInfo.css";
import "dayjs/locale/de";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import { EmptyClinicProfileImgForTable } from "../../Assets/Images/img";
import React from "react";


export default function PatientInfoClinics() {
  const clinics = useSelector(state => state.patientReducer?.patientAdditionalInfo.clinics);
  
  return (
    <>
      <div className="manage-patient-table">
        <div className="table-heading"
             style={!clinics.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
        >
          <div className="table-col-user">
            <Trans>Clinic</Trans>
          </div>
          <div className="table-col">
            <Trans>Address</Trans>
          </div>
          <div className="table-col">
            <Trans>Phone</Trans>
          </div>
          <div className="table-col"/>
        </div>
        {!clinics.length ?
          <SearchNoFound/>
          :
          clinics.map((item, i) =>
            <div className='card' key={`${item.id}-doctor`}>
              <div className='card-header'>
                <div className="table-col-user">
                  <img alt='img'
                       src={item?.image_url || EmptyClinicProfileImgForTable(i)}
                  />
                  <div className="table-col ml-10">
                    <div>{`${item?.name}`}</div>
                  </div>
                </div>
                <div className="table-col">{item?.location}</div>
                <div className="table-col">{item?.telephone}</div>
                <div className="table-col"/>
              </div>
            </div>
          )}
      </div>
    </>
  )
}
