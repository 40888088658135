import {ACTIONS} from "../../Utils/constants";
import {ForPatientComponentKeys} from "../../Utils/ConstantsForPatient";

const initialState = {
    breadcrumbs: [],
    component: ForPatientComponentKeys.CLINIC,
    data: {}
};


const bookAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS: {
            return {
                ...state,
               breadcrumbs: action.payload
            }
        }
        case ACTIONS.BOOK_APPOINTMENT.SET_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            }
        }
        case ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT: {
            return {
                ...state,
                component: action.payload
            }
        }

        case ACTIONS.BOOK_APPOINTMENT.CLEAR_DATA: {
            return {
                ...state,
                data: action.payload
            }
        }

        default:
            return state;
    }
};

export default bookAppointmentReducer;
