import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ACTIONS, isProd } from "../../../Utils/constants";
import 'dayjs/locale/de';
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";
import { useTranslation } from "react-i18next";
import { AfternoonBlockImg, EveningBlockImg, MorningBlockImg } from "../../../Assets/Images/img";
import dayjs from "dayjs";


export default function ChooseAppointmentDate() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  console.log("step 4");
  const { breadcrumbs, data } = useSelector(state => state.bookAppointmentReducer);
  
  const [selectedDate, setSelectedDate] = useState(dayjs());
  let daysArray = [];
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.APPOINTMENT_TYPE,
          label: 'Appointment'
        },
        {
          component: ForPatientComponentKeys.DATE,
          label: 'Date'
        }
      ]
    });
    
    if (data?.date) {
      setSelectedDate(dayjs(data?.date))
    }
  }, []);
  
  useEffect(() => {
    dayjs.locale(isProd || !localStorage.getItem("i18n") ? "de" : localStorage.getItem("i18n"));
  }, [localStorage.getItem("i18n"), isProd]);
  
  const generateDaysArray = (currentDate = dayjs()) => {
    while (daysArray.length < 14) {
      daysArray.push(currentDate.clone());
      currentDate = currentDate.clone().add(1, "day");
    }
  }
  
  if (!daysArray.length) {
    generateDaysArray()
  }
  
  const makeDayActive = (event) => {
    const elements = document.getElementsByClassName("active-date");
    for (let el of elements) {
      el.classList.remove("active-date");
    }
    event.currentTarget.classList.add("active-date");
  };
  
  const makeBlockActive = (event) => {
    const elements = document.getElementsByClassName("active-day-block");
    for (let el of elements) {
      el.classList.remove("active-day-block");
    }
    event.currentTarget.classList.add("active-day-block");
  };
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <div className='breadcrumbs-item' key={item.label + i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='date' type='appointment'/>
            <div className='list'>
              <div className='list-heading'>{i18n.t("Choose Date")}</div>
              <div className='list-date-heading'>
                {selectedDate.format('MMMM, YYYY')}
              </div>
              <div className='list-days'>
                {daysArray.length && daysArray.map((day, i) =>
                  <div key={`${day}-${i}`}
                       className={`list-day ${selectedDate.isSame(day, 'day') ? 'active-date' : ''}`}
                       onClick={(event => {
                         makeDayActive(event);
                         setSelectedDate(
                           selectedDate.set('year', day.year()).set('month', day.month()).set('date', day.date())
                         );
                       })}
                  >
                    <p>{day.format('ddd')}</p>
                    <p>{day.format('DD')}</p>
                  </div>
                )}
              </div>
              {(!selectedDate.isSame(dayjs(), 'day') || selectedDate.hour() < 8) &&
              <div className={`list-date-block ${dayjs(data?.date).hour() === 10 ? "active-day-block" : ""}`}
                   onClick={(event) => {
                     makeBlockActive(event);
                     setSelectedDate(selectedDate.set("hour", 10).set("minute", 30))
                   }}
              >
                <div className='list-date-block-info'>
                  <p>{i18n.t("Morning Block")}</p>
                  <p>8:00 {i18n.t("to")} 11:30</p>
                  <p>{i18n.t("Doctor will send you the time of the appointment")}</p>
                </div>
                <img alt='logo' src={MorningBlockImg}/>
              </div>
              }
              {(!selectedDate.isSame(dayjs(), 'day') || selectedDate.hour() < 13) &&
              <div className={`list-date-block ${dayjs(data?.date).hour() === 14 ? "active-day-block" : ""}`}
                   onClick={(event) => {
                     makeBlockActive(event);
                     setSelectedDate(selectedDate.set("hour", 14).set("minute", 30))
                   }}
              >
                <div className='list-date-block-info'>
                  <p>{i18n.t("Afternoon Block")}</p>
                  <p>13:00 {i18n.t("to")} 15:30</p>
                  <p>{i18n.t("Doctor will send you the time of the appointment")}</p>
                </div>
                <img alt='logo' src={AfternoonBlockImg}/>
              </div>
              }
              <div className={`list-date-block ${dayjs(data?.date).hour() === 17 ? "active-day-block" : ""}`}
                   onClick={(event) => {
                     makeBlockActive(event);
                     setSelectedDate(selectedDate.set("hour", 17).set("minute", 30))
                   }}
              >
                <div className='list-date-block-info'>
                  <p>{i18n.t("Evening Block")}</p>
                  <p>16:00 {i18n.t("to")} 19:30</p>
                  <p>{i18n.t("Doctor will send you the time of the appointment")}</p>
                </div>
                <img alt='logo' src={EveningBlockImg}/>
              </div>
              <button className='for-patients-btn btn scale-btn'
                      disabled={!document.getElementsByClassName("active-day-block").length}
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                          payload: {
                            date: selectedDate.format('ddd MMM DD HH:mm:ss [GMT]ZZ YYYY'),
                            isRegistered: null,
                          }
                        });
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                          payload: ForPatientComponentKeys.PATIENT_DETAILS
                        });
                      }}
              >
                {i18n.t("Continue")}
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}