import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/ManageOffice.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { getAppointmentTypesList, } from "../../Store/actions/clinicActions";
import { ActiveBtn, ActiveLangBtn } from "../../Components/ActiveBtn";
import {
  getDoctorAppointmentTypesList,
  updateDoctorAppointmentTypesList,
  updateDoctorHolidays
} from "../../Store/actions/doctorActions";
import { Field, Form, Formik } from "formik";
import { PencilImg } from "../../Assets/Images/img";
import { ACTIONS, PermissionsArray, RoleArray } from "../../Utils/constants";
import ValidationError from "../../Components/ValidationError";
import { getUserInfo } from "../../Store/actions/userActions";
import { WorkingStatusOnHoliday, WorkingStatusWork } from "../../Utils/ValidationSchemas";

export default function MySettings() {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const accountFormikRef = useRef();
  
  const { user } = useSelector(state => state.userReducer);
  const appointmentTypesList = useSelector((state) => state.clinicReducer.appointmentTypesList);
  const doctorAppointmentTypesList = useSelector((state) => state.doctorReducer.doctorAppointmentTypesList);
  
  const [isWorking, setIsWorking] = useState(user?.doctor?.is_working);
  
  useEffect(() => {
    if (!appointmentTypesList.length) {
      dispatch(getAppointmentTypesList(i18n));
    }
    
    if (!doctorAppointmentTypesList.length) {
      dispatch(getDoctorAppointmentTypesList(user?.uuid, i18n));
    }
    if (!user?.id) {
      dispatch(getUserInfo(user?.uuid, i18n, user?.role));
    }
  }, []);
  
  const saveChanges = () => {
    dispatch(updateDoctorAppointmentTypesList(user?.id, doctorAppointmentTypesList, i18n));
  };
  
  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading manage-office-heading">
            <h1><Trans>My Settings</Trans></h1>
            {user?.role !== RoleArray.SUPER_ADMIN &&
              <div className="manage-patient-buttons_group">
                <h3 className="manage-patient-buttons_heading"><Trans>On holiday</Trans></h3>
                <div className="manage-patient-buttons">
                  <div
                    id="yes"
                    className={`manage-patient-btn manage-patient-btn-50 ${!user?.doctor?.is_working && "active-btn"}`}
                    onClick={(event) => {
                      ActiveBtn(event);
                      setIsWorking(false);
                    }}
                  >
                    <Trans>Yes</Trans>
                  </div>
                  <div
                    id="no"
                    className={`manage-patient-btn manage-patient-btn-50 ${user?.doctor?.is_working && "active-btn"}`}
                    onClick={(event) => {
                      ActiveBtn(event);
                      setIsWorking(true);
                    }}
                  >
                    <Trans>No</Trans>
                  </div>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    working_status: user?.doctor?.working_status
                  }}
                  innerRef={accountFormikRef}
                  validationSchema={!isWorking ? WorkingStatusOnHoliday : WorkingStatusWork}
                  onSubmit={(values) => {
                    if ((values.working_status !== user?.doctor?.working_status || user?.doctor?.is_working !== isWorking)) {
                      dispatch({
                        type: ACTIONS.USER.SET_USER,
                        payload: {
                          ...user,
                          doctor: {
                            ...user.doctor,
                            is_working: isWorking,
                            working_status: values.working_status
                          }
                        }
                      });
                      if (isWorking) {
                        dispatch(updateDoctorHolidays(user?.id, isWorking, "", i18n, accountFormikRef))
                      } else {
                        dispatch(updateDoctorHolidays(user?.id, isWorking, values.working_status, i18n, accountFormikRef))
                      }
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="manage-form-col">
                      {!isWorking &&
                        <div className="input-container manage-patient-input">
                          <label><Trans>Description</Trans></label>
                          <Field
                            className="holiday-input"
                            ref={accountFormikRef}
                            type="text"
                            name="working_status"
                            maxLength="100"
                          />
                          <ValidationError errors={errors.working_status} touched={touched.working_status}/>
                          <img alt="pencil"
                               src={PencilImg}
                               className="input-img-right"
                          />
                        </div>
                      }
                      <div className="button-container manage-btn m-0">
                        <input type="submit"
                               value={i18n.t("Save Changes")}
                               className="submit-form-button scale-btn"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            }
            <div className="manage-patient-buttons_group">
              <h3 className="manage-patient-buttons_heading"><Trans>Language</Trans></h3>
              <div className="manage-patient-buttons">
                <div
                  id="de"
                  className={`manage-patient-btn manage-patient-btn-50 ${i18n.language === "de" ? "active-lang-button" : ""}`}
                  onClick={(event) => {
                    changeLanguageHandler("de");
                    localStorage.setItem("i18n", "de");
                    ActiveLangBtn(event);
                  }}
                >
                  De
                </div>
                <div
                  id="en"
                  className={`manage-patient-btn manage-patient-btn-50 ${i18n.language === "en" ? "active-lang-button" : ""}`}
                  onClick={(event) => {
                    changeLanguageHandler("en");
                    localStorage.setItem("i18n", "en");
                    ActiveLangBtn(event);
                  }}
                >
                  En
                </div>
              </div>
            </div>
          </div>
          {(user?.role !== RoleArray.SUPER_ADMIN && user?.permissions.some(item => item.id === PermissionsArray.DOCTOR)) &&
            <>
              <div className="manage-office-comment"><Trans>Appointment Types</Trans></div>
              <div className="manage-office-panel">
                <div className="manage-office-col">
                  {appointmentTypesList.map((el) => {
                    return (
                      <ToggleSwitch
                        key={`manage-appointment-type-${el.id}`}
                        element={el}
                        checked={Boolean(doctorAppointmentTypesList?.find(appT => appT.id === el.id))}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="button-container manage-btn mx-w-500">
                <button
                  className="submit-form-button scale-btn"
                  onClick={() => saveChanges()}
                >
                  <Trans>Save Changes</Trans>
                </button>
              </div>
            </>
          }
        </section>
      </main>
    </>
  );
}
