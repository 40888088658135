import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/Feedback.css";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { SuccessFeedbackImg } from "../../Assets/Images/img";

export default function FeedbackSuccess() {
  return (
    <>
      <section className="sect feedback-success-sect">
        <div className="feedback-success">
          <div>
            <p><Trans>Thank You!</Trans></p>
            <div><Trans>Your feedback has been sent successfully.</Trans></div>
          </div>
          <img alt="success" src={SuccessFeedbackImg}/>
          <Link className="submit-form-button feedback-success-btn btn scale-btn" to="/"><Trans>Ok</Trans></Link>
        </div>
      </section>
    </>
  )
}
