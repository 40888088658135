import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ChooseAuthType from "./ChooseAuthType";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import PatientsLogin from "./PatientsLogin";
import PatientsSignUp from "./PatientsSignUp";
import UserVerify from "../../Verification/UserVerify";
import PatientVerifyFailed from "./PatientVerifyFailed";
import { ConfirmationBreadcrumbsComponent } from "../../../Utils/ConstantsForPatient";


const PatientAuthSect = ({ isRegistered = false }) => {
  switch (isRegistered) {
    case 'login':
      return <PatientsLogin/>;
    case 'signUp':
      return <PatientsSignUp/>;
    case 'verify':
      return <UserVerify/>;
    case 'verify-failed':
      return <PatientVerifyFailed/>;
    default:
      return <ChooseAuthType/>;
  }
}

const GetAppointmentStepChain = ({ type }) => {
  switch (type) {
    case 'appointment':
      return <AppointmentStepChain active='details' type='appointment'/>
    case 'drug-order':
      return <AppointmentStepChain active='details' type='drug-order'/>
    case 'specialist-transfer':
      return <AppointmentStepChain active='details' type='doctor-transfer'/>;
  }
}

export default function PatientDetails() {
  console.log("step 5");
  const dispatch = useDispatch();
  
  const { breadcrumbs, data } = useSelector(state => state.bookAppointmentReducer);
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <ConfirmationBreadcrumbsComponent item={item} i={i} key={i} dispatch={dispatch} isLogin={data?.isLogin}/>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <GetAppointmentStepChain type={data?.appointment}/>
            <PatientAuthSect isRegistered={data?.isRegistered}/>
          </div>
        </section>
      </main>
    </>
  );
}
