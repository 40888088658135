import "../Assets/Styles/WorkingHours.css";
import { useTranslation } from "react-i18next";


export default function WorkingHoursString({ day, title, isActive = false }) {
  const { i18n } = useTranslation();
  
  return (
    <>
      <p className={`${isActive ? "active-day" : ""} working-hours-string`} key={"day-" + day}>
        {i18n.t(title)}
        {day ?
          day.map((hours, i) =>
            <>
              {` ${hours?.from} - ${hours?.to}`}
              {i !== day.length - 1 && <span>,</span>}
            </>
          )
          :
          ` ${i18n.t("Closed")}`
        }
      </p>
    </>
  );
}
