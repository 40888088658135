import "../../Assets/Styles/Banners.css";
import React from "react";
import SmallBanner from "./SmallBanner";
import LargeBanner from "./LargeBanner";


export default function Banners({ id }) {
  return (
    <>
      <div className='banners'>
        <SmallBanner bg="dark" link={`${window.location.origin}/clinics/${id}`} id={1}/>
        <SmallBanner bg="light" link={`${window.location.origin}/clinics/${id}`} id={2}/>
        <LargeBanner bg="dark" link={`${window.location.origin}/clinics/${id}`} id={3}/>
        <LargeBanner bg="light" link={`${window.location.origin}/clinics/${id}`} id={4}/>
      </div>
    </>
  );
}
