import { toast } from "react-toastify";
import { apiDelete, apiGet, apiPost, apiPut, } from "../../http/headerPlaceholder.instance";
import { ACTIONS, calculateOffset, inviteEmployeeReducerTypes, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";
import { checkAuth } from "./userActions";
import { paths } from "../../Utils/Routes";
import { InviteEmployeeComponentKeys } from "../../Utils/ConctantsInviteEmployee";
import dayjs from "dayjs";

export const getEmployeesList = (value = '', page = 0, i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/clinics/employees?name=${value}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}`
      });
      if (value) {
        dispatch({
          type: ACTIONS.EMPLOYEES.SET_FILTERED_EMPLOYEES_ARRAY,
          payload: data
        });
      } else {
        dispatch({
          type: ACTIONS.EMPLOYEES.SET_EMPLOYEES,
          payload: data
        });
      }
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getAllEmployeesList = (i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/clinics/employees`
      });
      
      dispatch({
        type: ACTIONS.EMPLOYEES.SET_FILTERED_EMPLOYEES_ARRAY,
        payload: data
      });
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getEmployee = (id, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/clinics/employees?uuid=${id}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.EMPLOYEES.SET_EMPLOYEE,
          payload: data[0]
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getCaretakers = (uuid, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/users/caretakers?uuid=${uuid}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENT_CARETAKERS,
          payload: data[0]
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const editEmployee = (userId, values, i18n, birthDate) => {
  const {
    first_name,
    last_name,
    primarySpecialty,
    secondarySpecialty,
    location,
    telephone,
    email,
    bsnr,
  } = values;
  
  return async (dispatch) => {
    apiPut({
      url: `clinics/employees/${userId}`,
      data: {
        firstName: first_name,
        lastName: last_name,
        birthDate: dayjs(birthDate).add(1, "day"),
        primarySpecialty,
        secondarySpecialty,
        location,
        telephone,
        email,
        bsnr
      }
    })
      .then(async () => {
        dispatch({
          type: ACTIONS.EMPLOYEES.CLEAR_DATA,
        });
        toast.success(i18n.t("Updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const EditEmployeePhoto = (userId, data, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `clinics/employees/${userId}/photo`,
      data: {
        data
      }
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.EMPLOYEES.EDIT_EMPLOYEE_PHOTO,
          payload: data.photo_url
        });
        toast.success(i18n.t("Updated!"))
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const editEmployeePermissions = (userId, permissionsList, setIsOpenDropdown, adminId, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/manager/update-permissions`,
      data: {
        userId,
        permissionsList
      }
    })
      .then(async ({ data }) => {
        if (userId === adminId) {
          window.location.reload(true)
        } else {
          dispatch({
            type: ACTIONS.EMPLOYEES.EDIT_EMPLOYEE_PERMISSIONS,
            payload: {
              userId,
              permissions: data
            }
          });
        }
        toast.success(i18n.t("Updated!"));
        setIsOpenDropdown(false);
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const editEmployeeStatus = (userId, statusId, navigate, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/doctors/status`,
      data: {
        userId,
        statusId
      }
    })
      .then(async () => {
        dispatch({
          type: ACTIONS.EMPLOYEES.CLEAR_DATA
        });
        
        setTimeout(() => {
          navigate(paths.employees);
        }, 150);
        
        toast.success(i18n.t("Updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const inviteEmployee = (email, setIsSent, i18n) => {
  return async () => {
    apiPost({
      url: `manager/invite-employee?lang=${i18n.language}`,
      data: {
        email
      }
    })
      .then(() => {
        setIsSent(true);
        toast.success(i18n.t("Invite successfully sent!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const removeEmployee = (userId, handleClose, i18n, navigate) => {
  return async (dispatch) => {
    apiDelete({
      url: `clinics/employees`,
      data: {
        userId
      }
    })
      .then(() => {
        dispatch({
          type: ACTIONS.EMPLOYEES.REMOVE_EMPLOYEE,
          payload: userId
        });
        
        handleClose();
        navigate(paths.employees)
        toast.success(i18n.t("Updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const registerEmployee = (values, captchaToken, i18n) => {
  return async (dispatch) => {
    console.log(8888, values);
    apiPut({
      url: `/users/set-user-info?lang=${i18n.language}`,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        telephone: values.telephone,
        email: values.email,
        birthDate: values.birthDate,
        password: values.newPassword,
        token: captchaToken,
        data: values.userPhoto,
        lanr: values.lanr,
        primarySpecialty: values.primarySpecialty,
        location: ''
      }
    })
      .then(() => {
        dispatch({
          type: ACTIONS.INVITE_EMPLOYEE.SET_EMPLOYEE_COMPONENT,
          payload: InviteEmployeeComponentKeys.ADD_EMPLOYEE_PENDING
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
        dispatch({
          type: ACTIONS.INVITE_EMPLOYEE.SET_EMPLOYEE_COMPONENT,
          payload: InviteEmployeeComponentKeys.ADD_EMPLOYEE_FAILED
        })
      })
  }
}

export const setState = (state, action) => {
  switch (action.type) {
    case inviteEmployeeReducerTypes.UPDATE_USER_PHOTO: {
      const userPhoto = action.payload;
      return {
        ...state,
        userPhoto
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_USER_BIRTH_DATE: {
      const birthDate = action.payload;
      return {
        ...state,
        birthDate
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_NEW_PASSWORD_TYPE: {
      const newPasswordType = action.payload;
      return {
        ...state,
        newPasswordType
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_CONFIRM_PASSWORD_TYPE: {
      const confirmPasswordType = action.payload;
      return {
        ...state,
        confirmPasswordType
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_IS_SHOW_ADDITIONAL_INFO: {
      const isShowAdditionalInfo = action.payload;
      return {
        ...state,
        isShowAdditionalInfo
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_CAPTCHA_TOKEN: {
      const captchaToken = action.payload;
      return {
        ...state,
        captchaToken
      }
    }
    case inviteEmployeeReducerTypes.UPDATE_SPECIALTIES: {
      const specialties = action.payload;
      return {
        ...state,
        specialties
      }
    }
  }
}

export const getNewEmployeesCount = (setIsNewEmployee) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/manager/new-accounts-count`
      });
      if (data.count) {
        setIsNewEmployee(true)
      }
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(checkAuth());
      }
    }
  };
};
