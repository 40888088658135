import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../Pages/Login/Login";
import MyProfile from "../Pages/MyProfile/MyProfile";
import ClinicSettings from "../Pages/ClinicSettings/ClinicSettings";
import PatientInfo from "../Pages/PatientInfo/PatientInfo";
import AllRequests from "../Pages/AllRequests/AllRequests";
import ListOfFeedbacks from "../Pages/ListOfFeedbacks/ListOfFeedbacks";
import Feedback from "../Pages/Feedback/Feedback";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import ResetPasswordSuccess from "../Pages/ResetPassword/ResetPasswordSuccess";
import NewPassword from "../Pages/ResetPassword/NewPassword";
import AllPatients from "../Pages/AllPatients/AllPatients";
import MySettings from "../Pages/MySettings/MySettings";
import ClinicProfile from "../Pages/ClinicProfile/ClinicProfile";
import AllClinics from "../Pages/AllClinics/AllClinics";
import ClinicApprove from "../Pages/AllClinics/ClinicApprove";
import ManageEmployees from "../Pages/ManageEmployees/ManageEmployees";
import ManageEmployeeApprove from "../Pages/ManageEmployees/ManageEmployeeApprove";
import AdvertisingForRegistered from "../Pages/Advertising/AdvertisingForRegistered";
import AddClinic from "../Pages/InviteClinic/AddClinic/AddClinic";
import AllEmployees from "../Pages/AllEmployees/AllEmployees";
import EditEmployeeInfo from "../Pages/EditEmployeeInfo/EditEmployeeInfo";
import AdvertisingForGuest from "../Pages/Advertising/AdvertisingForGuest";
import AdvertisingForClinic from "../Pages/Advertising/AdvertisingForClinic";

export const paths = {
  main: '/',
  login: '/login',
  resetPassword: '/reset/password',
  recoverPassword: '/recover-password',
  managers: '/managers',
  doctors: '/doctors',
  profile: '/profile',
  feedbacks: '/feedbacks',
  submitFeedback: '/submit-feedback',
  patients: '/patients',
  office: '/office',
  clinics: '/clinics',
  employees: '/employees',
  app: '/app',
  addClinic: '/add-clinic',
  inviteEmployee: '/invite-employee',
  inviteClinic: '/invite-clinic',
  allRequests: '/requests',
  clinicAd: '/clinic-ad',
  unregisteredAd: '/unregistered-ad',
  registeredAd: '/registered-ad',
  error: '*'
};

export const GuestRoutes = [
  {
    path: paths.login,
    Component: <Login/>
  },
  {
    path: paths.resetPassword,
    Component: <ResetPassword/>
  },
  {
    path: `${paths.resetPassword}/success`,
    Component: <ResetPasswordSuccess/>
  },
  {
    path: paths.recoverPassword,
    Component: <NewPassword/>
  },
  {
    path: paths.registeredAd,
    Component: <AdvertisingForRegistered/>
  },
  {
    path: paths.unregisteredAd,
    Component: <AdvertisingForGuest/>
  },
  {
    path: paths.clinicAd,
    Component: <AdvertisingForClinic/>
  }
];

export const SuperAdminRoutes = [
  {
    path: paths.addClinic,
    Component: <AddClinic/>
  },
  {
    path: `${paths.employees}`,
    Component: <ManageEmployees/>
  },
  {
    path: `${paths.employees}/:id`,
    Component: <EditEmployeeInfo/>
  },
  {
    path: `${paths.employees}/:id/approve`,
    Component: <ManageEmployeeApprove/>
  },
  {
    path: `${paths.profile}/edit`,
    Component: <MyProfile/>
  },
  {
    path: `${paths.profile}/settings`,
    Component: <MySettings/>
  },
  {
    path: paths.feedbacks,
    Component: <ListOfFeedbacks/>
  },
  {
    path: paths.main,
    Component: <AllPatients/>
  },
  {
    path: `${paths.patients}/:id`,
    Component: <PatientInfo/>
  },
  {
    path: paths.clinics,
    Component: <AllClinics/>
  },
  {
    path: `${paths.clinics}/:uuid/approve`,
    Component: <ClinicApprove/>
  },
  {
    path: `${paths.clinics}/:uuid/`,
    Component: <ClinicApprove/>
  }
];

export const ClinicAdminRoutes = [
  {
    path: paths.main,
    Component: <Navigate to={paths.employees} replace/>
  },
  {
    path: paths.feedbacks,
    Component: <ListOfFeedbacks/>
  },
  {
    path: paths.patients,
    Component: <AllPatients/>
  },
  {
    path: `${paths.patients}/:id`,
    Component: <PatientInfo/>
  },
  {
    path: `${paths.profile}/edit`,
    Component: <MyProfile/>
  },
  {
    path: `${paths.profile}/settings`,
    Component: <MySettings/>
  },
  {
    path: `${paths.office}/settings`,
    Component: <ClinicSettings/>
  },
  {
    path: `${paths.office}/edit`,
    Component: <ClinicProfile/>
  },
  {
    path: `${paths.employees}`,
    Component: <ManageEmployees/>
  },
  {
    path: `${paths.employees}/:id/approve`,
    Component: <ManageEmployeeApprove/>
  },
  {
    path: `${paths.employees}/:id`,
    Component: <EditEmployeeInfo/>
  }
];

export const ManagerRoutes = [
  {
    path: paths.main,
    Component: <Navigate to={paths.allRequests} replace/>
  },
  {
    path: paths.feedbacks,
    Component: <ListOfFeedbacks/>
  },
  {
    path: paths.patients,
    Component: <AllPatients/>
  },
  {
    path: `${paths.patients}/:id`,
    Component: <PatientInfo/>
  },
  {
    path: paths.allRequests,
    Component: <AllRequests/>
  },
  {
    path: `${paths.profile}/edit`,
    Component: <MyProfile/>
  },
  {
    path: `${paths.profile}/settings`,
    Component: <MySettings/>
  },
  {
    path: `${paths.employees}`,
    Component: <AllEmployees/>
  },
  {
    path: `${paths.employees}/:id`,
    Component: <EditEmployeeInfo/>
  }
];

export const DoctorRoutes = [
  {
    path: paths.main,
    Component: <Navigate to={paths.allRequests} replace/>
  },
  {
    path: paths.allRequests,
    Component: <AllRequests/>
  },
  {
    path: `${paths.profile}/edit`,
    Component: <MyProfile/>
  },
  {
    path: `${paths.profile}/settings`,
    Component: <MySettings/>
  },
  {
    path: paths.patients,
    Component: <AllPatients/>
  },
  {
    path: `${paths.patients}/:id`,
    Component: <PatientInfo/>
  },
  {
    path: paths.submitFeedback,
    Component: <Feedback/>
  }
];

export const PatientsRoutes = [
  {
    path: paths.main,
    Component: <AdvertisingForRegistered/>
  },
];

export const AdditionalRoutesForAllPermissions = [
  {
    path: paths.submitFeedback,
    Component: <Feedback/>
  },
  {
    path: paths.allRequests,
    Component: <AllRequests/>
  }
];