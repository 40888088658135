import '../Assets/Styles/QRCodeSect.css';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from "react-i18next";
import { CopyImg } from "../Assets/Images/img";
import { toast } from "react-toastify";

export const QRCodeSect = ({ id }) => {
  const { i18n } = useTranslation();
  
  const downloadQRCode = (event, id) => {
    event.preventDefault();
    let el = document.getElementById(id);
    let canvas = el?.querySelector('canvas');
    let image = canvas?.toDataURL('image/png');
    let anchor = document.createElement('a');
    anchor.href = image;
    anchor.download = 'qr-code.png';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  
  const copyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value);
    toast.success(i18n.t("Successfully copied!"));
  };
  
  return (
    <>
      <div className="qr-sect">
        <div className="qr-heading-sect">
          <p className="qr-title">{i18n.t("URL for Patient")}</p>
          <p className="qr-subtitle">{i18n.t("You can share this link with you patients")}</p>
        </div>
        
        <div className="input-container">
          <input
            type="text"
            name="link" value={`${window.location.origin}/clinics/${id}`}
            maxLength="40" disabled
          />
          <img alt="show_pw"
               src={CopyImg}
               className="input-end-img btn scale-btn"
               onClick={() => copyToClipboard(`${window.location.origin}/clinics/${id}`)}
          />
        </div>
        <div className="qr-heading-sect">
          <p className="qr-title">{i18n.t("QR code")}</p>
          <p className="qr-subtitle">{i18n.t("Download this qr code to share with patient")}</p>
        </div>
        <div className="qr-code-wrap" id="qr-link">
          <QRCodeCanvas value={`${window.location.origin}/clinics/${id}`} size={160} bgColor="#EEFBF9"/>
        </div>
        <div className="qr-btn" onClick={(event => downloadQRCode(event, 'qr-link'))}
        >
          {i18n.t("Download PNG")}
        </div>
      </div>
    </>
  );
};
