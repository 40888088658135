import "../../Assets/Styles/Login.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { SignupSchema } from "../../Utils/ValidationSchemas";
import { login } from "../../Store/actions/userActions";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { isProd } from "../../Utils/constants";
import { CrossedPasswordIco, PasswordIco, StartingLogo } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";
import { paths } from "../../Utils/Routes";

export default function Login() {
  const { i18n } = useTranslation();
  
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const user_id = useSelector(state => state.userReducer.user.id);
  
  const [captchaToken, setCaptchaToken] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  
  const tries = useSelector(state => state.userReducer.fails)
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  
  if (user_id) {
    return navigate(paths.main);
  }
  
  return (
    <main className="auth">
      <div className="starting-logo">
        <img alt="starting_logo"
             src={StartingLogo}
        />
      </div>
      <section className="auth-sect auth-login-sect">
        <h1 className="auth-heading">
          <Trans>Welcome to meplano</Trans>
        </h1>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const { email, password } = values;
            dispatch(login(email, password, captchaToken, tries, navigate, i18n, 'employee'));
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form autoComplete="off">
              <div className="input-container">
                <label>
                  <Trans>Email</Trans>
                </label>
                <Field type="text" name="email" maxLength="40" required
                       onChange={(event) => {
                         const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                         handleChange(event);
                         handleChange({ target: { name: 'email', value: cleanedValue } });
                       }}
                />
                <ValidationError errors={errors.email} touched={touched.email}/>
              </div>
              <div className="input-container">
                <label>
                  <Trans>Password</Trans>
                </label>
                <Field type={passwordType} name="password" maxLength="16" required/>
                <img alt="show_pw"
                     src={passwordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className="input-end-img btn scale-btn" onClick={togglePassword}
                />
                <ValidationError errors={errors.password} touched={touched.password}/>
              </div>
              <div className="input-container auth-items">
                <Link to="/reset/password" className="link">
                  <Trans>Forgot password</Trans>
                </Link>
              </div>
              {tries >= 3 &&
              <>
                <div className="captcha-margin"/>
                <HCaptcha
                  sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                  onVerify={(token) => setCaptchaToken(token)}
                />
              </>
              }
              <div className="button-container">
                <input type="submit"
                       disabled={tries >= 3 && !captchaToken}
                       value={i18n.t("Sign in")}
                       className="submit-form-button scale-btn"
                />
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </main>
  );
}
