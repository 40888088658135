import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeEmployee } from "../Store/actions/employeeActions";
import { useNavigate } from "react-router-dom";


export const RemoveEmployeePopup = ({ show, handleClose }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  return (
    <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
      <div className='popup-container'>
        <div className="popup-approve-heading">{i18n.t("Remove employee")}</div>
        <div className="popup-approve-subtitle">
          {i18n.t("Do you really want to remove")}<br/>
          {show?.firstName} {show?.lastName} {i18n.t("from office?")}<br/>
        </div>
        <div className="popup-btns">
          <div className="popup-approve-btn"
               onClick={() => {
                 handleClose();
               }}
          >
            {i18n.t("Cancel")}
          </div>
          <div className="popup-delete-btn popup-delete-btn-2"
               onClick={() => {
                 dispatch(removeEmployee(show?.id, handleClose, i18n, navigate))
                 handleClose();
               }}
          >
            {i18n.t("Remove")}
          </div>
        </div>
      </div>
    </div>
  )
}