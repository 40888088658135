import "../../Assets/Styles/ForPatients.css";
import Header from "../../Layouts/Header/Header";
import AppointmentStepChain from "../../Components/AppointmentStepChain";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmPendingImg } from "../../Assets/Images/img";
import { useNavigate } from "react-router-dom";
import { paths } from "../../Utils/Routes";

export default function InviteEmployeePending() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <AppointmentStepChain active="confirm" type="employee"/>
          <div className="welcome-whiteContainer m-t-40">
            <div className="list">
              <div className="list-heading">
                <p>{i18n.t("Pending")}</p>
                <div className="list-subtitle">
                  {i18n.t("Your account was created, but not yet activated. You will receive a letter when your account is activated.")}
                </div>
              </div>
              <img alt="logo" className="list-img" width="224"
                   src={ConfirmPendingImg}
              />
              <div className="list-img-heading">
                {i18n.t("You have to wait a while")}
              </div>
              <button className="for-patients-btn btn scale-btn"
                      onClick={(event) => {
                        event.preventDefault();
                        navigate(paths.clinicAd);
                      }}
              >
                OK
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
