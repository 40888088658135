import "../../Assets/Styles/ManageProfile.css";
import "../../Assets/Styles/WorkingHours.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { EditClinicSchema } from "../../Utils/ValidationSchemas";
import { editClinicById, updateClinicImage } from "../../Store/actions/clinicActions";
import { QRCodeSect } from "../../Components/QRCodeSect";
import { EditWorkingHoursPopup } from "../../Components/EditWorkingHoursPopup";
import WorkingHoursString from "../../Components/WorkingHoursString";
import { DaysArray } from "../../Utils/constants";
import { EmptyClinicProfileImgLight, PencilImg } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";


export default function ClinicProfileForm() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const clinic = useSelector(state => state.clinicReducer.clinic)
  
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  
  return (
    <>
      <div className="manage-clinic">
        <div className="manage-img">
          <div className="circle-crop-100">
            <img alt="avatar"
                 src={clinic?.image_url || EmptyClinicProfileImgLight}
            />
          </div>
          {clinic?.image_url &&
          <label className="manage-delete_file"
                 onClick={() => dispatch(updateClinicImage(clinic.id, clinic.uuid, '', i18n))}
          />
          }
          <label htmlFor="files" className="manage-upload_file"/>
          <input name="attachment" type="file"
                 id="files" accept="image/*" hidden
                 onChange={(e) => {
                   const reader = new FileReader();
                   reader.readAsDataURL(e.target.files[0]);
                   reader.onloadend = () => {
                     dispatch(updateClinicImage(clinic.id, clinic.uuid, reader.result, i18n))
                   }
                 }}
          />
        </div>
        <h3>{clinic?.name}</h3>
      </div>
      <div className="manage-form">
        <Formik
          enableReinitialize
          initialValues={{
            name: clinic?.name || "",
            slogan: clinic?.slogan || "",
            location: clinic?.location || "",
            telephone: clinic?.telephone || "",
            email: clinic?.email || "",
            website: clinic?.website || "",
            bsnr: clinic?.bsnr || "",
            description: clinic?.description || "",
            city: clinic?.city ?? '',
            postal_code: clinic?.postal_code ?? ''
          }}
          validationSchema={EditClinicSchema}
          onSubmit={values => {
            dispatch(editClinicById(clinic?.id, clinic.uuid, values, i18n));
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form className="manage-form-col">
              <div className="input-container">
                <label><Trans>Name</Trans></label>
                <Field type="text" name="name" required/>
                <ValidationError errors={errors.name} touched={touched.name}/>
              </div>
              <div className="input-container">
                <label><Trans>Slogan</Trans></label>
                <Field type="text" name="slogan" required/>
                <ValidationError errors={errors.slogan} touched={touched.slogan}/>
              </div>
              <div className="input-container hours-container">
                <label><Trans>Working hours</Trans></label>
                <div className='hours-list'>
                  {DaysArray.map(day =>
                    <WorkingHoursString clinic={clinic} title={day}
                                        day={clinic?.working_hours?.[day.toLowerCase()]}
                    />
                  )}
                </div>
                <img alt='pencil' className='hours-edit-ico' onClick={() => setShow(true)}
                     src={PencilImg}
                />
              </div>
              <div className="input-container">
                <label><Trans>City</Trans></label>
                <Field
                  type="text"
                  name="city"
                  maxLength="30" required
                />
                <ValidationError errors={errors.city} touched={touched.city}/>
              </div>
              <div className="input-container">
                <label><Trans>Address</Trans></label>
                <Field
                  type="text"
                  name="location"
                  maxLength="30" required
                />
                <ValidationError errors={errors.location} touched={touched.location}/>
              </div>
              <div className="input-container">
                <label><Trans>Postal Code</Trans></label>
                <Field
                  type="text"
                  name="postal_code"
                  maxLength="30" required
                />
                <ValidationError errors={errors.postal_code} touched={touched.postal_code}/>
              </div>
              <div className="input-container">
                <label><Trans>Telephone</Trans></label>
                <Field
                  type="tel"
                  name="telephone"
                  maxLength="20" required
                  onChange={(event) => {
                    const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                    handleChange(event);
                    handleChange({ target: { name: 'telephone', value: cleanedValue } });
                  }}
                />
                <ValidationError errors={errors.telephone} touched={touched.telephone}/>
              </div>
              <div className="input-container">
                <label><Trans>Email</Trans></label>
                <Field
                  type="email"
                  name="email"
                  maxLength="40" required
                  onChange={(event) => {
                    const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                    handleChange(event);
                    handleChange({ target: { name: 'email', value: cleanedValue } });
                  }}
                />
                <ValidationError errors={errors.email} touched={touched.email}/>
              </div>
              <div className="input-container">
                <label><Trans>Webpage</Trans></label>
                <Field
                  type="text"
                  name="website"
                  maxLength="30" required
                />
                <ValidationError errors={errors.website} touched={touched.website}/>
              </div>
              <div className="input-container">
                <label><Trans>BSNR Number</Trans></label>
                <Field
                  type="text"
                  name="bsnr"
                  maxLength="40" required
                />
                <ValidationError errors={errors.bsnr} touched={touched.bsnr}/>
              </div>
              <div className="input-container">
                <label><Trans>Description</Trans></label>
                <Field type="text" name="description" required/>
                <ValidationError errors={errors.description} touched={touched.description}/>
              </div>
              <div className="button-container manage-btn">
                <input type="submit"
                       value={i18n.t("Save Changes")}
                       className="submit-form-button scale-btn"
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className="manage-form-col">
          <QRCodeSect id={clinic?.uuid}/>
        </div>
      </div>
      
      <EditWorkingHoursPopup handleClose={handleClose} show={show} clinic={clinic}/>
    </>
  );
}
