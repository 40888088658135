import { Trans } from "react-i18next";
import { LoadMoreIco } from "../Assets/Images/img";

export default function PaginationButton({ handleNext }) {
  return (
    <div className="pagination" onClick={() => handleNext()}>
      <img alt="load_more" src={LoadMoreIco}/>
      <Trans>Load More</Trans>
    </div>
  );
}
