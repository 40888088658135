import { Trans, useTranslation } from "react-i18next";
import { CloseSVG } from "../Assets/Images/svg";
import { useState } from "react";
import { inviteEmployee } from "../Store/actions/employeeActions";
import { useDispatch } from "react-redux";
import { inviteClinic } from "../Store/actions/clinicActions";

const PopupState = ({ isSent, setIsSent, handleClose, type }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  
  switch (isSent) {
    case false:
      return (
        <>
          <div className="popup-clinic-heading">{i18n.t(`Invite ${type}`)}</div>
          <div className="popup-clinic-subtitle">
            {i18n.t(`Please enter the email of the ${type.toLowerCase()} you want to invite`)}
          </div>
          <div className="input-container">
            <label><Trans>Email</Trans></label>
            <input
              type="text" name="email"
              maxLength="40" required value={email}
              onChange={(event => {
                setEmail(event.target.value.replace(" ", "").toLowerCase());
                setButtonDisabled(false);
              })}
            />
          </div>
          <span className='popup-close'
                onClick={() => {
                  setEmail('');
                  handleClose();
                }}
          >
            {CloseSVG}
          </span>
          <div className="popup-clinic-btn"
               onClick={() => {
                 if (!isButtonDisabled) {
                   if (type === "Employee") {
                     dispatch(inviteEmployee(email, setIsSent, i18n))
                   } else {
                     dispatch(inviteClinic(email, setIsSent, i18n))
                   }
                 }
                 setButtonDisabled(true);
               }}
          >
            {i18n.t("Send Invitation")}
          </div>
        </>
      );
    case true:
      return (
        <>
          <div className="popup-clinic-heading">{i18n.t("Success!")}</div>
          <div className="popup-clinic-subtitle">
            {i18n.t("Invitation to")} {email} {i18n.t("has been sent")}
          </div>
          <div className="popup-clinic-btn"
               onClick={() => {
                 setIsSent(false);
                 setEmail('');
                 handleClose();
               }}
          >
            Ok
          </div>
        </>
      );
  }
}

export const InvitePopup = ({ show, handleClose, isSent, setIsSent, type }) => {
  return (
    <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
      <div className='popup-container invite-popup'>
        <div className='popup-clinic'>
          <PopupState handleClose={handleClose} isSent={isSent} setIsSent={setIsSent} type={type}/>
        </div>
      </div>
    </div>
  )
}