import React from "react";
import AdvertisingForClinic from "../Pages/Advertising/AdvertisingForClinic";
import { InviteClinicWelcomePage } from "../Pages/InviteClinic/InviteClinicWelcomePage/InviteClinicWelcomePage";
import AddClinic from "../Pages/InviteClinic/AddClinic/AddClinic";
import { AddAdmin } from "../Pages/InviteClinic/AddAdmin/AddAdmin";
import ClinicConfirm from "../Pages/InviteClinic/ClinicConfirm/ClinicConfirm";
import InviteEmployeeInvalidCode from "../Pages/InviteEmployee/InviteEmployeeInvalidCode";

export const InviteClinicComponentKeys = {
  WELCOME_CLINIC: 'WELCOME_CLINIC',
  ADD_CLINIC: 'ADD_CLINIC',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  ADD_CLINIC_VERIFY: 'ADD_CLINIC_VERIFY',
  CONFIRM_APPROVED: "CONFIRM_APPROVED",
  CONFIRM_REJECTED: "CONFIRM_REJECTED",
  ADD_CLINIC_INVALID_CODE: 'ADD_CLINIC_INVALID_CODE',
  ADS: "ADS"
};

export const GetInviteClinicComponentByKey = (key) => {
  switch (key) {
    case InviteClinicComponentKeys.WELCOME_CLINIC:
      return <InviteClinicWelcomePage/>;
    case InviteClinicComponentKeys.ADD_CLINIC:
      return <AddClinic/>;
    case InviteClinicComponentKeys.ADD_EMPLOYEE:
      return <AddAdmin/>;
    case InviteClinicComponentKeys.CONFIRM_APPROVED:
      return <ClinicConfirm confirmStatus="pending"/>;
    case InviteClinicComponentKeys.CONFIRM_REJECTED:
      return <ClinicConfirm confirmStatus="rejected"/>;
    case InviteClinicComponentKeys.ADD_CLINIC_INVALID_CODE:
      return <InviteEmployeeInvalidCode/>;
    case InviteClinicComponentKeys.ADS:
      return <AdvertisingForClinic/>;
    
  }
};