import "../../../Assets/Styles/ForPatients.css";
import { SignupSchema } from "../../../Utils/ValidationSchemas";
import { Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { ACTIONS, isProd } from "../../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsListPatientDetails, ConfirmationComponent } from "../../../Utils/ConstantsForPatient";
import { login } from "../../../Store/actions/userActions";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CrossedPasswordIco, PasswordIco } from "../../../Assets/Images/img";
import ValidationError from "../../../Components/ValidationError";
import { ARROWS } from "../../../Assets/Images/svg";


export default function PatientsLogin() {
  console.log("step 6");
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  
  const formRef = useRef();
  
  const [passwordType, setPasswordType] = useState("password");
  const [captchaToken, setCaptchaToken] = useState('');
  
  const tries = useSelector(state => state.userReducer.fails);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: BreadcrumbsListPatientDetails(data?.appointment)
    });
  }, []);
  
  useEffect(() => {
    if (data?.isLogin) {
      ConfirmationComponent(data?.appointment, dispatch);
    }
  }, [data?.isLogin])
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  
  return (
    <>
      <div className='list'>
        <div className='list-heading'>{i18n.t("Patient Details")}</div>
        <Formik
          innerRef={formRef}
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const { email, password } = values;
            
            dispatch(login(email, password, captchaToken, tries, null, i18n, 'patient'));
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form autoComplete="off">
              <div className="input-container">
                <label>
                  <Trans>Email</Trans>
                </label>
                <Field type="text" name="email" maxLength="40" required
                       onChange={(event) => {
                         const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                         handleChange(event);
                         handleChange({ target: { name: 'email', value: cleanedValue } });
                       }}
                />
                <ValidationError errors={errors.email} touched={touched.email}/>
              </div>
              <div className="input-container">
                <label>
                  <Trans>Password</Trans>
                </label>
                <Field type={passwordType} name="password" maxLength="16" required/>
                <img alt="show_pw"
                     src={passwordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className="input-end-img btn scale-btn" onClick={togglePassword}
                />
                <ValidationError errors={errors.password} touched={touched.password}/>
              </div>
              {tries >= 3 &&
              <>
                <div className="captcha-margin"/>
                <HCaptcha
                  sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                  onVerify={(token) => setCaptchaToken(token)}
                />
              </>
              }
              <div className='flexbox'>
                <div className='arrow-container'>
                  <div className='image-container' onClick={() => dispatch({
                    type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                    payload: {
                      isRegistered: null
                    }
                  })}>
                    {ARROWS.LEFT}
                    <span className="tooltip-text left">Back</span>
                  </div>
                </div>
                
                <button className='for-patients-btn btn scale-btn' type="submit">{i18n.t("Confirm")}</button>
              </div>
            
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
