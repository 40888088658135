import "../Assets/Styles/WorkingHours.css";
import "../Assets/Styles/Popup.css";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { getAllEmployeesList } from "../Store/actions/employeeActions";
import { Field, Form, Formik } from "formik";
import { DocToDocSchema } from "../Utils/ValidationSchemas";
import ValidationError from "./ValidationError";
import { sendSpecialistTransfer } from "../Store/actions/appointmentActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { capitalizeFirstLetter, theme } from "../Utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

export const Doctor2DoctorPopup = ({ show, handleClose }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { patientGeneralInfo } = useSelector(state => state.patientReducer);
  const { filteredItems } = useSelector(state => state.employeesReducer);
  const { user: { clinic_id_clinics_clinicEmployees } } = useSelector(state => state.userReducer);
  const specialties = useSelector(state => state.specialtyReducer.items);
  
  const [activeButton, setActiveButton] = useState();
  const [speciality, setSpeciality] = useState();
  const [doctorId, setDoctorId] = useState(-1);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  
  const doctorsList = filteredItems.filter((employee) => {
    const hasSpecialty = employee?.doctor?.specialty_id_specialties.some(obj => Number(obj.id) === Number(speciality))
    
    return !!employee.firstName && hasSpecialty
  });
  
  useEffect(() => {
    if (!filteredItems.length) {
      dispatch(getAllEmployeesList(i18n));
    }
  }, [])
  
  return (
    <>
      <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
        <div className='popup-container' id='doctor2doctor-popup'>
          <div className='list-heading'>
            <p>{i18n.t("Request an Appointment")}</p>
            <div className='list-subtitle'>
              {i18n.t("Please fill the form to make specialist transfer request")}
            </div>
          </div>
          <Formik
            initialValues={{
              specialty: '',
              doctor: '',
              reason: ''
            }}
            validationSchema={DocToDocSchema}
            onSubmit={(values, { resetForm }) => {
              dispatch(sendSpecialistTransfer(values, i18n, clinic_id_clinics_clinicEmployees[0]?.ClinicEmployees.clinic_id, activeButton, patientGeneralInfo.id));
              
              setActiveButton('')
              setSpeciality('')
              setDoctorId(-1)
              handleClose()
              resetForm()
            }}
            enableReinitialize={true}
          >
            {({ errors, touched, handleChange, resetForm }) => (
              <Form className="transfer-form">
                <div className="input-container">
                  <label><Trans>Specialist</Trans></label>
                  <div className="input-container select-input for-patient-select">
                    <Field as="select" name="specialty"
                           onChange={(event) => {
                             handleChange(event)
                             setSpeciality(Number(event.target.value))
                           }}
                    >
                      <option value=""/>
                      {specialties.map(item =>
                        <option value={item?.id} key={item?.description}>
                          {capitalizeFirstLetter(i18n.t(item?.description))}
                        </option>
                      )}
                    </Field>
                  </div>
                  <ValidationError errors={errors.specialty} touched={touched.specialty}/>
                </div>
                <div className="input-container">
                  <label><Trans>Reason of Transfer</Trans></label>
                  <Field
                    type="text"
                    name="reason"
                    maxLength="150" required
                  />
                  <ValidationError errors={errors.reason} touched={touched.reason}/>
                </div>
                <div className="input-container">
                  <label><Trans>Choose Doctor</Trans></label>
                  <div className="input-container select-input for-patient-select">
                    <Field as="select" name="doctor" value={doctorId ?? -1}
                           onChange={(event) => {
                             handleChange(event)
                             setDoctorId(event.target.value)
                           }}
                    >
                      <option value={-1}/>
                      {doctorsList.map((item) =>
                        <option value={item?.doctor.user_id} key={item?.doctor?.publicName}>
                          {capitalizeFirstLetter(i18n.t(item?.doctor?.publicName))}
                        </option>
                      )}
                    </Field>
                  </div>
                </div>
                {Number(doctorId) === -1 &&
                <Fragment>
                  <div className="input-container">
                    <label>
                      <Trans>Choose date</Trans>:
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          className='date-picker light-date-picker doc2doc-picker'
                          views={['day']}
                          openTo={"year"}
                          inputFormat="DD.MM.YYYY"
                          disablePast
                          minDate={new Date(Date.now())}
                          value={date}
                          onChange={(value) => {
                            setDate(new Date(value));
                          }}
                          renderInput={(params) =>
                            <TextField {...params} helperText={null} sx={{ svg: { color: '#47C0C1' } }}/>
                          }
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                    <p className="dropdown-error">
                      {date.getTime() < new Date(Date.now()).getTime() && i18n.t("Selected date has already passed")}
                    </p>
                  </div>
                  <div className="input-container">
                    <label>{i18n.t('Choose Time')}</label>
                    <ThemeProvider theme={theme}>
                      <TextField
                        id="time"
                        type="time"
                        defaultValue={time}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        onChange={(event) => {
                          setTime(event.target.value);
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </Fragment>
                }
                <div className='doc2doc-buttons-wrap'>
                  <label><Trans>Urgency</Trans></label>
                  <div className='doc2doc-buttons'>
                    <button className={`${activeButton === 1 ? `active-urgent-btn1` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(1)
                            }}
                    >
                      <Trans>ASAP</Trans> 10 <Trans>Days</Trans>
                    </button>
                    <button className={`${activeButton === 2 ? `active-urgent-btn2` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(2)
                            }}
                    >
                      <Trans>ASAP</Trans> 20 <Trans>Days</Trans>
                    </button>
                    <button className={`${activeButton === 3 ? `active-urgent-btn3` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(3)
                            }}
                    >
                      <Trans>ASAP</Trans> 30 <Trans>Days</Trans>
                    </button>
                  </div>
                </div>
                <button type="submit" className='doc2doc-btn1'>
                  <Trans>Send Request</Trans>
                </button>
                <div className='doc2doc-btn2'
                     onClick={() => {
                       setActiveButton('')
                       setSpeciality('')
                       setDoctorId(-1)
                       handleClose()
                       resetForm()
                     }}
                >
                  <Trans>Cancel</Trans>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
