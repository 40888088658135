import "../../Assets/Styles/ManagePatient.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import Loading from "../../Layouts/Loading/Loading";
import { ACTIONS, StatusArray } from "../../Utils/constants";
import { getEmployeesList } from "../../Store/actions/employeeActions";
import debounce from "lodash.debounce";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import { Link } from "react-router-dom";
import { paths } from "../../Utils/Routes";
import PaginationButton from "../../Components/PaginationButton";

export default function AllEmployees() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { filteredItems, page, loadNext, isReady } = useSelector(state => state.employeesReducer);
  const registeredEmployees = filteredItems.filter((employee) => !!employee.firstName);
  
  const [query, setQuery] = useState("");
  
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (query) {
      dispatch({
        type: ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY
      });
      setTimeout(() => {
        dispatch({
          type: ACTIONS.EMPLOYEES.SET_FILTERED_EMPLOYEES_ARRAY,
          payload: filteredItems.filter(item =>
            (item?.firstName + item?.lastName).replaceAll(" ", "").toLowerCase().includes(query)
          )
        });
      }, 150);
    }
  }, [query]);
  
  useEffect(() => {
    if (!isReady && !query) {
      dispatch(getEmployeesList(query, 0, i18n));
      setTimeout(() => {
        dispatch(getEmployeesList(query, 1, i18n));
      }, 150);
    }
  }, [isReady, query]);
  
  const handleNextEmployees = useCallback(() => {
    dispatch(getEmployeesList(query, page, i18n));
  }, [query, page]);
  
  const changeHandler = (event) => {
    if (!event.target.value) {
      dispatch({
        type: ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY
      });
    } else {
      dispatch({
        type: ACTIONS.EMPLOYEES.SET_PAGE, payload: 0
      });
    }
    setQuery(event.target.value.trim().toLowerCase());
  };
  
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading patients-heading">
            <div className="search">
              <div className="search-input-wrap">
                <input className="search-input"
                       ref={inputRef}
                       placeholder={i18n.t("Search")}
                       maxLength="30"
                       onChange={debouncedChangeHandler}
                />
                {query &&
                <div className="search-clear"
                     onClick={() => {
                       setQuery("");
                       inputRef.current.value = "";
                       dispatch({
                         type: ACTIONS.EMPLOYEES.CLEAR_FILTERED_ARRAY
                       });
                     }}
                >
                  ✕
                </div>
                }
              </div>
            </div>
          </div>
          <div className="manage-patient-table">
            <div
              className="table-heading"
              style={!filteredItems.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
            >
              <div className="table-col-user">
                Employee
              </div>
              <div className="table-col">
                <Trans>Specialization</Trans>
              </div>
              <div className="table-col">
                <Trans>Phone</Trans>
              </div>
              <div className="table-col table-col-user-clinic"><Trans>Email</Trans></div>
            </div>
            {isReady ?
              !registeredEmployees.length ?
                <SearchNoFound/>
                :
                registeredEmployees.map((employee, i) =>
                  <div className='card' key={`doctorCard ${i}`}>
                    <div className='card-header'>
                      <Link to={
                        employee?.status_id === StatusArray.PENDING ?
                          `${paths.employees}/${employee?.uuid}/approve`
                          :
                        `${paths.employees}/${employee?.uuid}`
                      } className="table-link"
                      >
                        <div className="table-col-user">
                          <img alt="img"
                               src={employee?.photo_url || EmptyUserProfileImgForTable(i)}
                          />
                          <div className="table-col ml-10">
                            <div>{`${employee?.firstName} ${employee?.lastName}`}</div>
                          </div>
                        </div>
                      </Link>
                      <div className="table-col">
                        {employee?.doctor?.specialty_id_specialties.map((item) =>
                          <div
                            key={`${item?.id} ${item?.description}`}>
                            {i18n.t(`${item?.description}`)}
                          </div>
                        )}
                      </div>
                      <div className="table-col">{employee?.telephone}</div>
                      <div className="table-col table-col-user-clinic">{employee?.email}</div>
                    </div>
                  </div>
                )
              :
              <Loading/>
            }
          </div>
          
          {(registeredEmployees.length && loadNext) &&
          <PaginationButton handleNext={handleNextEmployees}/>
          }
        </section>
      </main>
    </>
  );
}
