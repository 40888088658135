import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger"

import rootReducer from "../Store/reducers/index";
import { isProd } from "../Utils/constants";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (isProd) {
      return getDefaultMiddleware().concat(thunk);
    } else {
      return getDefaultMiddleware().concat(thunk, logger);
    }
  },
});

export default store;
