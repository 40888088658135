import { toast } from "react-toastify";
import { apiGet, apiPost, } from "../../http/headerPlaceholder.instance";
import { ACTIONS, calculateOffset, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";
import { checkAuth } from "./userActions";
import { ConfirmationComponent } from "../../Utils/ConstantsForPatient";

export const getPatientsList = (value = '', birthDate = '', phone = '', page = 0, i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/manager/clinic-patients?name=${value}&birthDate=${birthDate || ""}&phone=${phone}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}`
      });
      if (value) {
        dispatch({
          type: ACTIONS.PATIENTS.SET_FILTERED_PATIENTS_ARRAY,
          payload: data
        });
      } else {
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENTS,
          payload: data
        });
      }
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getPatientGeneralInfo = (id, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/manager/clinic-patients?uuid=${id}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENT_GENERAL_INFO,
          payload: data[0]
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getPatientAdditionalInfo = (id, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/users/${id}/additional-info`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENT_ADDITIONAL_INFO,
          payload: data
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const patientSignUp = (values, token, i18n, appointmentType) => {
  return async (dispatch) => {
    apiPost({
      url: "/auth/sign-up",
      data: {
        ...values,
        token
      }
    }).then(({data}) => {
      localStorage.setItem('accessToken', data.accessToken);
      dispatch({
        type: ACTIONS.PATIENTS.SET_PATIENT_ADDITIONAL_INFO,
        payload: {
          verifyToken: data.token,
          userId: data.userId
        }
      });
      dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
        payload: {
          user: {
            ...values
          },
          user_id: data.userId,
          userEmail: values.email,
          isVerified: true
        }
      })
      ConfirmationComponent(appointmentType, dispatch)
    }).catch(() => {
      dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
        payload: {
          isRegistered: "verify-failed"
        }
      });
      toast.error(i18n.t("Error"));
    });
  };
};

export const getDoc2DocTransfers = (id, i18n, doc2DocTransfers) => {
  return async (dispatch) => {
    try {
      if (!doc2DocTransfers.length) {
        const { data } = await apiGet({
          url: `/transfers/all/redirected`
        })
        dispatch({
          type: ACTIONS.PATIENTS.SET_DOC2DOC_TRANSFER,
          payload: data
        });
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENT_DOC2DOC_TRANSFER,
          payload: data.filter((el) => Number(el.user_id) === Number(id))
        });
      }
      else {
        dispatch({
          type: ACTIONS.PATIENTS.SET_PATIENT_DOC2DOC_TRANSFER,
          payload: doc2DocTransfers.filter((el) => Number(el.sender_id) === Number(id))
        });
      }
    }
    catch (e) {
      if (e.response.status === 401) {
            dispatch(checkAuth());
          }
          toast.error(i18n.t("Something went wrong"));
      }
  };
};
