import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { ACTIONS } from "../../../Utils/constants";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import { ARROWS } from "../../../Assets/Images/svg";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";
import { useTranslation } from "react-i18next";
import WorkingHoursAccordion from "../../../Components/WorkingHoursAccordion";


export default function ChooseAppointment() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  console.log("step 2");
  const { breadcrumbs, data } = useSelector(state => state.bookAppointmentReducer);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        }
      ]
    });
    
    if (data?.appointmentDoctor) {
      dispatch({
        type: ACTIONS.BOOK_APPOINTMENT.CLEAR_DATA,
        payload: {
          appointmentDoctor: data?.appointmentDoctor
        }
      });
    }
  }, []);
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <div className='breadcrumbs-item' key={i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <WorkingHoursAccordion/>
            <div className='list'>
              <div className='list-heading non-copy'>{i18n.t("Choose Type of Appointment")}</div>
              <div className="list-link"
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                       payload: {
                         appointment: 'appointment'
                       }
                     });
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.APPOINTMENT_TYPE
                     });
                   }}
              >
                <div className="list-link-item">
                  <p className="non-copy">{i18n.t("Medical Appointment")}</p>
                  {ARROWS.RIGHT}
                </div>
              </div>
              <div className="list-link"
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                       payload: {
                         appointment: 'drug-order'
                       }
                     });
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.CHOOSE_DRUGS
                     });
                   }}
              >
                <div className="list-link-item">
                  <p className="non-copy">{i18n.t("Drug Prescription")}</p>
                  {ARROWS.RIGHT}
                </div>
              </div>
              <div className="list-link"
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                       payload: {
                         appointment: 'specialist-transfer'
                       }
                     });
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.CHOOSE_SPECIALIST
                     });
                   }}
              >
                <div className="list-link-item">
                  <p className="non-copy">{i18n.t("Specialist Transfer")}</p>
                  {ARROWS.RIGHT}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
