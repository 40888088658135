import React from "react";
import Header from "../../../Layouts/Header/Header";
import { useTranslation } from "react-i18next";
import { ACTIONS } from "../../../Utils/constants";
import { useDispatch } from "react-redux";
import { InviteClinicComponentKeys } from "../../../Utils/ConstantsInviteClinic";

export const InviteClinicWelcomePage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  return (
    <div>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className="welcome-header">{i18n.t("Welcome to")} <span className="welcome-blueHeader">meplano</span>.
          </div>
          <div className="welcome-whiteContainer">
            <div className="welcome-text">
              <p>{i18n.t("You can register here to get started.")}</p>
              <p>{i18n.t("Your request will be processed by our team and activated")}</p>
              <p>{i18n.t("accordingly.")}</p>
              <p>{i18n.t("Please ensure that your information is accurate!")}</p>
            </div>
            <button type="button" className="welcome-button"
                    onClick={(event => {
                      event.preventDefault();
                      dispatch({
                        type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
                        payload: InviteClinicComponentKeys.ADD_CLINIC
                      });
                    })}
            >
              {i18n.t("Continue")}
            </button>
          </div>
        </section>
      </main>
    </div>
  )
};
