import { ACTIONS } from "../../Utils/constants";

const initialState = {
  doctorToEdit: null,
  doctorAppointmentTypesList: [],
  doctorsGrouped: [],
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DOCTOR.SET_DOCTORS_GROUPED: {
      return {
        ...state,
        doctorsGrouped: action.payload
      };
    }
    case ACTIONS.DOCTOR.SET_DOCTOR: {
      return {
        ...state,
        doctorToEdit: action.payload
      };
    }
    case ACTIONS.DOCTOR.SET_DOCTOR_APPOINTMENT_TYPES_LIST: {
      return {
        ...state,
        doctorAppointmentTypesList: action.payload,
      }
    }
    case ACTIONS.DOCTOR.ADD_TO_DOCTOR_APPOINTMENT_TYPES_LIST: {
      return {
        ...state,
        doctorAppointmentTypesList: state.doctorAppointmentTypesList.concat(action.payload),
      }
    }
    case ACTIONS.DOCTOR.REMOVE_FROM_DOCTOR_APPOINTMENT_TYPES_LIST: {
      return {
        ...state,
        doctorAppointmentTypesList: state.doctorAppointmentTypesList.filter((el) => el.id !== action.payload.id),
      }
    }
    
    default:
      return state;
  }
};

export default doctorReducer;