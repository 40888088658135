import "../Assets/Styles/ForPatients.css";
import {
  AppointmentTypeStepSVG,
  CalendarSVG,
  ClinicLogoSVG,
  ConfirmSVG,
  DrugSVG,
  PatientDetailsSVG,
  RequestSVG,
  SpecialistSVG
} from "../Assets/Images/svg";
import { useTranslation } from "react-i18next";
import { AppointmentStepChainArrow } from "../Assets/Images/img";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../Utils/constants";
import { InviteClinicComponentKeys } from "../Utils/ConstantsInviteClinic";
import { InviteEmployeeComponentKeys } from "../Utils/ConctantsInviteEmployee";
import { ForPatientComponentKeys } from "../Utils/ConstantsForPatient";


export default function AppointmentStepChain({ active, type }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: { clinicInfo }, component } = useSelector(state => state.inviteClinicReducer);
  const appointmentInfo = useSelector(state => state.bookAppointmentReducer.data);
  
  switch (type) {
    case 'appointment':
      return (
        <>
          <div className='for-patients-nav'>
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 2 && active !== 'confirm') && `pointer`}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 2 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.APPOINTMENT_TYPE
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'type' ? 'item-active' : ''}`}>
                {AppointmentTypeStepSVG}
              </div>
              <p>{i18n.t("Choose Appointment Type")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 3 && active !== 'confirm') && `pointer`}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 3 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.DATE
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'date' ? 'item-active' : ''}`}>
                {CalendarSVG}
              </div>
              <p>{i18n.t("Choose Date")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div
              className={`for-patients-nav-item
              ${(Object.keys(appointmentInfo).length >= 4 && active !== 'confirm') && `pointer`}`}
              onClick={() => {
                if (active !== 'confirm') {
                  Object.keys(appointmentInfo).length >= 4 && dispatch({
                    type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                    payload: ForPatientComponentKeys.PATIENT_DETAILS
                  });
                }
              }}
            >
              <div className={`for-patients-svg ${active === 'details' ? 'item-active' : ''}`}>
                {PatientDetailsSVG}
              </div>
              <p>{i18n.t("Patient Details")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'confirm' ? 'item-active' : ''}`}>
                {ConfirmSVG}
              </div>
              <p>{i18n.t("Confirm")}</p>
            </div>
          </div>
        </>
      );
    case 'drug-order':
      return (
        <>
          <div className='for-patients-nav'>
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 2 && active !== 'confirm') && `pointer`}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 2 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.CHOOSE_DRUGS
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'drugs' ? 'item-active' : ''}`}>
                {DrugSVG}
              </div>
              <p>{i18n.t("Add Item")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 3 && active !== 'confirm') && `pointer`}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 3 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.PATIENT_DETAILS
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'details' ? 'item-active' : ''}`}>
                {PatientDetailsSVG}
              </div>
              <p>{i18n.t("Patient Details")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'confirm' ? 'item-active' : ''}`}>
                {ConfirmSVG}
              </div>
              <p>{i18n.t("Confirm")}</p>
            </div>
          </div>
        </>
      );
    case 'doctor-transfer':
      return (
        <>
          <div className='for-patients-nav'>
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 2 && active !== 'confirm') && `pointer`}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 2 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.CHOOSE_SPECIALIST
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'select-doctor' ? 'item-active' : ''}`}>
                {SpecialistSVG}
              </div>
              <p>{i18n.t("Select Specialist")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className={`for-patients-nav-item
            ${(Object.keys(appointmentInfo).length >= 3 && active !== 'confirm') ? `pointer` : ''}`}
                 onClick={() => {
                   if (active !== 'confirm') {
                     Object.keys(appointmentInfo).length >= 3 && dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: ForPatientComponentKeys.PATIENT_DETAILS
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'details' ? 'item-active' : ''}`}>
                {PatientDetailsSVG}
              </div>
              <p>{i18n.t("Patient Details")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'request' ? 'item-active' : ''}`}>
                {RequestSVG}
              </div>
              <p>{i18n.t("Send Request")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'confirm' ? 'item-active' : ''}`}>
                {ConfirmSVG}
              </div>
              <p>{i18n.t("Confirm")}</p>
            </div>
          </div>
        </>
      );
    case 'add-clinic' :
      return (
        <>
          <div className='for-patients-nav'>
            <div className='for-patients-nav-item pointer'
                 onClick={() => {
                   if (!(component === InviteClinicComponentKeys.CONFIRM_APPROVED || component === InviteClinicComponentKeys.CONFIRM_REJECTED || component === InviteClinicComponentKeys.ADD_CLINIC_INVALID_CODE || component === InviteClinicComponentKeys.ADS)) {
                     dispatch({
                       type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
                       payload: InviteClinicComponentKeys.ADD_CLINIC
                     });
                   }
                 }}
            >
              <div className={`for-patients-svg ${active === 'clinic' ? 'item-active' : ''}`}>
                {ClinicLogoSVG}
              </div>
              <p>{i18n.t("Info about the clinic")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}/>
            <div className={`for-patients-nav-item  ${Object.keys(clinicInfo).length >= 1 && `pointer`}`}
                 onClick={() => {
                   if (!!clinicInfo?.name && !(component === InviteClinicComponentKeys.CONFIRM_APPROVED || component === InviteClinicComponentKeys.CONFIRM_REJECTED || component === InviteClinicComponentKeys.ADD_CLINIC_INVALID_CODE || component === InviteClinicComponentKeys.ADS)) {
                     dispatch({
                       type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
                       payload: InviteClinicComponentKeys.ADD_EMPLOYEE
                     });
                   }
              
                 }}>
              <div className={`for-patients-svg ${active === 'details' ? 'item-active' : ''}`}>
                {PatientDetailsSVG}
              </div>
              <p>{i18n.t("Info about the clinic administrator")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'confirm' ? 'item-active' : ''}`}>
                {ConfirmSVG}
              </div>
              <p>{i18n.t("Confirmation")}</p>
            </div>
          </div>
        </>
      );
    case 'employee' :
      return (
        <>
          <div className='for-patients-nav'>
            <div className='for-patients-nav-item pointer'
                 onClick={() => {
                   dispatch({
                     type: ACTIONS.INVITE_EMPLOYEE.SET_EMPLOYEE_COMPONENT,
                     payload: InviteEmployeeComponentKeys.ADD_EMPLOYEE
                   });
                 }}>
              <div className={`for-patients-svg ${active === 'details' ? 'item-active' : ''}`}>
                {PatientDetailsSVG}
              </div>
              <p>{i18n.t("Info about employee")}</p>
            </div>
            <img alt='arrow'
                 src={AppointmentStepChainArrow}
            />
            <div className='for-patients-nav-item'>
              <div className={`for-patients-svg ${active === 'confirm' ? 'item-active' : ''}`}>
                {ConfirmSVG}
              </div>
              <p>{i18n.t("Confirmation")}</p>
            </div>
          </div>
        </>
      );
  }
}
