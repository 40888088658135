import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/Feedback.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import FeedbackSuccess from "./FeedbackSuccess";
import { postFeedback } from "../../Store/actions/feedbackActions";
import { FeedbackImg } from "../../Assets/Images/img";

export default function Feedback() {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const { id } = useSelector(state => state.userReducer.user)
  
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  
  useEffect(() => {
    if (message) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [message]);
  
  return (
    <>
      <Header/>
      <main>
        {
          success ?
            <FeedbackSuccess/>
            :
            <section className="sect">
              <div className="manage-patient-heading"/>
              <div className="feedback">
                <div className="feedback-col">
                  <h1 className="feedback-heading">
                    <Trans>Feel free to give us</Trans><br/><Trans>your feedback</Trans>
                  </h1>
                  <img alt="icon" src={FeedbackImg}/>
                </div>
                <div className="feedback-form">
                  <div className="star-rating">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={index <= (hover || rating) ? "star-btn on" : "star-btn off"}
                          onClick={() => {
                            setRating(index)
                            
                          }}
                          onMouseEnter={() => setHover(index)}
                          onMouseLeave={() => setHover(rating)}
                        >
                          <span className="star">&#9733;</span>
                        </button>
                      );
                    })}
                  </div>
                  <textarea
                    className="feedback-textarea"
                    placeholder={i18n.t("Please write your feedback")}
                    required
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <button
                    className="submit-form-button scale-btn"
                    onClick={() => {
                      dispatch(postFeedback(id, message, rating, i18n))
                      setSuccess(true);
                    }}
                    disabled={disabled}
                  >
                    <Trans>Send Feedback</Trans>
                  </button>
                </div>
              </div>
            </section>
        }
      </main>
    </>
  )
}
