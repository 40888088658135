import * as Yup from 'yup';
import { nameRegex, telephoneRegex } from "./Regex";

export const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('invalid_email')
    .required('please_enter_your_email'),
  password: Yup.string()
    .required('please_enter_your_password')
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('invalid_email')
    .required('please_enter_your_email'),
  
});

export const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,16}$'), "use_valid_pass")
    .test(
      'len',
      'pass_length',
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 8 && val.length <= 16);
      }
    )
    .required('please_enter_your_password'),
  confirmPassword: Yup.string()
    .required('please_confirm_your_password')
    .oneOf([Yup.ref('newPassword'), null], 'passwords_must_match'),
});

export const DoctorsSignInSchema = Yup.object().shape({
    first_name: Yup.string()
      .test(
        "len",
        "name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'name_invalid')
      .required('please_enter_your_last_name'),
    last_name: Yup.string()
      .test(
        "len",
        "last_name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'last_name_invalid')
      .required('please_enter_your_last_name'),
    birthDate: Yup.string()
      .required('please_enter_your_birth_date'),
    primarySpecialty: Yup.string()
      .required(),
    secondarySpecialty: Yup.string()
      .when("secondarySpecialty", (val) => {
        if (val?.length > 0) {
          return Yup.string().notOneOf([Yup.ref('primarySpecialty'), null], 'the_two_specialties_should_not_be_equal');
        } else {
          return Yup.string().notRequired();
        }
      }),
    clinic: Yup.string()
      .required('please_enter_your_clinic'),
    location: Yup.string()
      .required('please_enter_your_postal_code'),
    bsnr: Yup.string()
      .required('please_enter_bsnr'),
    telephone: Yup.string()
      .matches(telephoneRegex, 'phone_number_invalid')
      .test(
        "len",
        "phone_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 9 && val.length <= 20);
        }
      )
      .required('please_enter_your_phone_number'),
    email: Yup.string()
      .email('invalid_email')
      .required('please_enter_your_email')
  },
  ["secondarySpecialty", "secondarySpecialty"]
);

export const EmployeeSignInSchema = Yup.object().shape({
    firstName: Yup.string()
      .test(
        "len",
        "name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'name_invalid')
      .required('please_enter_your_last_name'),
    lastName: Yup.string()
      .test(
        "len",
        "last_name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'last_name_invalid')
      .required('please_enter_your_last_name'),
    telephone: Yup.string()
      .matches(telephoneRegex, 'phone_number_invalid')
      .test(
        "len",
        "phone_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 9 && val.length <= 20);
        }
      ),
    email: Yup.string()
      .email('invalid_email')
      .required('please_enter_your_email'),
    lanr: Yup.string()
      .required('please_enter_lanr'),
    primarySpecialty: Yup.string()
      .required('please_enter_your_specialty'),
    newPassword: Yup.string()
      .matches(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,16}$'), "use_valid_pass")
      .test(
        'len',
        'pass_length',
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 8 && val.length <= 16);
        }
      )
      .required('please_enter_your_password'),
    confirmPassword: Yup.string()
      .required('please_confirm_your_password')
      .oneOf([Yup.ref('newPassword'), null], 'passwords_must_match'),
  },
);

export const EditUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .test(
      "len",
      "name_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 1 && val.length <= 25);
      }
    )
    .matches(nameRegex, 'name_invalid')
    .required('please_enter_your_first_name'),
  last_name: Yup.string()
    .test(
      "len",
      "last_name_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 1 && val.length <= 25);
      }
    )
    .matches(nameRegex, 'last_name_invalid')
    .required('please_enter_your_last_name'),
  location: Yup.string()
    .required('please_enter_your_postal_code'),
  telephone: Yup.string()
    .matches(telephoneRegex, 'phone_number_invalid')
    .test(
      "len",
      "phone_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 9 && val.length <= 20);
      }
    )
    .required('please_enter_your_phone_number'),
  email: Yup.string()
    .email('invalid_email')
    .required('please_enter_your_email'),
});

export const EditDoctorSchema = Yup.object().shape({
    first_name: Yup.string()
      .test(
        "len",
        "name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'name_invalid')
      .required('please_enter_your_first_name'),
    last_name: Yup.string()
      .test(
        "len",
        "last_name_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 1 && val.length <= 25);
        }
      )
      .matches(nameRegex, 'last_name_invalid')
      .required('please_enter_your_last_name'),
    primarySpecialty: Yup.string()
      .required('please_enter_your_specialty'),
    secondarySpecialty: Yup.string()
      .when("secondarySpecialty", (val) => {
        if (val?.length > 0) {
          return Yup.string().notOneOf([Yup.ref('primarySpecialty'), null], 'the_two_specialties_should_not_be_equal');
        } else {
          return Yup.string().notRequired();
        }
      }),
    location: Yup.string()
      .required('please_enter_your_postal_code'),
    bsnr: Yup.string()
      .required('please_enter_bsnr'),
    telephone: Yup.string()
      .matches(telephoneRegex, 'phone_number_invalid')
      .test(
        "len",
        "phone_length",
        (val) => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || (val.length >= 9 && val.length <= 20);
        }
      )
      .required('please_enter_your_phone_number'),
    email: Yup.string()
      .email('invalid_email')
      .required('please_enter_your_email')
    
  },
  ["secondarySpecialty", "secondarySpecialty"]
);

export const PatientsSignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .test(
      "len",
      "name_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 1 && val.length <= 25);
      }
    )
    .matches(nameRegex, 'name_invalid')
    .required('please_enter_your_first_name'),
  lastName: Yup.string()
    .test(
      "len",
      "last_name_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 1 && val.length <= 25);
      }
    )
    .matches(nameRegex, 'last_name_invalid')
    .required('please_enter_your_last_name'),
  birthDate: Yup.string()
    .required('please_enter_your_birth_date'),
  telephone: Yup.string()
    .matches(telephoneRegex, 'phone_number_invalid')
    .test(
      "len",
      "phone_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 9 && val.length <= 20);
      }
    )
    .required('please_enter_your_phone_number'),
  email: Yup.string()
    .email('invalid_email')
    .required('please_enter_your_email'),
});

export const EditClinicSchema = Yup.object().shape({
  name: Yup.string()
    .required('please_enter_clinic_name'),
  slogan: Yup.string()
    .required('please_enter_clinic_slogan'),
  location: Yup.string()
    .required('please_enter_clinic_location'),
  city: Yup.string()
    .required('please_enter_clinic_city'),
  postal_code: Yup.string()
    .required('please_enter_clinic_postal_code'),
  telephone: Yup.string()
    .matches(telephoneRegex, 'phone_number_invalid')
    .test(
      "len",
      "phone_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 9 && val.length <= 20);
      }
    )
    .required('please_enter_your_phone_number'),
  email: Yup.string()
    .email('invalid_email')
    .required('please_enter_your_email'),
  website: Yup.string()
    .required('please_enter_clinic_website'),
  bsnr: Yup.string()
    .required('please_enter_bsnr'),
  description: Yup.string()
    .required('please_enter_clinic_description')
});

export const CreateClinicSchema = Yup.object().shape({
  name: Yup.string()
    .required('please_enter_clinic_name'),
  slogan: Yup.string(),
  location: Yup.string()
    .required('please_enter_clinic_location'),
  city: Yup.string()
    .required('please_enter_clinic_city'),
  postal_code: Yup.string()
    .required('please_enter_clinic_postal_code'),
  email: Yup.string()
    .email('invalid_email'),
  telephone: Yup.string()
    .matches(telephoneRegex, 'phone_number_invalid')
    .test(
      "len",
      "phone_length",
      (val) => {
        if (val === undefined) {
          return true;
        }
        return val.length === 0 || (val.length >= 9 && val.length <= 20);
      }
    ),
  website: Yup.string(),
  bsnr: Yup.string()
    .required('please_enter_bsnr'),
  description: Yup.string()
    .required('please_enter_clinic_description'),
});

export const WorkingStatusOnHoliday = Yup.object().shape({
  working_status: Yup.string()
    .required('please_enter_work_status'),
});

export const WorkingStatusWork = Yup.object().shape({
  working_status: Yup.string()
});


export const DocToDocSchema = Yup.object().shape({
  specialty: Yup.string()
    .required('please_enter_your_specialty'),
  reason: Yup.string()
    .required('Please enter reason you need to be transferred'),
});
