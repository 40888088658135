import Header from "../../../Layouts/Header/Header";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import { EmployeeSect } from "../../InviteEmployee/InviteEmployee";
import { useState } from "react";

export const AddAdmin = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  
  return (
    <div>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <AppointmentStepChain active='details' type='add-clinic'/>
          <div className="welcome-whiteContainer m-t-40">
            <EmployeeSect isSubmit={isSubmit} setIsSubmit={setIsSubmit}/>
          </div>
        </section>
      </main>
    </div>
  )
};
