import { toast } from "react-toastify";

export const smallBannerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "27px",
  maxWidth: "580px",
  width: "100%"
}

export const largeBannerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "0 185px",
}

export const bannerStyles = {
  fontFamily: `'Inter', sans-serif`,
  position: "relative",
  borderRadius: "16px",
  height: "164px"
}

export const bannerLogoStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "2.5px",
  fontWeight: "700",
  fontSize: "36.6225px",
  lineHeight: "44px",
}

export const bannerTitleStyles = {
  gap: "19px",
  marginRight: "-19px",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "29px",
}

export const bannerLinkStyles = {
  position: "absolute",
  right: "26px",
  bottom: "32px",
}

export const largeBannerLincStyles = {
  position: "absolute",
  right: "40px",
  bottom: "69px",
}

export const CopyHtmlToClipboard = (id, i18n) => {
  const bannerElement = document.getElementById(id);
  if (bannerElement) {
    const html = bannerElement.outerHTML;
    
    const tempElement = document.createElement('textarea');
    tempElement.value = html;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
  
    toast.success(i18n.t("Successfully copied!"));
  }
}