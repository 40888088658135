import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/PatientInfo.css";
import "dayjs/locale/de";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import Loading from "../../Layouts/Loading/Loading";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import PatientInfoForm from "./PatientInfoForm";
import PatientInfoAppointments from "./PatientInfoAppointments";
import PatientsInfoDoctors from "./PatientsInfoDoctors";
import PatientInfoClinics from "./PatientInfoClinics";
import { ActiveBtn } from "../../Components/ActiveBtn";
import { getPatientInfo } from "../../Store/actions/appointmentActions";
import { getPatientAdditionalInfo, getPatientGeneralInfo } from "../../Store/actions/patientActions";
import { EmptyUserProfileImgLight } from "../../Assets/Images/img";
import { PatientsInfoRelatedAccounts } from "./PatientsInfoRelatedAccounts";
import { PatientsInfoDoctorTransfer } from "./PatientsInfoDoctorTransfer";
import { RoleArray } from "../../Utils/constants";
import { Doctor2DoctorPopup } from "../../Components/Doctor2DoctorPopup";
import { getCaretakers } from "../../Store/actions/employeeActions";
import { getAllEmployeesList } from "../../Store/actions/employeeActions";
export default function PatientInfo() {
  const { i18n } = useTranslation();
  
  const { id } = useParams();
  
  const dispatch = useDispatch();
  
  const role = useSelector((state) => state.userReducer.user.role);
  const appointmentsList = useSelector(state => state.appointmentReducer?.patient);
  const { patientGeneralInfo } = useSelector(state => state.patientReducer);
  
  const [display, setDisplay] = useState(<Loading/>);
  const [showButton, setShowButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    dispatch(getPatientInfo(id, i18n));
    dispatch(getPatientGeneralInfo(id, i18n));
    dispatch(getPatientAdditionalInfo(id, i18n));
    dispatch(getCaretakers(id, i18n));
    dispatch(getAllEmployeesList(i18n));
  }, [id]);
  
  useEffect(() => {
    if (patientGeneralInfo) {
      setDisplay(<PatientInfoForm patient={patientGeneralInfo}/>)
    }
  }, [patientGeneralInfo]);
  
  if (!appointmentsList || id !== patientGeneralInfo?.uuid) {
    return <Loading/>
  }
  
  const handleClosePopup = () => {
    setShowPopup(false)
  };
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading patient-info-heading">
            <div className='employee-heading'>{i18n.t("Patient Profile")}</div>
            <div className="manage-patient-detail">
              <div className="circle-crop-100">
                <img alt="avatar"
                     src={patientGeneralInfo?.photo_url || EmptyUserProfileImgLight}
                />
              </div>
              <div className="patient-info-name">
                {`${patientGeneralInfo?.firstName} ${patientGeneralInfo?.lastName}`}
              </div>
            </div>
            
            <div className="patient-info-items">
              <div className='patient-info-flexbox'>
                <div className="patient-info-buttons">
                  <div className="patient-info-btn active-btn"
                       onClick={(event) => {
                         setDisplay(<PatientInfoForm patient={patientGeneralInfo}/>);
                         setShowButton(false);
                         ActiveBtn(event);
                       }}
                  >
                    <Trans>User Info</Trans>
                  </div>
                  {role !== RoleArray.SUPER_ADMIN ?
                    <div className="patient-info-btn"
                         onClick={(event) => {
                           if (!appointmentsList) {
                             setDisplay(<SearchNoFound/>);
                             setShowButton(false);
                           } else {
                             setDisplay(<PatientInfoAppointments appointmentsList={appointmentsList}/>);
                             setShowButton(false);
                           }
                           ActiveBtn(event);
                         }}
                    >
                      <Trans>Requests</Trans>
                    </div>
                    :
                    null
                  }
                  <div className="patient-info-btn"
                       onClick={(event) => {
                         setDisplay(<PatientsInfoDoctors/>);
                         setShowButton(false);
                         ActiveBtn(event);
                       }}
                  >
                    <Trans>Related Doctors</Trans>
                  </div>
                  {role !== RoleArray.GHOST_USER ?
                    <div className="patient-info-btn"
                         onClick={(event) => {
                           setDisplay(<PatientInfoClinics/>);
                           setShowButton(false);
                           ActiveBtn(event);
                         }}
                    >
                      <Trans>Related Clinics</Trans>
                    </div>
                    :
                    null
                  }
                  {role !== RoleArray.GHOST_USER ?
                    <div className="patient-info-btn"
                         onClick={(event) => {
                           setDisplay(<PatientsInfoRelatedAccounts/>);
                           setShowButton(false);
                           ActiveBtn(event);
                         }}
                    >
                      <Trans>Related Accounts</Trans>
                    </div>
                    :
                    null
                  }
                  {role !== RoleArray.GHOST_USER ?
                    <div className="patient-info-btn"
                         onClick={(event) => {
                           setDisplay(<PatientsInfoDoctorTransfer/>);
                           setShowButton(role !== RoleArray.SUPER_ADMIN);
                           ActiveBtn(event);
                         }}
                    >
                      <Trans>Doc2Doc Transfer</Trans>
                    </div>
                    :
                    null
                  }
                
                </div>
                {showButton &&
                <div className="manage-patient-add-btn mt-20"
                     onClick={() => setShowPopup(true)}
                >
                  + <Trans>New Request</Trans>
                </div>
                }
              </div>
              <div>{display}</div>
            </div>
          </div>
        </section>
      </main>
      
      <Doctor2DoctorPopup handleClose={handleClosePopup} show={showPopup}/>
    </>
  )
}
