import "dayjs/locale/de";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { editStatus } from "../../Store/actions/appointmentActions";
import { appointmentsTypes, StatusArray, theme, timeBlocks } from "../../Utils/constants";
import { useState } from "react";

export default function Approve({ item, pendingItems, setPendingItems, openDropdown, setOpenDropdown }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const [date, setDate] = useState(new Date(item?.date));
  const [time, setTime] = useState(timeBlocks[item?.day_block_id].time);
  
  return (
    <div className="position-relative"
         style={
           openDropdown === item?.id ? { display: "block" } : { display: "none" }
         }
    >
      <div className="item-dropdown">
        <label className="label-notice">
          <Trans>Choose date</Trans>:
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
          <ThemeProvider theme={theme}>
            <DatePicker
              id='approve-date-picker'
              views={['day']}
              openTo={"year"}
              inputFormat="DD.MM.YYYY"
              disablePast
              minDate={new Date(Date.now())}
              value={date}
              onChange={(value) => {
                setDate(new Date(value));
              }}
              renderInput={(params) =>
                <TextField {...params} helperText={null} sx={{ svg: { color: '#47C0C1' } }}/>
              }
            />
          </ThemeProvider>
        </LocalizationProvider>
        <p className="dropdown-error">
          {date.getTime() < new Date(Date.now()).getTime() && i18n.t("Selected date has already passed")}
        </p>
        <label className="dropdown-date-item">
          {i18n.t(timeBlocks[item?.day_block_id].message)}
        </label>
        <ThemeProvider theme={theme}>
          <TextField
            id="time"
            type="time"
            defaultValue={time}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            sx={{
              width: 220,
              '& input[type="time"]::-webkit-calendar-picker-indicator': {
                filter:
                  'invert(89%) sepia(57%) saturate(2168%) hue-rotate(138deg) brightness(83%) contrast(76%)',
              },
            }}
            onChange={(event) => {
              setTime(event.target.value);
            }}
          />
        </ThemeProvider>
        <div className="dropdown-btns">
          <button className="cancel-btn dropdown-approve-btn btn scale-btn"
                  onClick={(event) => {
                    event.preventDefault();
                    setOpenDropdown(null);
                  }}
          >
            <Trans>Cancel</Trans>
          </button>
          <button className="success-btn dropdown-approve-btn btn scale-btn"
                  onClick={(event) => {
                    event.preventDefault();
            
                    const hours = time.slice(0, 2);
                    const min = time.slice(3);
            
                    const newDate = new Date(date);
                    newDate.setHours(hours, min);
            
                    setTimeout(() => {
                      dispatch(editStatus(
                        appointmentsTypes[item?.type],
                        item?.id,
                        StatusArray.APPROVED,
                        item.user_id,
                        pendingItems,
                        setPendingItems,
                        newDate,
                        item?.notice,
                        i18n
                      ));
                    }, 150);
            
                    setOpenDropdown(null);
                  }}
          >
            <Trans>Approve</Trans>
          </button>
        </div>
      </div>
    </div>
  );
}
