import "../../../Assets/Styles/ForPatients.css";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { logOut } from "../../../Store/actions/userActions";
import { ConfirmPendingImg } from "../../../Assets/Images/img";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../Utils/Routes";

const ListSubtitle = ({ type }) => {
  const { i18n } = useTranslation();
  
  switch (type) {
    case 'appointment':
      return `${i18n.t("Your appointment")} ${i18n.t("isnt confirmed yet by office manager.")}`;
    case 'drug-order':
      return i18n.t('Your medication request was sent.');
    case 'doctor-transfer':
      return i18n.t('We will send you feedback from your doctor');
  }
};

export default function ConfirmPending({ type }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  
  return (
    <>
      <div className="list">
        <div className="list-heading">
          <p>{type === 'doctor-transfer' ? i18n.t('Request was sent') : i18n.t('Pending')}</p>
          <div className="list-subtitle">
            <ListSubtitle type={type}/>
          </div>
        </div>
        <img alt="logo" className="list-img" width="224"
             src={ConfirmPendingImg}
        />
        <div className="list-img-heading">
          {type === 'doctor-transfer' ? i18n.t('Please wait for feedback from your doctor') : i18n.t('Please wait for confirmation')}
        </div>
        <button className="for-patients-btn btn scale-btn"
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(logOut());
                  if (data.isRegistered === 'login') {
                    navigate(paths.registeredAd);
                  } else {
                    navigate(paths.unregisteredAd);
                  }
                }}
        >
          OK
        </button>
      </div>
    </>
  );
}
