import React, { useReducer, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../Layouts/Header/Header";
import { ClinicField } from "./components/ClinicField";
import { CreateClinicSchema } from "../../../Utils/ValidationSchemas";
import { DayContainer } from "./components/DayContainer";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import { reducer, setWorkingHours } from "../../../Store/actions/clinicActions";
import "./index.css";
import { ACTIONS, PermissionsArray } from "../../../Utils/constants";
import { InviteClinicComponentKeys } from "../../../Utils/ConstantsInviteClinic";
import ValidationError from "../../../Components/ValidationError";
import { ARROWS } from "../../../Assets/Images/svg";
import {
  CrossedPasswordIco,
  EmptyClinicProfileImg,
  InfoIco,
  InputWarningIco,
  PasswordIco
} from "../../../Assets/Images/img";


function AddClinic() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const clinicInfo = useSelector(state => state.inviteClinicReducer.data?.clinicInfo);
  const [state, changeState] = useReducer(reducer, {
    days: {
      monday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.monday,
        time: clinicInfo?.workingHours?.monday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.monday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      tuesday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.tuesday,
        time: clinicInfo?.workingHours?.tuesday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.tuesday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      wednesday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.wednesday,
        time: clinicInfo?.workingHours?.wednesday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.wednesday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      thursday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.thursday,
        time: clinicInfo?.workingHours?.thursday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.thursday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      friday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.friday,
        time: clinicInfo?.workingHours?.friday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.friday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      saturday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.saturday,
        time: clinicInfo?.workingHours?.saturday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.saturday?.map(entry => entry.to)) || ["08:00", "20:00"]
      },
      sunday: {
        isShown: false,
        isChecked: clinicInfo?.workingHours?.sunday,
        time: clinicInfo?.workingHours?.sunday?.map(entry => entry.from)
          ?.concat(clinicInfo?.workingHours?.sunday?.map(entry => entry.to)) || ["08:00", "20:00"]
      }
    }
  });
  
  const [clinicPhoto, setClinicPhoto] = useState(clinicInfo?.clinicPhoto);
  const [isShowAdditionalInfo, setIsShowAdditionalInfo] = useState(false);
  
  return (
    <div>
      <Header isHeaderHidden/>
      <section className="sect">
        <AppointmentStepChain active="clinic" type="add-clinic"/>
        <div className="clinic-white-container">
          <div className="clinic-header">
            <p>{i18n.t("Add Required Information about Clinic")}</p>
          </div>
          <div className="clinic-form">
            <Formik
              initialValues={{
                name: clinicInfo.name,
                slogan: clinicInfo.slogan ?? '',
                location: clinicInfo.location,
                email: clinicInfo.email ?? '',
                telephone: clinicInfo.telephone ?? '',
                bsnr: clinicInfo.bsnr,
                website: clinicInfo.website ?? '',
                description: clinicInfo.description,
                city: clinicInfo?.city ?? '',
                postal_code: clinicInfo?.postal_code ?? ''
              }}
              validationSchema={CreateClinicSchema}
              onSubmit={(values) => {
                let { stringWorkingHours, objectWorkingHours } = setWorkingHours(state.days);
                dispatch({
                  type: ACTIONS.INVITE_CLINIC.SET_CLINIC_DATA,
                  payload: {
                    clinicInfo: {
                      ...values,
                      clinicPhoto,
                      workingHours: objectWorkingHours
                    }
                  }
                });
                dispatch({
                  type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
                  payload: InviteClinicComponentKeys.ADD_EMPLOYEE
                });
                dispatch({
                  type: ACTIONS.INVITE_USER.SET_INVITE_USER,
                  payload: {
                    role: PermissionsArray.CLINIC_ADMIN
                  }
                });
              }}
            >
              {({ errors, touched, handleChange }) => (
                <Form className="clinic-manage-form-col">
                  <ClinicField errors={errors} touched={touched} type="text" name="name" displayName="Clinic Name"/>
                  <ClinicField errors={errors} touched={touched} type="text" name="description"
                               displayName="Clinic Description"
                  />
                  <ClinicField errors={errors} touched={touched} type="text" name="location"
                               maxLength="30" displayName="Street and No"
                  />
                  <ClinicField errors={errors} touched={touched} type="text" name="city"
                               maxLength="30" displayName="City"
                  />
                  <ClinicField errors={errors} touched={touched} type="text" name="postal_code"
                               maxLength="30" displayName="Postal Code"
                  />
                  <ClinicField errors={errors} touched={touched} type="text" name="bsnr"
                               maxLength="40" displayName="BSNR Number"
                  />
                  <div className="clinic-header">
                    <p>{i18n.t("Additional Information (You can skip it for now)")}</p>
                    <div className={isShowAdditionalInfo ? "clinic-arrow" : "clinic-arrow-rotate"}
                         onClick={() => setIsShowAdditionalInfo(!isShowAdditionalInfo)}
                    >
                      {ARROWS.BOTTOM}
                    </div>
                  </div>
                  <div className={isShowAdditionalInfo ? "clinic-form-container" : "clinic-form-container-expanded"}>
                    <div className="clinic-logo">
                      <div className="manage-img">
                        <div className="circle-crop-100">
                          <img alt="photo" src={clinicPhoto || EmptyClinicProfileImg}/>
                        </div>
                        {clinicPhoto &&
                          <label htmlFor="files" className="manage-delete_file" onClick={() => setClinicPhoto('')}/>
                        }
                        <label htmlFor="files" className="manage-upload_file"/>
                        <input name="attachment" type="file"
                               id="files" accept="image/*" hidden
                               onChange={(e) => {
                                 const reader = new FileReader();
                                 reader.readAsDataURL(e.target.files[0]);
                                 reader.onloadend = () => {
                                   setClinicPhoto(reader.result);
                                   e.target.value = '';
                                 };
                               }}
                        />
                      </div>
                    </div>
                    <ClinicField errors={errors} touched={touched} type="text" name="slogan" displayName="Slogan"
                                 required={false}
                    />
                    <div className="clinic-date-box">
                      <label><Trans>Working hours</Trans></label>
                      <div className="clinic-date-container">
                        <DayContainer days={state.days} day="Monday" name="monday"
                                      setDays={changeState} isChecked={state.days.monday.isChecked}
                                      hoursRangeLength={state.days.monday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Tuesday" name="tuesday"
                                      setDays={changeState} isChecked={state.days.tuesday.isChecked}
                                      hoursRangeLength={state.days.tuesday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Wednesday" name="wednesday"
                                      setDays={changeState}
                                      isChecked={state.days.wednesday.isChecked}
                                      hoursRangeLength={state.days.wednesday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Thursday" name="thursday"
                                      setDays={changeState}
                                      isChecked={state.days.thursday.isChecked}
                                      hoursRangeLength={state.days.thursday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Friday" name="friday"
                                      setDays={changeState} isChecked={state.days.friday.isChecked}
                                      hoursRangeLength={state.days.friday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Saturday" name="saturday"
                                      setDays={changeState}
                                      isChecked={state.days.saturday.isChecked}
                                      hoursRangeLength={state.days.saturday.time.length / 2}
                        />
                        <DayContainer days={state.days} day="Sunday" name="sunday"
                                      setDays={changeState} isChecked={state.days.sunday.isChecked}
                                      hoursRangeLength={state.days.sunday.time.length / 2}
                        />
                      </div>
                    </div>
                    <ClinicField errors={errors} touched={touched} type="text" name="telephone"
                                 maxLength="20" displayName="Clinic Telephone" required={false}
                    />
                    <div className="clinic-input-container">
                      <label><Trans>Email</Trans></label>
                      <Field
                        className={`clinic-field ${(errors.email && touched.email) ? 'input-red-border' : ''}`}
                        type="email"
                        name="email"
                        maxLength="40" required={false}
                        onChange={(event) => {
                          const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                          handleChange(event);
                          handleChange({ target: { name: 'email', value: cleanedValue } });
                        }}
                      />
                      <div className='input-icons'>
                        {(errors.email && touched.email) &&
                          <img alt="show_pw" src={InputWarningIco}/>
                        }
                      </div>
                      <ValidationError errors={errors.email} touched={touched.email}/>
                    </div>
                    <ClinicField errors={errors} touched={touched} type="text" name="website"
                                 maxLength="50" displayName="Webpage" required={false}
                    />
                  </div>
                  <button className="welcome-button" type="submit"
                          disabled={Object.keys(errors).length}
                  >
                    {i18n.t("Continue")}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        
        
        </div>
      </section>
    </div>
  );
}

export default AddClinic;
