import "../../Assets/Styles/Advertising.css";
import Header from "../../Layouts/Header/Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppStoreIco, GooglePlayIco } from "../../Assets/Images/img";


export default function AdvertisingForGuest() {
  const { i18n } = useTranslation();
  
  return (
    <div className='ads-wrap'>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='ads-container'>
            <div className='ads-info'>
              <p className='ads-heading'>
                {i18n.t("Thank you for")} <br/>{i18n.t("choosing")} <span>meplano</span>!
              </p>
              <p className='ads-subtitle'>
                {i18n.t("Download the application and it will help you to book and manage your medical appointments, drug prescription and specialist transfer.")}
              </p>
              <div className='ads-buttons'>
                <Link to='https://www.apple.com/app-store/'>
                  <img alt='btn'
                       src={AppStoreIco}
                  />
                </Link>
                <Link to='https://play.google.com/store'>
                  <img alt='btn'
                       src={GooglePlayIco}
                  />
                </Link>
              </div>
            </div>
            <img alt='ads' src={i18n.t("ForPatientsAdsImg")}/>
          </div>
        </section>
      </main>
    </div>
  );
}
