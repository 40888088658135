import "../InviteClinic/InviteClinicWelcomePage/index.css";
import "../../Assets/Styles/ForPatients.css";
import { useState } from "react";
import Header from "../../Layouts/Header/Header";
import AppointmentStepChain from "../../Components/AppointmentStepChain";
import UserVerify from "../Verification/UserVerify";
import { InviteEmployeeForm } from "./InviteEmployeeForm";

export const EmployeeSect = ({ isSubmit, setIsSubmit }) => {
  switch (isSubmit) {
    case false:
      return <InviteEmployeeForm setIsSubmit={setIsSubmit}/>;
    case true:
      return <UserVerify/>;
  }
}

export const InviteEmployee = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  
  return (
    <div>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <AppointmentStepChain active='details' type='employee'/>
          <div className="welcome-whiteContainer m-t-40">
            <EmployeeSect isSubmit={isSubmit} setIsSubmit={setIsSubmit}/>
          </div>
        </section>
      </main>
    </div>
  )
};
