import "../../Assets/Styles/ManagePatient.css";
import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import Header from "../../Layouts/Header/Header";
import { ACTIONS } from "../../Utils/constants";
import AllRequestsPending from "./AllRequestsPending";
import AllRequestsDone from "./AllRequestsDone";
import { ActiveBtn, ActiveTab } from "../../Components/ActiveBtn";


export default function AllRequests() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  
  const [table, setTable] = useState("pending");
  const [activeTab, setActiveTab] = useState("allRequests");
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  
  const changeHandler = (event) => {
    dispatch({
      type: ACTIONS.APPOINTMENT.CLEAR_USERS
    });
    setQuery(event.target.value.trim().toLowerCase());
  };
  
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading">
            <div className='manage-patient-buttons-wrap'>
              <div className="manage-patient-buttons">
                <div
                  id="pending"
                  className="manage-patient-btn active-btn"
                  onClick={(event) => {
                    setActiveTab('allRequests');
                    setTable('pending')
                    ActiveBtn(event);
                    ActiveTab(null, 'all-requests');
                  }}
                >
                  <Trans>Pending</Trans>
                </div>
                <div
                  id="done"
                  className="manage-patient-btn"
                  onClick={(event) => {
                    setTable('done')
                    setActiveTab('allRequests');
                    ActiveBtn(event);
                    ActiveTab(null, 'all-requests');
                  }}
                >
                  <Trans>Done</Trans>
                </div>
              </div>
              <div className="manage-patient-buttons">
                <div
                  id="all-requests"
                  className="manage-patient-btn active-tab request-btn"
                  onClick={(event) => {
                    setActiveTab('allRequests');
                    ActiveTab(event);
                  }}
                >
                  <Trans>All</Trans>
                </div>
                <div
                  id="appointment"
                  className="manage-patient-btn request-btn"
                  onClick={(event) => {
                    setActiveTab('appointment');
                    ActiveTab(event);
                  }}
                >
                  <Trans>Medical Appointment</Trans>
                </div>
                <div
                  id="transfer"
                  className="manage-patient-btn request-btn"
                  onClick={(event) => {
                    setActiveTab('doctor transfer');
                    ActiveTab(event);
                  }}
                >
                  <Trans>Specialist Transfer</Trans>
                </div>
                <div
                  id="drugs"
                  className="manage-patient-btn request-btn"
                  onClick={(event) => {
                    setActiveTab('drug order');
                    ActiveTab(event);
                  }}
                >
                  <Trans>Drug Prescription</Trans>
                </div>
                <div
                  id="doc2doc"
                  className="manage-patient-btn request-btn"
                  onClick={(event) => {
                    
                    setActiveTab('doc2Doc');
                    ActiveTab(event);
                  }}
                >
                  <Trans>Doc2Doc Transfer</Trans>
                </div>
              </div>
            </div>
            {(table === "pending") &&
            <div className="search">
              <div className="search-input-wrap">
                <input className="search-input"
                       ref={inputRef}
                       placeholder={i18n.t("Search")}
                       maxLength="30"
                       onChange={debouncedChangeHandler}
                />
                {query &&
                <div className="search-clear"
                     onClick={() => {
                       dispatch({
                         type: ACTIONS.APPOINTMENT.CLEAR_USERS
                       });
                       setQuery("");
                       inputRef.current.value = "";
                     }}
                >
                  ✕
                </div>
                }
              </div>
            </div>
            }
          </div>
          {/*TODO add new design and logic to displaying data*/}
          <div className="manage-patient-table">
            {table === "pending" ?
              <AllRequestsPending status={table} query={query} activeTab={activeTab}/>
              :
              <AllRequestsDone activeTab={activeTab}/>
            }
          </div>
        </section>
      </main>
    </>
  )
}
