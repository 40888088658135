import { toast } from "react-toastify";
import { apiGet, apiPost, apiPut } from "../../http/headerPlaceholder.instance";
import { ACTIONS, PermissionsArray, RoleArray } from "../../Utils/constants";
import { submitClinic } from "./clinicActions";
import { registerEmployee } from "./employeeActions";
import { patientSignUp } from "./patientActions";
import { ForPatientComponentKeys } from "../../Utils/ConstantsForPatient";
import dayjs from "dayjs";
import { paths } from "../../Utils/Routes";

export const login = (email, password, token, tries, navigate, i18n, role) => {
  return async (dispatch) => {
    apiPost({
      url: "/auth/log-in",
      data: { email, password, token, try: tries }
    })
      .then(({ data }) => {
        localStorage.setItem('uuid', data.userUUID);
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshTokne);
        delete data.refreshToken;
        dispatch({
          type: ACTIONS.USER.SET_TOKENS,
          payload: data
        });
        dispatch({
          type: ACTIONS.USER.SET_USER,
          payload: data?.user
        });
        if (role === 'patient') {
          dispatch({
            type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
            payload: {
              isLogin: true,
              user_id: data.userUUID,
              userEmail: email,
              role
            }
          });
        }
      })
      .then(() => {
        role === 'employee' && navigate("/");
      })
      .catch(() => {
        toast.error(i18n.t("Invalid email or password"));
        dispatch({
          type: ACTIONS.USER.INCREASE_FAILS,
          payload: tries + 1
        });
      });
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
      dispatch({
        type: ACTIONS.USER.LOG_OUT
      });
      
      return;
    }
    
    apiPut({
      url: "/auth/log-in",
      data: { refreshToken }
    })
      .then(({ data }) => {
        delete data.refreshToken;
        dispatch({
          type: ACTIONS.USER.SET_TOKENS,
          payload: data
        });
        dispatch({
          type: ACTIONS.USER.SET_USER,
          payload: data?.user
        });
      })
      .catch(() => {
        if (accessToken) {
          dispatch(logOut());
        } else {
          dispatch({
            type: ACTIONS.USER.LOG_OUT
          });
        }
      });
  };
};

export const logOut = () => {
  return async (dispatch) => {
    try {
      await apiPost({
        url: `/auth/log-out`
      });
    } catch (e) {
      console.log('error logout: ', e);
    }
    
    dispatch({
      type: ACTIONS.USER.LOG_OUT
    });
  };
};

export const getUserInfo = (uuid, i18n, role = null) => {
  return async (dispatch) => {
    apiGet({
      url: `/users/${uuid}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.USER.SET_USER,
          payload: data
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        
        if (role && (Number(role) !== RoleArray.PATIENT || role.toString() !== 'patient')) {
          const keysToRemove = ["accessToken", "uuid", "refreshToken"];
          keysToRemove.forEach(key => {
            localStorage.removeItem(key);
          });
          
          dispatch({
            type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
            payload: {
              isLogin: false,
              user_id: null,
              userEmail: null,
              role: null
            }
          });
          
          dispatch({
            type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
            payload: ForPatientComponentKeys.PATIENT_DETAILS
          });
        }
      });
  };
};

export const updateUserInfo = (uuid, values, i18n) => {
  const {
    firstName,
    lastName,
    location,
    telephone,
    birthDate
  } = values;
  
  return async (dispatch) => {
    apiPut({
      url: `/users/${uuid}/update-user-info`,
      data: {
        birthDate: dayjs(birthDate).add(1, "day"),
        firstName,
        lastName,
        location,
        telephone,
      }
    })
      .then(() => {
        toast.success(i18n.t("Updated!"));
        setTimeout(() => {
          dispatch(getUserInfo(uuid, i18n));
        }, 500);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        } else {
          toast.error(i18n.t("Something went wrong"));
        }
      });
  };
};

export const updateUserPhoto = (uuid, data, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/users/${uuid}/update-profile-photo`,
      data: { data }
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.USER.ADD_PHOTO,
          payload: data.photo_url
        });
        toast.success(i18n.t("Updated!"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        } else {
          toast.error(i18n.t("Something went wrong"));
        }
      });
  };
};

export const sendRecoverPwEmail = (data, navigate, i18n) => {
  return async () => {
    apiPost({
      url: `/users/send-recover-password-email?lang=${i18n.language}`,
      data: {
        email: data
      }
    })
      .then(() => {
        navigate("/reset/password/success");
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const updatePassword = (newPassword, token, navigate, i18n) => {
  return async () => {
    apiPut({
      url: "/users/update-password",
      data: {
        newPassword,
        token
      }
    })
      .then(() => {
        navigate(paths.registeredAd);
        toast.success(i18n.t("Your password was updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const sendOTP = (email, captchaToken, setIsSubmit, i18n) => {
  return async () => {
    apiPost({
      url: '/auth/send-otp',
      data: {
        token: captchaToken,
        email
      }
    }).then(({ data }) => {
      localStorage.setItem('accessToken', data.token);
      !!setIsSubmit && setIsSubmit(true);
    }).catch(() => {
      toast.error(i18n.t("Something went wrong"));
    });
  };
};

export const verifyOtp = (captchaToken, code, user, clinicInfo, AppointmentData, setIsAvailable, i18n) => {
  const { email } = user;
  
  return async (dispatch) => {
    apiPost({
      url: `/auth/check-otp`,
      data: {
        token: captchaToken,
        code: code,
        email
      }
    })
      .then(() => {
        setIsAvailable(true);
        switch (user?.role) {
          case PermissionsArray.CLINIC_ADMIN: {
            dispatch(submitClinic(user, captchaToken, clinicInfo, i18n));
            break;
          }
          case RoleArray.PATIENT: {
            return dispatch(patientSignUp(user, captchaToken, i18n, AppointmentData?.appointment));
          }
          case "": {
            dispatch(registerEmployee(user, captchaToken, i18n));
            break;
          }
        }
      })
      .catch(() => {
        setIsAvailable(true);
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const editPersonalInfo = (uuid, values, i18n) => {
  const {
    firstName,
    lastName,
    primarySpecialty,
    secondarySpecialty,
    location,
    telephone,
    email,
    lanr,
    birthDate
  } = values;
  
  return async (dispatch) => {
    apiPut({
      url: `doctors/${uuid}`,
      data: {
        firstName,
        lastName,
        birthDate: dayjs(birthDate).add(1, "day"),
        primarySpecialty,
        secondarySpecialty,
        location,
        telephone,
        email,
        lanr
      }
    })
      .then(async () => {
        dispatch({
          type: ACTIONS.EMPLOYEES.CLEAR_DATA,
        });
        toast.success(i18n.t("Updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};
