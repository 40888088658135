import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewRequests } from "../../../Store/actions/appointmentActions";
import { paths } from "../../../Utils/Routes";
import { Link } from "react-router-dom";
import { BellImg } from "../../../Assets/Images/img";
import { bellTypes, RoleArray } from "../../../Utils/constants";
import { getNewClinicsCount } from "../../../Store/actions/clinicActions";
import { getNewEmployeesCount } from "../../../Store/actions/employeeActions";

export const Bell = ({ type }) => {
  const userInfo = useSelector(state => state.userReducer.user);
  
  const [isNewInfo, setIsNewInfo] = useState(false);
  const dispatch = useDispatch();
  
  useInterval(() => {
    switch (type) {
      case bellTypes.request:
        return dispatch(getNewRequests(setIsNewInfo))
      case bellTypes.employees:
        return dispatch(getNewEmployeesCount(setIsNewInfo))
      case bellTypes.clinics: {
        if (userInfo.role === RoleArray.SUPER_ADMIN)
          dispatch(getNewClinicsCount(setIsNewInfo))
      }
        return
    }
  }, 300000);
  
  function useInterval(callback, delay) {
    const savedCallback = useRef();
    
    useEffect(() => {
      savedCallback.current = callback;
    });
    
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }
  
  const reloadPage = () => {
    setIsNewInfo(false);
    switch (type) {
      case bellTypes.request: {
        if (window.location.pathname === paths.allRequests) {
          window.location.reload();
        }
        return
      }
      case bellTypes.employees: {
        if (window.location.pathname === paths.employees) {
          window.location.reload();
        }
        return
      }
      case bellTypes.clinics: {
        if (window.location.pathname === paths.clinics) {
          window.location.reload();
        }
        return
      }
    }
  }
  
  return isNewInfo &&
    <Link to={
      type === bellTypes.request ?
        paths.allRequests
        : type === bellTypes.employees ? paths.employees
          : paths.clinics
    }
    >
      <img className="bell" alt="bell" src={BellImg}
           onClick={reloadPage}
      />
    </Link>
}
