import "../../Assets/Styles/ManageProfile.css";
import "../../Assets/Styles/ManageOffice.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import React, { useEffect } from "react";
import { editClinicStatus, getClinicById } from "../../Store/actions/clinicActions";
import Loading from "../../Layouts/Loading/Loading";
import WorkingHoursString from "../../Components/WorkingHoursString";
import { DaysArray, StatusArray } from "../../Utils/constants";
import { EmptyClinicProfileImgLight } from "../../Assets/Images/img";
import dayjs from "dayjs";

export default function ClinicApprove() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  
  const clinic = useSelector(state => state.clinicReducer.clinic);
  
  useEffect(() => {
    dispatch(getClinicById(uuid, i18n))
  }, [uuid]);
  
  if (!clinic || (uuid && uuid !== clinic?.uuid)) {
    return <Loading/>
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect">
          <div className='clinic-heading'>{clinic?.name}</div>
          <div className="manage-clinic">
            <div className="manage-img">
              <div className="circle-crop-100">
                <img alt="avatar"
                     src={clinic?.image_url || EmptyClinicProfileImgLight}
                />
              </div>
            </div>
          </div>
          <div className="manage-form">
            <form className="manage-form-col">
              <div className="input-container">
                <label><Trans>Clinic Name</Trans></label>
                <input
                  type="text"
                  name="name" value={clinic?.name}
                  maxLength="50" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Slogan</Trans></label>
                <input type="text" name="slogan" value={clinic?.slogan} disabled/>
              </div>
              <div className="input-container">
                <label><Trans>Working hours</Trans></label>
                <div className='hours-list'>
                  {DaysArray.map(day =>
                    <WorkingHoursString
                      clinic={clinic} title={day}
                      day={clinic?.working_hours?.[day.toLowerCase()]}
                      isActive={
                        dayjs().locale('en').format('dddd') === day
                      }
                    />
                  )}
                </div>
              </div>
              <div className="input-container">
                <label><Trans>City</Trans></label>
                <input
                  type="text"
                  name="city" value={clinic?.city ?? ''}
                  maxLength="30" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Address</Trans></label>
                <input
                  type="text"
                  name="location" value={clinic?.location}
                  maxLength="30" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Postal Code</Trans></label>
                <input
                  type="text"
                  name="postal_code" value={clinic?.postal_code ?? ''}
                  maxLength="30" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Telephone</Trans></label>
                <input
                  type="tel"
                  name="telephone" value={clinic?.telephone}
                  maxLength="20" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Email</Trans></label>
                <input
                  type="email"
                  name="email" value={clinic?.email}
                  maxLength="40" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Webpage</Trans></label>
                <input
                  type="text"
                  name="website" value={clinic?.website}
                  maxLength="30" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>BSNR Number</Trans></label>
                <input
                  type="text"
                  name="bsnr" value={clinic?.bsnr}
                  maxLength="40" disabled
                />
              </div>
              {(clinic?.status === StatusArray.PENDING) &&
              <>
                <div className="select-btn clinic-btn btn scale-btn"
                     onClick={() => {
                       dispatch(editClinicStatus(Number(clinic?.id), StatusArray.APPROVED, navigate, i18n))
                     }}
                >
                  {i18n.t("Approve")}
                </div>
                <div className="warning-btn clinic-btn btn scale-btn"
                     onClick={() => {
                       dispatch(editClinicStatus(Number(clinic?.id), StatusArray.CANCELED, navigate, i18n))
                     }}
                >
                  {i18n.t("Reject")}
                </div>
              </>
              }
            </form>
          </div>
        </section>
      </main>
    </>
  );
}
