import { apiGet, apiPost, apiPut } from "../../http/headerPlaceholder.instance";
import { ACTIONS, calculateOffset, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";
import { toast } from "react-toastify";
import { checkAuth } from "./userActions";
import dayjs from "dayjs";

export const getAppointmentsList = (page = 0, status, i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/notifications/all?statusIds=${status}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}`
      });
      
      if (Number(status) !== 3) {
        dispatch({
          type: ACTIONS.APPOINTMENT.SET_DONE_APPOINTMENTS,
          payload: data
        });
      }
    } catch (e) {
      if (e.response.status === 401) {
        dispatch(checkAuth());
      }
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getUsersHavingPending = (name, page, i18n) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/manager/clinic-patients?name=${name}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}&hasPending=true`
      })
      
      dispatch({
        type: ACTIONS.APPOINTMENT.SET_USERS_HAVING_PENDING,
        payload: { data }
      })
    } catch (e) {
      if (e.response.status === 401) {
        dispatch(checkAuth());
      }
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getUserPendingAppointmentsList = (userId, setItems, i18n, setLoaded) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/notifications/all?uuid=${userId}&statusIds=3`
      });
      
      setItems({
        items: data,
        filteredItems: data
      });
      setLoaded(true);
    } catch (e) {
      if (e.response.status === 401) {
        dispatch(checkAuth());
      }
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const editNotice = (type, id, notice, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/${type}/${id}/notes`,
      data: {
        id,
        notice
      }
    })
      .then(() => {
        dispatch({
          type: ACTIONS.APPOINTMENT.UPDATE_NOTICE,
          payload: {
            id,
            notice
          }
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const editStatus = (type, id, statusId, userId, pendingItems, setPendingItems, newDate, notice, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/${type}/${id}/status`,
      data: {
        id,
        statusId,
        newDate,
        notice
      }
    })
      .then(() => {
        if (pendingItems.items.length === 1) {
          dispatch({
            type: ACTIONS.APPOINTMENT.REMOVE_FROM_LIST,
            payload: userId
          });
        } else {
          setPendingItems({
            items: pendingItems.items.filter(item => item.id !== id),
            filteredItems: pendingItems.filteredItems.filter(item => item.id !== id)
          })
        }
  
        toast.success(i18n.t("Success!"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const approveDoc2DocTransfer = (id, priorityId, values, i18n) => {
  const {
    reason,
    doctor
  } = values;
  
  return async (dispatch) => {
    apiPut({
      url: `/transfers/${id}/redirect`,
      data: {
        priorityId,
        doctorId: Number(doctor),
        notice: reason
      }
    })
      .then(() => {
        toast.success(i18n.t("Success!"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const rejectDoc2DocTransfer = (id, senderId, doctorNotice, i18n, doctorId, pendingItems, setPendingItems) => {
  return async (dispatch) => {
    apiPut({
      url: `/transfers/${id}/redirected/reject`,
      data: {
        senderId,
        doctorNotice
      }
    })
      .then(() => {
        if (Number(senderId) === Number(doctorId)) {
          setPendingItems({
            items: pendingItems.items.filter(item => item.id !== id),
            filteredItems: pendingItems.filteredItems.filter(item => item.id !== id)
          })
        } else {
          const newArr = pendingItems.filteredItems.map((obj) => {
            if (Number(obj.id) === Number(id)) {
              return {
                ...obj,
                doctor_notice: doctorNotice,
                doctor_id: null,
                doctor: null
              };
            }
            
            return obj;
          })
          
          setPendingItems({
            ...pendingItems,
            filteredItems: newArr
          })
        }
        toast.success(i18n.t("Success!"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const sendSpecialistTransfer = (values, i18n, clinicId, priorityId, userId) => {
  const {
    specialty,
    reason,
    doctor
  } = values;
  
  const doctorId = Number(doctor) === -1 ? null : Number(doctor)
  
  return async (dispatch) => {
    apiPost({
      url: `transfers/`,
      data: {
        specialtyId: Number(specialty),
        doctorId,
        clinicId,
        notice: reason,
        userId,
        priorityId,
        date: dayjs().add(1, "day")
      }
    })
      .then(async () => {
        dispatch({
          type: ACTIONS.EMPLOYEES.CLEAR_DATA,
        });
        toast.success(i18n.t("Send!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const partialApproval = (id, statusId, newDate, notice, drugsList, user_id, pendingItems, setPendingItems, i18n) => {
  const approvedDrugs = drugsList.map(item => item.drug.id);
  return async (dispatch) => {
    apiPut({
      url: `/drug-orders/${id}/status`,
      data: {
        id,
        statusId,
        newDate,
        notice,
        approvedDrugs
      }
    })
      .then(() => {
        if (pendingItems.items.length === 1) {
          dispatch({
            type: ACTIONS.APPOINTMENT.REMOVE_FROM_LIST,
            payload: user_id
          });
        } else {
          setPendingItems({
            items: pendingItems.items.filter(item => item.id !== id),
            filteredItems: pendingItems.filteredItems.filter(item => item.id !== id)
          })
        }
  
        toast.success(i18n.t("Success!"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const getPatientInfo = (id, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/notifications/all?uuid=${id}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.APPOINTMENT.SET_PATIENT,
          payload: data
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getNewRequests = (setIsNewRequest) => {
  return async (dispatch) => {
    apiGet({
      url: `/notifications/get-count`
    })
      .then(({ data }) => {
        if (data.count) {
          setIsNewRequest(true)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth());
        }
      });
  };
}

export const postAppointment = (data, clinicId, setConfirmStatus, i18n) => {
  const {
    appointmentType,
    appointmentDoctor,
    date
  } = data;
  
  return async (dispatch) => {
    apiPost({
      url: `/appointments`,
      data: {
        appointmentTypeId: appointmentType?.id,
        clinicId,
        date: new Date(date),
        doctorId: appointmentDoctor?.user_id,
        notice: "",
      }
    })
      .then(() => {
        setConfirmStatus('pending')
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth())
        }
        toast.error(i18n.t("Something went wrong"));
      })
  }
}

export const postDrugOrder = (data, userId, clinicId, setConfirmStatus, i18n) => {
  const {
    appointmentDoctor,
    drugs
  } = data;
  
  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  return async (dispatch) => {
    apiPost({
      url: `/drug-orders`,
      data: {
        doctorId: appointmentDoctor?.user_id,
        date: new Date(tomorrow),
        notice: "",
        drugs,
        clinicId
      }
    })
      .then(() => {
        setConfirmStatus('pending')
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth())
        }
        toast.error(i18n.t("Something went wrong"));
      })
  }
}

export const postDoctorTransfer = (data, userId, clinicId, setActiveStepChainIco, setConfirmStatus, i18n) => {
  const {
    appointmentDoctor,
    specialist,
    notice
  } = data;
  
  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  return async (dispatch) => {
    apiPost({
      url: `/transfers/`,
      data: {
        appointmentTypeId: 1,
        specialtyId: specialist?.id,
        doctorId: appointmentDoctor?.user_id,
        clinicId,
        date: new Date(tomorrow),
        notice
      }
    })
      .then(() => {
        setActiveStepChainIco('confirm');
        setConfirmStatus('pending');
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(checkAuth())
        }
        toast.error(i18n.t("Something went wrong"));
      })
  }
}
