import { useTranslation } from "react-i18next";
import WorkingHoursString from "./WorkingHoursString";
import { DaysArray } from "../Utils/constants";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AccordionArrow } from "../Assets/Images/img";
import dayjs from "dayjs";

export default function WorkingHoursAccordion() {
  const { i18n } = useTranslation();
  
  const clinic = useSelector(state => state.clinicReducer.clinic);
  
  const [isActive, setIsActive] = useState(false);
  
  const toggleAccordion = () => {
    setIsActive(!isActive);
  };
  
  return (
    <div className="accordion">
      <div
        className={`accordion-item ${isActive ? 'active' : ''}`}
        onClick={toggleAccordion}
      >
        <div className="accordion-header">
          <p>{i18n.t("Working hours")}</p>
          {!isActive &&
          <WorkingHoursString clinic={clinic} title={dayjs().format('dddd')}
                              day={clinic?.working_hours?.[dayjs().format('dddd').toLowerCase()]}
                              isActive={true}
          />
          }
          <img alt='arrow'
               src={AccordionArrow}
               className={`arrow ${isActive ? 'open' : ''}`}
          />
        </div>
        <div className={`accordion-content ${isActive ? 'active' : ''}`}>
          <div className='hours-list accordion-hours-list'>
            {DaysArray.map(day =>
              <WorkingHoursString clinic={clinic} title={day} key={day}
                                  day={clinic?.working_hours?.[day.toLowerCase()]}
                                  isActive={dayjs().locale('en').format('dddd') === day}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

