import "../../Assets/Styles/ManagePatient.css";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersHavingPending } from "../../Store/actions/appointmentActions";
import { useTranslation } from "react-i18next";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import RenderUsersHavingPending from "./RenderUsersHavingPending";
import PaginationButton from "../../Components/PaginationButton";

export default function AllRequestsPending({ query, activeTab }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const { items, page, loadNext, isReady } = useSelector((state) => state.appointmentReducer.users);
  
  useEffect(() => {
    if (!isReady || query) {
      dispatch(getUsersHavingPending(query, 0, i18n));
    }
  }, [query]);
  
  const handleNextUsers = useCallback(() => {
    dispatch(getUsersHavingPending(query, page, i18n));
  }, [page]);
  
  return (
    isReady && (
      <>
        {!items.length ?
          <SearchNoFound/>
          :
          <RenderUsersHavingPending items={items} activeTab={activeTab}/>
        }
        {(!!items.length && loadNext) &&
        <PaginationButton handleNext={handleNextUsers}/>
        }
      </>
    )
  );
}
