import "../../Assets/Styles/Advertising.css";
import Header from "../../Layouts/Header/Header";
import { Trans, useTranslation } from "react-i18next";
import { ClinicAdsImg } from "../../Assets/Images/img";
import { useNavigate } from "react-router-dom";
import { paths } from "../../Utils/Routes";


export default function AdvertisingForClinic() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <div className="ads-wrap">
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className="ads-container">
            <div className="ads-info"
                 style={localStorage.getItem("i18n") === 'en' ? { width: "486px" } : { width: "550px" }}
            >
              <p className="ads-heading">
                {i18n.t("Thank you for choosing")} <br/><span>meplano</span>!
              </p>
              <p className="ads-subtitle">
                {i18n.t("Web and mobile medical platform for doctors, for")}<br/>
                {i18n.t("managers and for patients.")}
              </p>
              <p className="ads-subtitle">
                {i18n.t("With this web platform doctors can easily")}<br/>
                {i18n.t("manage their appointments with patients, makes")}<br/>
                {i18n.t("drug prescriptions and specialists transfers.")}
              </p>
              <p className="ads-subtitle">
                {i18n.t("Add a clinic for better cooperation with patients")}<br/>
                {i18n.t("and manage it through your account on the")}<br/>
                {i18n.t("platform.")}
              </p>
              <button className="ads-button" onClick={() => {
                localStorage.clear();
                navigate(paths.login);
              }}>
                <Trans>Start</Trans>
              </button>
            </div>
            <img alt="ads" className="clinic-ads-img"
                 src={ClinicAdsImg}
            />
          </div>
        </section>
      </main>
    </div>
  );
}
