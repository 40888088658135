import "../../Assets/Styles/ForPatients.css";
import Header from "../../Layouts/Header/Header";
import AppointmentStepChain from "../../Components/AppointmentStepChain";
import React from "react";
import { useTranslation } from "react-i18next";
import { ACTIONS, PermissionsArray } from "../../Utils/constants";
import { InviteEmployeeComponentKeys } from "../../Utils/ConctantsInviteEmployee";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmFailedCodeImg } from "../../Assets/Images/img";
import { InviteClinicComponentKeys } from "../../Utils/ConstantsInviteClinic";


export default function InviteEmployeeInvalidCode() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { role } = useSelector(state => state.inviteUserReducer.user);
  
  return (
    <div>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <AppointmentStepChain active="confirm" type="employee"/>
          <div className="welcome-whiteContainer m-t-40">
            <div className="list">
              <div className="list-heading">
                <p>{i18n.t("Verify Request")}</p>
                <div className="list-subtitle">
                  {i18n.t("Sorry, this code is not valid.")}
                </div>
              </div>
              <div className="center-container">
                <div className="list-img-error">
                  <img alt="logo" className="list-img" width="112"
                       src={ConfirmFailedCodeImg}
                  />
                </div>
                <div className="list-img-heading">{i18n.t("Please, request code again")}</div>
                <button className="for-patients-btn margin-button btn scale-btn"
                        onClick={(event) => {
                          event.preventDefault();
                          switch (role) {
                            case PermissionsArray.CLINIC_ADMIN : {
                              dispatch({
                                type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
                                payload: InviteClinicComponentKeys.ADD_CLINIC_VERIFY
                              });
                              break;
                            }
                            default: {
                              dispatch({
                                type: ACTIONS.INVITE_EMPLOYEE.SET_COMPONENT,
                                payload: InviteEmployeeComponentKeys.ADD_EMPLOYEE_VERIFY
                              });
                              break;
                            }
                            
                          }
                        }}
                >
                  {i18n.t("Request again")}
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
