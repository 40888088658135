import { combineReducers } from "redux";
import userReducer from "./userReducer";
import doctorReducer from "./doctorReducer";
import clinicReducer from "./clinicReducer";
import specialtyReducer from "./specialtyReducer";
import feedbackReducer from "./feedbackReducer";
import appointmentReducer from "./appointmentReduser";
import patientReducer from "./patientReducer";
import bookAppointmentReducer from "./bookAppointmentReducer";
import inviteEmployeeReducer from "./inviteEmployeeReducer";
import inviteClinicReducer from "./inviteClinicReducer";
import employeesReducer from "./employeesReducer";
import inviteUserReducer from "./InviteUserReduer";


const appReducer = combineReducers({
  userReducer,
  doctorReducer,
  clinicReducer,
  specialtyReducer,
  feedbackReducer,
  appointmentReducer,
  patientReducer,
  bookAppointmentReducer,
  inviteEmployeeReducer,
  inviteClinicReducer,
  inviteUserReducer,
  employeesReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT" && !state?.bookAppointmentReducer.breadcrumbs.length) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;