import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { ACTIONS } from "../../../Utils/constants";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import { MinusSVG, PlusSVG, TrashSVG } from "../../../Assets/Images/svg";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";
import { useTranslation } from "react-i18next";


export default function AddDrugToOrder() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { breadcrumbs, data } = useSelector(state => state.bookAppointmentReducer);
  
  const [query, setQuery] = useState("");
  const [countQuery, setCountQuery] = useState(1);
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  
  const inputRef = useRef(null);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_DRUGS,
          label: 'Drug Prescription'
        }
      ]
    });
    
    if (data?.drugs?.length) {
      setSelectedDrugs(data?.drugs)
    }
  }, [])
  
  const addToSelectedDrugs = (drug, count) => {
    setSelectedDrugs([...selectedDrugs,
      {
        description: drug,
        count
      }
    ])
  }
  
  const removeFromSelectedDrugs = (drug) => {
    setSelectedDrugs(selectedDrugs.filter(item => item.description !== drug))
  }
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <div className='breadcrumbs-item' key={item + i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='drugs' type='drug-order'/>
            <div className='list'>
              <div className='list-heading'>
                <p>{i18n.t("Add item")}</p>
                <div className='list-subtitle'>
                  {i18n.t("Only medication that has been prescribed can be ordered")}
                </div>
              </div>
              <div className="search drugs-search">
                <div className="drugs-search-input-wrap">
                  <input className="search-input drugs-search-input"
                         ref={inputRef}
                         placeholder={i18n.t("Type drug name")}
                         maxLength="30"
                         onChange={(event => {
                           setQuery(event.target.value.trim())
                         })}
                  />
                </div>
                <div className="drugs-search-counter">
                  <button className="drugs-search-btn btn scale-btn"
                          disabled={!query}
                          onClick={() => setCountQuery(countQuery !== 1 ? countQuery - 1 : 1)}
                  >
                    {MinusSVG}</button>
                  <div>{countQuery}</div>
                  <button className="drugs-search-btn btn scale-btn"
                          disabled={!query}
                          onClick={() => setCountQuery(countQuery + 1)}
                  >
                    {PlusSVG}
                  </button>
                </div>
              </div>
              <button className='for-patients-add-btn btn scale-btn' disabled={!query}
                      onClick={(event) => {
                        event.preventDefault();
                        if (!selectedDrugs.some(obj => obj.description === query)) {
                          addToSelectedDrugs(query, countQuery)
                        }
                        setQuery('');
                        setCountQuery(1);
                        inputRef.current.value = "";
                      }}
              >
                {i18n.t("Add")}
              </button>
              {selectedDrugs.map((drug, i) =>
                <div className='list-drug' key={drug.description + i}>
                  <p>{drug.description} - {drug.count} pack(s)</p>
                  <div className="btn scale-btn" onClick={() => removeFromSelectedDrugs(drug.description)}>
                    {TrashSVG}
                  </div>
                </div>
              )}
              {!!selectedDrugs.length &&
              <button className='for-patients-btn btn scale-btn'
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                          payload: {
                            drugs: selectedDrugs,
                            isRegistered: null,
                          }
                        });
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                          payload: ForPatientComponentKeys.PATIENT_DETAILS
                        });
                      }}
              >
                {i18n.t("Confirm")}
              </button>
              }
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
