import "../../Assets/Styles/ManageProfile.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import Loading from "../../Layouts/Loading/Loading";
import { EditDoctorSchema, EditUserSchema } from "../../Utils/ValidationSchemas";
import { editPersonalInfo, getUserInfo, updateUserInfo, updateUserPhoto } from "../../Store/actions/userActions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import {
  capitalizeFirstLetter,
  countDateForDatePicker,
  PermissionsArray,
  RoleArray,
  theme
} from "../../Utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { BlockedToEditImg, EmptyUserProfileImgLight } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";


export default function MyProfile() {
  const { i18n } = useTranslation();
  
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  
  const {
    firstName,
    lastName,
    location,
    telephone,
    email,
    birth_date,
    photo_url,
    role,
    permissions,
    uuid,
    clinic_id_clinics_clinicEmployees,
    doctor,
    id,
  } = useSelector(state => state.userReducer.user);
  const specialtiesList = useSelector(state => state.specialtyReducer.items);
  
  const primarySpecialty = doctor?.specialty_id_specialties[0];
  const secondarySpecialty = doctor?.specialty_id_specialties[1];
  
  const [birthDate, setBirthDate] = useState(birth_date);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedSpecId, setSelectedSpecId] = useState(primarySpecialty?.id);
  const [smallSpecialtiesList, setSmallSpecialtiesList] = useState(specialtiesList.filter((item) => item.id !== primarySpecialty?.id));
  const [selectedSpecId2, setSelectedSpecId2] = useState(secondarySpecialty?.id);
  const [smallSpecialtiesList2, setSmallSpecialtiesList2] = useState(specialtiesList.filter((item) => item.id !== secondarySpecialty?.id));
  
  useEffect(() => {
    setSelectedSpecId(primarySpecialty?.id);
    setSelectedSpecId2(secondarySpecialty?.id);
  }, [primarySpecialty, secondarySpecialty]);
  
  useEffect(() => {
    setSmallSpecialtiesList(specialtiesList.filter((item) => item.id !== selectedSpecId));
    setSmallSpecialtiesList2(specialtiesList.filter((item) => item.id !== selectedSpecId2));
  }, [selectedSpecId, primarySpecialty, secondarySpecialty, specialtiesList, selectedSpecId2]);
  
  useEffect(() => {
    setBirthDate(birth_date);
  }, [birth_date]);
  
  useEffect(() => {
    if (id) {
      dispatch(getUserInfo(uuid, i18n, role));
    }
  }, []);
  
  const forgotPw = () => {
    navigate("/reset/password");
  };
  
  if (!firstName) {
    return <Loading/>;
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect">
          <h1><Trans>My Profile</Trans></h1>
          <div className="manage-img">
            <div className="circle-crop-100">
              <img alt="img" src={photo_url || EmptyUserProfileImgLight}/>
            </div>
            {photo_url &&
              <label className="manage-delete_file" onClick={() => dispatch(updateUserPhoto('', i18n, uuid))}/>}
            <label htmlFor="files" className="manage-upload_file"/>
            <input
              name="attachment"
              type="file"
              id="files"
              accept="image/*"
              hidden
              onChange={(e) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = () => {
                  dispatch(updateUserPhoto(uuid, reader.result, i18n));
                };
              }}
            />
          </div>
          <div className="manage-form">
            <Formik
              initialValues={{
                firstName: firstName,
                lastName: lastName,
                primarySpecialty: primarySpecialty?.id,
                secondarySpecialty: secondarySpecialty?.id || "-1",
                clinic: !!clinic_id_clinics_clinicEmployees?.length && clinic_id_clinics_clinicEmployees[0]?.name,
                location: location ?? '',
                telephone: telephone ?? '',
                email: email,
                lanr: doctor?.lanr
              }}
              validationSchema={permissions?.some(obj => obj.id === PermissionsArray.DOCTOR) ?
                EditDoctorSchema
                :
                EditUserSchema
              }
              onSubmit={(values) => {
                if (role === RoleArray.EMPLOYEE) {
                  dispatch(editPersonalInfo(uuid, { ...values, birthDate }, i18n));
                } else {
                  dispatch(updateUserInfo(uuid, { ...values, birthDate }, i18n));
                }
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form className="manage-form-col">
                  <h2>{`${firstName} ${lastName}`}</h2>
                  <div className="input-container">
                    <label><Trans>First Name</Trans></label>
                    <Field
                      type="text"
                      name="firstName"
                      maxLength="25" required
                      onChange={(event) => {
                        handleChange(event);
                        setIsChanged(event.target.value !== firstName);
                      }}
                    />
                    <ValidationError errors={errors.firstName} touched={touched.firstName}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Last Name</Trans></label>
                    <Field
                      type="text"
                      name="lastName"
                      maxLength="25" required
                      onChange={(event) => {
                        handleChange(event);
                        setIsChanged(event.target.value !== lastName);
                      }}
                    />
                    <ValidationError errors={errors.lastName} touched={touched.lastName}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Birth date</Trans></label>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          className="date-picker light-date-picker"
                          views={['year', 'month', 'day']}
                          inputFormat="DD.MM.YYYY"
                          openTo={"year"}
                          maxDate={countDateForDatePicker(18)}
                          disableFuture
                          sx={{
                            width: '100%',
                            '& .MuiInputBase-input': {
                              padding: '12px 0 !important',
                              height: 42,
                            },
                          }}
                          value={birthDate || birth_date}
                          onChange={(value) => {
                            setBirthDate(new Date(value));
                            setIsChanged(true);
                          }}
                          renderInput={(params) =>
                            <TextField {...params} helperText={null}/>
                          }
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                    {
                      !birthDate &&
                      <span className="error">
                        <div>{i18n.t(`please_enter_your_birth_date`)}</div>
                      </span>
                    }
                  </div>
                  {(role === RoleArray.EMPLOYEE && permissions?.some(obj => obj.id === PermissionsArray.DOCTOR)) &&
                    <>
                      <div className="input-container">
                        <label><Trans>Primary Specialty</Trans></label>
                        <div className="select-input">
                          <Field as="select" name="primarySpecialty"
                                 onChange={(e) => {
                                   handleChange(e);
                                   setSelectedSpecId(Number(e.target.value));
                                   setIsChanged(Number(e.target.value) !== Number(primarySpecialty?.id));
                                 }}
                          >
                            {!primarySpecialty &&
                              <option value=""/>
                            }
                            {smallSpecialtiesList2.map(item =>
                              <option
                                value={item.id}
                                key={`${item.id}${item.description}`}
                              >
                                {capitalizeFirstLetter(i18n.t(`${item.description}`))}
                              </option>
                            )}
                          </Field>
                        </div>
                        <ValidationError errors={errors.primarySpecialty}
                                         touched={primarySpecialty?.id ? true : touched.primarySpecialty}
                        />
                      </div>
                      <div className="input-container">
                        <label><Trans>Secondary Specialty</Trans></label>
                        <div className="select-input">
                          <Field as="select" name="secondarySpecialty"
                                 onChange={(e) => {
                                   handleChange(e);
                                   setSelectedSpecId2(Number(e.target.value));
                                   setIsChanged(Number(e.target.value) !== Number(secondarySpecialty?.id));
                                 }}>
                            {!secondarySpecialty &&
                              <option value=""/>
                            }
                            {smallSpecialtiesList.map(item =>
                              <option
                                value={item.id}
                                key={`${item.id}${item.description}`}
                              >
                                {capitalizeFirstLetter(i18n.t(`${item.description}`))}
                              </option>
                            )}
                          </Field>
                        </div>
                        <ValidationError errors={errors.secondarySpecialty}
                                         touched={errors.secondarySpecialty}/>
                      </div>
                      <div className="input-container">
                        <label><Trans>Clinic</Trans></label>
                        <Field name="clinic" disabled/>
                        <img alt="show_pw"
                             src={BlockedToEditImg}
                             className="input-end-img"
                             onChange={(value) => {
                               handleChange(value);
                               setIsChanged(true);
                             }}
                        />
                        <ValidationError errors={errors.clinic} touched={errors.clinic}/>
                      </div>
                      <div className="input-container">
                        <label><Trans>LANR</Trans></label>
                        <Field
                          type="text"
                          name="lanr"
                          maxLength="40" required
                          onChange={(event) => {
                            handleChange(event);
                            setIsChanged(event.target.value !== doctor?.lanr);
                          }}
                        />
                        <ValidationError errors={errors.lanr} touched={errors.lanr}/>
                      </div>
                    </>
                  }
                  <div className="input-container">
                    <label><Trans>Address</Trans></label>
                    <Field
                      type="text"
                      name="location"
                      maxLength="250" required
                      onChange={(event) => {
                        handleChange(event);
                        setIsChanged(event.target.value !== location ?? '');
                      }}
                    />
                    <ValidationError errors={errors.location} touched={errors.location}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Telephone</Trans></label>
                    <Field
                      type="tel"
                      name="telephone"
                      maxLength="20" required
                      onChange={(event) => {
                        const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                        handleChange(event);
                        handleChange({ target: { name: 'telephone', value: cleanedValue } });
                        setIsChanged(event.target.value !== telephone);
                      }}
                    />
                    <ValidationError errors={errors.telephone} touched={errors.telephone}/>
                  </div>
                  <div className="input-container">
                    <label><Trans>Email</Trans></label>
                    <Field
                      type="email"
                      name="email"
                      maxLength="40" required disabled
                    />
                    <img alt="show_pw"
                         src={BlockedToEditImg}
                         className="input-end-img"
                    />
                    <ValidationError errors={errors.email} touched={errors.email}/>
                  </div>
                  <p className="link" onClick={forgotPw}><Trans>Forgot password</Trans></p>
                  <div className="button-container manage-btn">
                    <input type="submit"
                           disabled={!isChanged}
                           value={i18n.t("Save Changes")}
                           className="submit-form-button scale-btn"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </main>
    </>
  );
}
