import { ACTIONS, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";

const initialState = {
  isReady: false,
  items: [],
  nextItems: [],
  filteredItems: [],
  nextFilteredItems: [],
  patientGeneralInfo: null,
  patientAdditionalInfo: null,
  doc2DocTransfers: [],
  patientDoc2DocTransfers: [],
  patientCaretakers: [],
  page: 0,
  loadNext: true
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATIENTS.SET_PATIENTS: {
      if (action.payload.length === LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          items: state.items.concat(state.nextItems),
          filteredItems: action.payload,
          isReady: true,
          loadNext: true,
          page: state.page + 1
        };
      }
      return {
        ...state,
        items: state.items.concat(state.nextItems, action.payload),
        filteredItems: state.filteredItems.concat(action.payload),
        isReady: true,
        loadNext: false
      };
    }
    case ACTIONS.PATIENTS.SET_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_FILTERED_PATIENTS_ARRAY: {
      if (action.payload.length === LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          filteredItems: state.filteredItems.concat(state.nextFilteredItems),
          nextFilteredItems: action.payload,
          isReady: true,
          loadNext: true,
          page: state.page + 1
        };
      }
      return {
        ...state,
        filteredItems: state.filteredItems.concat(state.nextFilteredItems, action.payload),
        nextFilteredItems: [],
        isReady: true,
        loadNext: false
      };
    }
    case ACTIONS.PATIENTS.CLEAR_FILTERED_ARRAY: {
      return {
        ...state,
        isReady: false,
        filteredItems: [],
        items: [],
        nextItems: [],
        nextFilteredItems: [],
        page: 0,
        loadNext: true
      };
    }
    case ACTIONS.PATIENTS.FILTER_FILTERED_ARRAY: {
      return {
        ...state,
        filteredItems: state.filteredItems.slice(0,15),
        items: [],
        nextItems: [],
        nextFilteredItems: [],
        page: 1,
        isReady: true,
        loadNext: true
      };
    }
    case ACTIONS.PATIENTS.SET_OLD_ITEMS: {
      return {
        ...state,
        isReady: true,
        filteredItems: state.items,
        page: Math.ceil(state.items.length / LIMIT_ITEM_PER_PAGE) + 1,
        loadNext: state.items.length % LIMIT_ITEM_PER_PAGE === 0
      };
    }
    case ACTIONS.PATIENTS.SET_READY_PATIENTS: {
      return {
        ...state,
        isReady: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_PATIENT_GENERAL_INFO: {
      return {
        ...state,
        patientGeneralInfo: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_PATIENT_ADDITIONAL_INFO: {
      return {
        ...state,
        patientAdditionalInfo: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_DOC2DOC_TRANSFER: {
      return {
        ...state,
        doc2DocTransfers: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_PATIENT_DOC2DOC_TRANSFER: {
      return {
        ...state,
        patientDoc2DocTransfers: action.payload
      };
    }
    case ACTIONS.PATIENTS.SET_PATIENT_CARETAKERS: {
      return {
        ...state,
        patientCaretakers: action.payload.caregiverToUsers
      };
    }
    default:
      return state;
  }
};

export default patientReducer;