import "../../Assets/Styles/ManageProfile.css";
import "../../Assets/Styles/ManageOffice.css";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Layouts/Loading/Loading";
import React, { useEffect, Fragment } from "react";
import { editEmployeeStatus, getEmployee } from "../../Store/actions/employeeActions";
import { EmptyUserProfileImgLight } from "../../Assets/Images/img";
import { RoleArray, StatusArray } from "../../Utils/constants";

export default function ManageEmployeeApprove() {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { employee } = useSelector(state => state.employeesReducer);
  const user = useSelector(state => state.userReducer.user);
  
  useEffect(() => {
    dispatch(getEmployee(id, i18n))
  }, [id])
  
  if (!employee?.firstName || id !== employee?.uuid) {
    return <Loading/>;
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect">
          <div className='clinic-heading'>{i18n.t("New Employee")}</div>
          <div className='clinic-subtitle'>{i18n.t("New employee is waiting for account confirmation")}</div>
          <div className="manage-clinic">
            <div className="manage-img">
              <div className="circle-crop-100">
                <img alt='avatar'
                     src={employee?.photo_url || EmptyUserProfileImgLight}
                />
              </div>
            </div>
          </div>
          <div className="manage-form">
            <form className="manage-form-col">
              <div className="input-container">
                <label><Trans>First Name</Trans></label>
                <input
                  type="text"
                  name="firstName" value={employee?.firstName}
                  maxLength="50" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Last Name</Trans></label>
                <input
                  type="text"
                  name="lastName" value={employee?.lastName}
                  maxLength="30" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Telephone</Trans></label>
                <input
                  type="tel"
                  name="telephone" value={employee?.telephone}
                  maxLength="20" disabled
                />
              </div>
              <div className="input-container">
                <label><Trans>Email</Trans></label>
                <input
                  type="email"
                  name="email" value={employee?.email}
                  maxLength="40" disabled
                />
              </div>
              {employee?.account_status === StatusArray.PENDING && user.role === RoleArray.EMPLOYEE &&
                <Fragment>
                  <div className="select-btn clinic-btn btn scale-btn"
                       onClick={() => {
                         dispatch(editEmployeeStatus(employee?.id, StatusArray.APPROVED, navigate, i18n))
                       }}
                  >
                    {i18n.t("Approve")}
                  </div>
                  <div className="warning-btn clinic-btn btn scale-btn"
                       onClick={() => {
                         dispatch(editEmployeeStatus(employee?.id, StatusArray.CANCELED, navigate, i18n))
                       }}
                  >
                    {i18n.t("Reject")}
                  </div>
                </Fragment>
              }
            </form>
          </div>
        </section>
      </main>
    </>
  );
}
