import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/PatientInfo.css";
import "dayjs/locale/de";
import { Trans, useTranslation } from "react-i18next";
import { StatusInfo, timeBlocks } from "../../Utils/constants";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import React from "react";


export default function PatientInfoAppointments({ appointmentsList }) {
  const { i18n } = useTranslation();
  
  const {
    appointmentsCount,
    ordersCount,
    transfersCount
  } = useSelector(state => state.patientReducer?.patientAdditionalInfo);
  const totalRequests = appointmentsCount + ordersCount + transfersCount;
  
  return (
    <div className="manage-patient-appointments">
      <div className="patient-info-counter">
        <div className="patient-info-counter-headings">
          <div><Trans>Total Requests</Trans></div>
          <div><Trans>Appointments</Trans></div>
          <div><Trans>Medication</Trans></div>
          <div><Trans>Transfer</Trans></div>
        </div>
        <div className="patient-info-counter-numbers">
          <div>{totalRequests}</div>
          <div>{appointmentsCount}</div>
          <div>{ordersCount}</div>
          <div>{transfersCount}</div>
        </div>
      </div>
      <div className="manage-patient-table">
        <div className="table-heading">
          <div className="table-col">
            <Trans>Complete Actions</Trans>
          </div>
          <div className="table-col">
            <Trans>Doctor</Trans>
          </div>
          <div className="table-col">
            <Trans>Appointment Type</Trans>
          </div>
          <div className="table-col">
            <Trans>Details</Trans>
          </div>
          <div className="table-col table-col-notice">
            <Trans>Notice</Trans>
          </div>
          <div className="table-col">
            <Trans>Date</Trans>
          </div>
          <div className="table-col table-col-status">
            <Trans>Status</Trans>
          </div>
        </div>
        {appointmentsList.map((item) =>
          <div className='card' key={`${item?.type}${item.id}`}>
            <div className='card-header'>
              <div className="table-col">{`${i18n.t(item?.type)} #${item?.id}`}</div>
              <div className="table-col">{item?.doctor?.publicName}</div>
              <div className="table-col">
                {item?.type === "appointment" && i18n.t(item?.appointment_type.description)}
              </div>
              <div className="table-col">
                {item?.drugToOrders?.map(value =>
                  <div key={`${value?.drug.description}: ${value?.number} #${item?.id}`}>
                    {`${value?.drug.description}: ${value?.number}`}
                  </div>
                )}
              </div>
              <div className="table-col table-col-notice">
                {!item?.notice || item?.notice === "" ?
                  i18n.t("Without notice")
                  :
                  item?.notice
                }
              </div>
              <div className="table-col">
                {item?.status_id !== 3 ?
                  <>
                    <div>{dayjs(new Date(item?.date)).format("DD.MM.YYYY")}</div>
                    <div>{dayjs(new Date(item?.date)).format("HH:mm")}</div>
                  </>
                  :
                  <>
                    <div>{dayjs(new Date(item?.date)).format("DD.MM.YYYY")}</div>
                    <div>
                      {i18n.t(timeBlocks[item?.day_block_id].heading)}
                    </div>
                  </>
                }
              </div>
              <div className="table-col table-col-status">
                <StatusInfo statusId={item?.status_id}/>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
