import "../../../Assets/Styles/ForPatients.css";
import { meplanoSVG } from "../../../Assets/Images/svg";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "../../../Utils/constants";
import React, { useEffect, useState } from "react";
import { BreadcrumbsListPatientAccountType } from "../../../Utils/ConstantsForPatient";
import { useTranslation } from "react-i18next";
import { LogoHeartImg } from "../../../Assets/Images/img";


export default function ChooseAuthType() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  
  const [authType, setAuthType] = useState('');
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: BreadcrumbsListPatientAccountType(data?.appointment)
    });
  }, []);
  
  const makeTypeActive = (event) => {
    const elements = document.getElementsByClassName("active-type");
    for (let el of elements) {
      el.classList.remove("active-type")
    }
    event.currentTarget.classList.add("active-type");
  }
  
  return (
    <>
      <div className='list'>
        <div className='list-heading'>{i18n.t("Choose Type of Account")}</div>
        <div className="list-item list-auth-item"
             onClick={(event) => {
               makeTypeActive(event);
               setAuthType('login')
             }}
        >
          {i18n.t("I already have a")}
          <img alt='logo' src={LogoHeartImg}/>
          <span>{meplanoSVG}</span>
          {i18n.t("account")}
        </div>
        <div className="list-item"
             onClick={(event) => {
               makeTypeActive(event);
               setAuthType('signUp');
             }}
        >
          {i18n.t("Guest account")}
        </div>
        <button className='for-patients-btn btn scale-btn' disabled={!document.getElementsByClassName("active-type").length}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch({
                    type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                    payload: {
                      isRegistered: authType
                    }
                  });
                }}
        >
          {i18n.t("Continue")}
        </button>
      </div>
    </>
  );
}
