import "../../../Assets/Styles/ForPatients.css";
import { PatientsSignUpSchema } from "../../../Utils/ValidationSchemas";
import { Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import {
  ACTIONS,
  countDateForDatePicker,
  isPreProd,
  isProd,
  privacyPolicyLink,
  RoleArray,
  termsOfUseLink,
  theme
} from "../../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsListPatientDetails, ConfirmationComponent } from "../../../Utils/ConstantsForPatient";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ValidationError from "../../../Components/ValidationError";
import { sendOTP } from "../../../Store/actions/userActions";
import { ARROWS } from "../../../Assets/Images/svg";


export default function PatientsSignUp() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  console.log("step 6");
  const { data } = useSelector(state => state.bookAppointmentReducer);
  const { user } = useSelector(state => state.inviteUserReducer);
  
  const formRef = useRef();
  
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: BreadcrumbsListPatientDetails(data?.appointment)
    });
  }, []);
  
  return (
    <>
      <div className="list">
        <div className="list-heading">{i18n.t("Patient Details")}</div>
        <Formik
          innerRef={formRef}
          initialValues={{
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            telephone: user?.telephone ?? "",
            email: user?.email ?? "",
            birthDate: user?.birthDate ? new Date(user?.birthDate) : countDateForDatePicker(20)
          }}
          validationSchema={PatientsSignUpSchema}
          onSubmit={values => {
            dispatch({
              type: ACTIONS.INVITE_USER.SET_INVITE_USER,
              payload: {
                ...values,
                role: RoleArray.PATIENT
              }
            });
            if (data?.isVerified) {
              ConfirmationComponent(data?.appointment, dispatch)
            } else {
              dispatch({
                type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                payload: {
                  isRegistered: "verify"
                }
              });
              dispatch(sendOTP(values.email, captchaToken, "", i18n));
            }
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form className="employee-form">
              <div className="input-container">
                <label><Trans>First Name</Trans></label>
                <Field
                  type="text"
                  name="firstName"
                  maxLength="25" required
                />
                <ValidationError errors={errors.firstName} touched={touched.firstName}/>
              </div>
              <div className="input-container">
                <label><Trans>Last Name</Trans></label>
                <Field
                  type="text"
                  name="lastName"
                  maxLength="25" required
                />
                <ValidationError errors={errors.lastName} touched={touched.lastName}/>
              </div>
              <div className="input-container">
                <label><Trans>Birth date</Trans></label>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <ThemeProvider theme={theme}>
                    <DatePicker
                      className="date-picker"
                      openTo={"year"}
                      views={['year', 'month', 'day']}
                      inputFormat="DD.MM.YYYY"
                      maxDate={countDateForDatePicker(16)}
                      disableFuture
                      value={values.birthDate}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-input ': {
                          padding: '12px 0 !important',
                          height: 42,
                        },
                      }}
                      onChange={(value) => {
                        handleChange({
                          target: {
                            name: 'birthDate',
                            value: String(value.toDate())
                          }
                        });
                      }}
                      renderInput={(params) =>
                        <TextField {...params} helperText={null}/>
                      }
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              </div>
              <div className="input-container">
                <label><Trans>Email</Trans></label>
                <Field
                  type="email"
                  name="email"
                  maxLength="40" required
                  onChange={(event) => {
                    const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                    handleChange(event);
                    handleChange({ target: { name: 'email', value: cleanedValue } });
                  }}
                />
                <ValidationError errors={errors.email} touched={touched.email}/>
              </div>
              <div className="input-container">
                <label><Trans>Telephone</Trans></label>
                <Field
                  type="tel"
                  name="telephone"
                  maxLength="20" required disabled={user?.telephone}
                  onChange={(event) => {
                    const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                    handleChange(event);
                    handleChange({ target: { name: 'telephone', value: cleanedValue } });
                  }}
                />
                <ValidationError errors={errors.telephone} touched={touched.telephone}/>
              </div>
              <div className="list-checkboxes">
                <div className="input-container">
                  <label className="checkbox">
                    <input type="checkbox" name="checkmark" checked={checkTerms}
                           onChange={() => {
                             setCheckTerms(!checkTerms);
                           }}
                    />
                    <span className="checkmark"/>
                  </label>
                  <p className="checkbox-right-comment">
                    {i18n.t("I accept and agree to")}
                    <a target="_blank" href={termsOfUseLink} className='btn scale-btn terms-link'
                    >
                      {i18n.t("Terms of use")}
                    </a>
                  </p>
                </div>
                <div className="input-container">
                  <label className="checkbox">
                    <input type="checkbox" name="checkmark" checked={checkPrivacy}
                           onChange={() => {
                             setCheckPrivacy(!checkPrivacy);
                           }}
                    />
                    <span className="checkmark"/>
                  </label>
                  <p className="checkbox-right-comment">
                    {i18n.t("I accept and agree to")}
                    <a target="_blank" href={privacyPolicyLink} className='btn scale-btn terms-link'
                    >
                      {i18n.t("Privacy Policy 2")}
                    </a>
                  </p>
                </div>
              </div>
              {(isProd || isPreProd) && (
                <>
                  <div className="captcha-margin"/>
                  <HCaptcha
                    sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                    onVerify={(token) => setCaptchaToken(token)}
                  />
                </>
              )}
              <div className="flexbox">
                <div className="arrow-container">
                  <div className="image-container" onClick={() => dispatch({
                    type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                    payload: {
                      isRegistered: null
                    }
                  })}>
                    {ARROWS.LEFT}
                    <span className="tooltip-text left">Back</span>
                  </div>
                </div>
                <button className="for-patients-btn btn scale-btn" type="submit"
                        disabled={!checkTerms || !checkPrivacy}
                >
                  {i18n.t("Confirm")}
                </button>
              </div>
            
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
