import './index.css';
import './Utils/i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';
import createStore from './Store/store';

const store = createStore;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <App/>
    </Provider>
  </>
);
