import "../../Assets/Styles/ManagePatient.css";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import Header from "../../Layouts/Header/Header";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import Loading from "../../Layouts/Loading/Loading";
import { getPatientsList } from "../../Store/actions/patientActions";
import PatientsSearchFilter from "../../Components/PatientsSearchFilter";
import { ACTIONS } from "../../Utils/constants";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import PaginationButton from "../../Components/PaginationButton";
import dayjs from "dayjs";

export default function AllPatients() {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  const { filteredItems, page, loadNext, isReady } = useSelector(state => state.patientReducer);
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  
  useEffect(() => {
    if (!!filteredItems.length) {
      dispatch({
        type: ACTIONS.PATIENTS.FILTER_FILTERED_ARRAY
      });
    }
  }, [])
  
  useEffect(() => {
    if (!filteredItems.length) {
      dispatch(getPatientsList(name, birthDate, phone, 0, i18n));
    }
  }, []);
  
  const handleNextPatients = useCallback(() => {
    dispatch(getPatientsList(name, birthDate, phone, page, i18n));
  }, [name, birthDate, phone, page]);
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading patients-heading">
            <PatientsSearchFilter
              name={name} setName={setName}
              phone={phone} setPhone={setPhone}
              birthDate={birthDate} setBirthDate={setBirthDate}
            />
          </div>
          <div className="manage-patient-table">
            <div
              className="table-heading"
              style={!filteredItems.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
            >
              <div className="table-col-user">
                <Trans>Patient</Trans>
              </div>
              <div className="table-col">
                <Trans>Date of Birth</Trans>
              </div>
              <div className="table-col">
                <Trans>Phone</Trans>
              </div>
              <div className="table-col"><Trans>Last Quarter</Trans></div>
              <div className="table-col"><Trans>This Quarter</Trans></div>
            </div>
            {isReady ?
              !filteredItems.length ?
                <SearchNoFound/>
                :
                filteredItems.map((patient, i) =>
                  <div className='card' key={`doctorCard ${i}`}>
                    <div className='card-header'>
                    <Link to={`/patients/${patient?.uuid}`} className="table-link">
                        <div className="table-col-user">
                          <img alt="img"
                               src={patient?.photo_url || EmptyUserProfileImgForTable(i)}
                          />
                          <div className="table-col ml-10">
                            <div>{`${patient?.firstName} ${patient?.lastName}`}</div>
                          </div>
                        </div>
                      </Link>
                      <div className="table-col">
                        {dayjs(new Date(patient?.birth_date)).format("DD.MM.YYYY")}
                      </div>
                      <div className="table-col">{patient?.telephone}</div>
                      <div className="table-col">{patient?.prev_q}</div>
                      <div className="table-col">{patient?.this_q}</div>
                    </div>
                  </div>
                )
              :
              <Loading/>
            }
          </div>
          
          {(!!filteredItems.length && loadNext) &&
          <PaginationButton handleNext={handleNextPatients}/>
          }
        </section>
      </main>
    </>
  );
}
