import { Trans } from "react-i18next";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import React from "react";
import { useSelector } from "react-redux";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";


export const PatientsInfoRelatedAccounts = () => {
  const { patientReducer: { patientCaretakers } } = useSelector((state) => state);
  return (
    <div className="manage-patient-table">
      <div className="table-heading"
           style={patientCaretakers.length ?
             { borderBottom: "none" }
             :
             { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" }}
      >
        <div className="table-col-user column-270">
          <Trans>Name</Trans>
        </div>
        <div className="table-col">
          <Trans>Caretaker</Trans>
        </div>
        <div className="table-col">
          <Trans>Date Of Birth</Trans>
        </div>
        <div className="table-col">
          <Trans>Notice</Trans>
        </div>
        <div className="table-col"/>
      </div>
      
      {!patientCaretakers.length ?
        <SearchNoFound/>
        :
        patientCaretakers.map((person, index) => (
          <div className='card' key={person.user_id}>
            <div className='card-header'>
              <div className="table-col-user column-270"
                   style={{ cursor: "auto" }}
                   onClick={() => {
                   }}
              >
                <img alt='avatar'
                     src={person?.user.photo_url || EmptyUserProfileImgForTable(index % 2)}
                />
                <div className="table-col ml-10">
                  <div>{person?.user.firstName} {person?.user.lastName}</div>
                </div>
              </div>
              <div className="table-col">
                <div>{person.caretaker_role}</div>
              </div>
              <div className="table-col">
                <div>{person?.user.birth_date}</div>
              </div>
              <div className="table-col">
                <div>{person.details}</div>
              </div>
              <div className="table-col"/>
            </div>
          </div>
        ))}
    </div>
  )
}
