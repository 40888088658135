import {ACTIONS, LIMIT_ITEM_PER_PAGE} from "../../Utils/constants";

const initialState = {
    isReady: false,
    items: [],
    nextItems: [],
    filteredItems: [],
    nextFilteredItems: [],
    appointmentTypesList: [],
    clinicAppointmentTypesList: [],
    clinic: null,
    page: 0,
    loadNext: true
};

const clinicReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CLINIC.SET_CLINICS: {
            if (action.payload.length === LIMIT_ITEM_PER_PAGE) {
                return {
                    ...state,
                    items: state.items.concat(state.nextItems),
                    filteredItems: state.filteredItems.concat(action.payload),
                    nextItems: action.payload,
                    isReady: true,
                    loadNext: true,
                    page: state.page + 1
                };
            }
            return {
                ...state,
                items: state.items.concat(state.nextItems, action.payload),
                filteredItems: state.filteredItems.concat(action.payload),
                nextItems: [],
                isReady: true,
                loadNext: false
            };
        }
        case ACTIONS.CLINIC.SET_PAGE: {
            return {
                ...state,
                page: action.payload
            };
        }
        case ACTIONS.CLINIC.SET_FILTERED_ARRAY: {
            if (action.payload.length === LIMIT_ITEM_PER_PAGE) {
                return {
                    ...state,
                    filteredItems: state.filteredItems.concat(state.nextFilteredItems),
                    nextFilteredItems: action.payload,
                    isReady: true,
                    loadNext: true,
                    page: state.page + 1
                };
            }
            return {
                ...state,
                filteredItems: state.filteredItems.concat(state.nextFilteredItems, action.payload),
                nextFilteredItems: [],
                isReady: true,
                loadNext: false
            };
        }
        case ACTIONS.CLINIC.CLEAR_FILTERED_ARRAY: {
            return {
                ...state,
                isReady: false,
                filteredItems: [],
                nextFilteredItems: [],
                page: 0,
                loadNext: true
            };
        }
        case ACTIONS.CLINIC.FILTER_CLINIC_FILTERED_ARRAY: {
            return {
                ...state,
                isReady: true,
                filteredItems: state.items,
                nextFilteredItems: [],
                page: 0,
            };
        }
        case ACTIONS.CLINIC.SET_OLD_ITEMS: {
            return {
                ...state,
                isReady: true,
                filteredItems: state.items,
                page: Math.ceil(state.items.length / LIMIT_ITEM_PER_PAGE) + 1,
                loadNext: state.items.length % LIMIT_ITEM_PER_PAGE === 0
            };
        }
        case ACTIONS.CLINIC.SET_READY_CLINICS: {
            return {
                ...state,
                isReady: action.payload
            };
        }
        case ACTIONS.CLINIC.SET_APPOINTMENT_TYPES_LIST: {
            return {
                ...state,
                appointmentTypesList: action.payload,
            }
        }
        case ACTIONS.CLINIC.SET_CLINIC_APPOINTMENT_TYPES_LIST: {
            return {
                ...state,
                clinicAppointmentTypesList: action.payload,
            }
        }
        case ACTIONS.CLINIC.CLEAR_CLINIC_APPOINTMENT_TYPES_LIST: {
            return {
                ...state,
                clinicAppointmentTypesList: []
            }
        }
        case ACTIONS.CLINIC.ADD_TO_CLINIC_APPOINTMENT_TYPES_LIST: {
            return {
                ...state,
                clinicAppointmentTypesList: state.clinicAppointmentTypesList.concat(action.payload),
            }
        }
        case ACTIONS.CLINIC.REMOVE_FROM_CLINIC_APPOINTMENT_TYPES_LIST: {
            return {
                ...state,
                clinicAppointmentTypesList: state.clinicAppointmentTypesList.filter((el) => el.id !== action.payload.id),
            }
        }
        case ACTIONS.CLINIC.SET_CLINIC: {
            return {
                ...state,
                clinic: action.payload
            }
        }
        case ACTIONS.CLINIC.ADD_CLINIC_PHOTO: {
            return {
                ...state,
                clinic: {
                    ...state.clinic,
                    image_url: action.payload
                }
            }
        }
        default:
            return state;
    }
};

export default clinicReducer;