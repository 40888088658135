import "../../../Assets/Styles/ForPatients.css";
import React, { useEffect, useState } from "react";
import Header from "../../../Layouts/Header/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS, isProd } from "../../../Utils/constants";
import { getClinicById } from "../../../Store/actions/clinicActions";
import Loading from "../../../Layouts/Loading/Loading";
import { useTranslation } from "react-i18next";
import { getDoctorsListByClinic } from "../../../Store/actions/doctorActions";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";
import WorkingHoursAccordion from "../../../Components/WorkingHoursAccordion";
import { EmptyClinicProfileImg, EmptyUserProfileImgLight, HolidayInfoIco } from "../../../Assets/Images/img";
import dayjs from "dayjs";

//BEGIN of the Web for Patients flow
export default function ClinicsDoctors() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { uuid } = useParams();
  
  const { breadcrumbs } = useSelector(state => state.bookAppointmentReducer);
  
  const clinic = useSelector(state => state.clinicReducer.clinic);
  const doctors = useSelector(state => state.doctorReducer.doctorsGrouped);
  
  useEffect(() => {
    dispatch(getClinicById(uuid, i18n));
    dispatch(getDoctorsListByClinic(uuid, i18n));
  }, [uuid]);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        }
      ]
    });
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.CLEAR_DATA,
      payload: {}
    });
  }, []);
  
  useEffect(() => {
    dayjs().locale(isProd || !localStorage.getItem("i18n") ? "de" : localStorage.getItem("i18n"));
  }, [localStorage.getItem("i18n"), isProd]);
  
  if (!clinic || (uuid && uuid !== clinic?.uuid)) {
    return <Loading/>;
  }
  console.log("step 1");
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className="breadcrumbs">
            {breadcrumbs.map((item, i) =>
              <div className="breadcrumbs-item" key={item.label + i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className="for-patients-sect">
            <div className="info-sect">
              <div className="manage-img for-patients-img">
                <div className="circle-crop-145">
                  <img alt="avatar"
                       src={clinic?.image_url || EmptyClinicProfileImg}
                  />
                </div>
              </div>
              <div className="details">
                <p>{clinic?.name}</p>
                <p>{clinic.slogan}</p>
                <p>{clinic?.city}</p>
                <p>{clinic?.location}</p>
                {clinic?.telephone && <p>{i18n.t("Phone")}: {clinic?.telephone}</p>}
                {clinic?.website && <Link to={`https://${clinic?.website}`} target="_blank">{clinic?.website}</Link>}
              </div>
            </div>
            <WorkingHoursAccordion/>
            <div className="list">
              <div className="list-heading">{i18n.t("Choose Doctor")}</div>
              <div className={`list-block ${!clinic?.is_working ? "disabled-list" : ''}`}>
                {!clinic?.is_working &&
                  <div className="list-info-string">
                    <img alt="ico" src={HolidayInfoIco}/>
                    <p>{clinic?.working_status}</p>
                  </div>
                }
                {doctors.map((doctor, i) =>
                  <div
                    className={`list-link ${!doctor?.is_working && clinic?.is_working ? "disabled-list-link" : ''}`}
                    key={`doctor-${i}`}
                    onClick={() => {
                      if (doctor?.is_working && clinic?.is_working) {
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                          payload: {
                            appointmentDoctor: doctor
                          }
                        });
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                          payload: ForPatientComponentKeys.DOCTOR
                        });
                      }
                    }}
                  >
                    {!doctor?.is_working && clinic?.is_working ?
                      <div className="list-info-string">
                        <img alt="ico" src={HolidayInfoIco}/>
                        <p>{doctor?.working_status}</p>
                      </div>
                      :
                      null
                    }
                    <div className="table-col-user list-user">
                      <img alt="avatar"
                           src={doctor?.user.photo_url || EmptyUserProfileImgLight}
                      />
                      <div className="table-col ml-10">
                        <div>{`${doctor?.user?.firstName} ${doctor?.user?.lastName}`}</div>
                        <div>
                          {doctor?.specialtyToDoctors.map(item =>
                            <p key={`${item?.specialty?.id}-specialty`}>{i18n.t(item?.specialty?.description)}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
