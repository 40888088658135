import "../../Assets/Styles/Advertising.css";
import Header from "../../Layouts/Header/Header";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logOut } from "../../Store/actions/userActions";
import { Link } from "react-router-dom";
import { AppStoreIco, GooglePlayIco } from "../../Assets/Images/img";


export default function AdvertisingForRegistered() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { userEmail } = useSelector(state => state.bookAppointmentReducer?.data);
  
  useEffect(() => {
    dispatch(logOut());
  }, []);
  
  return (
    <div className="ads-wrap">
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className="ads-container">
            <div className="ads-info"
                 style={localStorage.getItem("i18n") === 'en' ? { width: "486px" } : { width: "493px" }}
            >
              <p className="ads-heading">
                {i18n.t("Your account is already registered on")} <br/><span>meplano</span> app.
              </p>
              <p className="ads-subtitle">
                {i18n.t("Email")} {userEmail} {i18n.t("is already registered. You can continue in the app.")}
              </p>
              <div className="ads-buttons">
                <Link to="https://www.apple.com/app-store/" target="_blank">
                  <img alt="btn" src={AppStoreIco}/>
                </Link>
                <Link to="https://play.google.com/store" target="_blank">
                  <img alt="btn" src={GooglePlayIco}/>
                </Link>
              </div>
            </div>
            <img alt="ads" src={i18n.t("ForPatientsAdsImg")}/>
          </div>
        </section>
      </main>
    </div>
  );
}
