import { InviteEmployeeWelcomePage } from "../Pages/InviteEmployee/InviteEmployeeWelcomePage";
import { InviteEmployee } from "../Pages/InviteEmployee/InviteEmployee";
import InviteEmployeeFailed from "../Pages/InviteEmployee/InviteEmployeeFailed";
import InviteEmployeePending from "../Pages/InviteEmployee/InviteEmployeePending";
import AdvertisingForClinic from "../Pages/Advertising/AdvertisingForClinic";
import InviteEmployeeInvalidCode from "../Pages/InviteEmployee/InviteEmployeeInvalidCode";


export const InviteEmployeeComponentKeys = {
  WELCOME_EMPLOYEE: 'WELCOME_EMPLOYEE',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  ADD_EMPLOYEE_VERIFY: 'ADD_EMPLOYEE_VERIFY',
  ADD_EMPLOYEE_FAILED: 'ADD_EMPLOYEE_FAILED',
  ADD_EMPLOYEE_PENDING: 'ADD_EMPLOYEE_PENDING',
  ADD_EMPLOYEE_INVALID_CODE: 'ADD_EMPLOYEE_INVALID_CODE',
  ADS: "ADS"
}

export const GetInviteEmployeeComponentByKey = (key) => {
  switch (key) {
    case InviteEmployeeComponentKeys.WELCOME_EMPLOYEE:
      return <InviteEmployeeWelcomePage/>;
    case InviteEmployeeComponentKeys.ADD_EMPLOYEE:
      return <InviteEmployee/>;
    case InviteEmployeeComponentKeys.ADD_EMPLOYEE_FAILED:
      return <InviteEmployeeFailed/>;
    case InviteEmployeeComponentKeys.ADD_EMPLOYEE_INVALID_CODE:
      return <InviteEmployeeInvalidCode/>;
    case InviteEmployeeComponentKeys.ADD_EMPLOYEE_PENDING:
      return <InviteEmployeePending/>;
    case InviteEmployeeComponentKeys.ADS:
      return <AdvertisingForClinic/>;
    
  }
}