import React from "react";
import { theme, updateTime } from "../../../../Utils/constants";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const TimePicker = ({ value, index, name, days, setDays }) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <TextField
          id="working-hours-time-picker"
          type="time"
          name={name}
          defaultValue={value}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300,
            style: {
              border: "none",
              outline: "none"
            },
          }}
          sx={{
            width: 120
          }}
          InputProps={{
            sx: {
              'fieldset': {
                border: '1px solid #47C0C1!important',
              },
            },
          }}
          onChange={(e) => updateTime(e, e.target.name, index, days, setDays)}
        />
      </ThemeProvider>
    </div>
  )
};
