import { Trans, useTranslation } from "react-i18next";
import { StatusInfo } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getDoc2DocTransfers } from "../../Store/actions/patientActions";
import React, { useEffect } from "react";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";

export const PatientsInfoDoctorTransfer = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { id } = useSelector((state) => state.patientReducer.patientGeneralInfo);
  const { patientDoc2DocTransfers, doc2DocTransfers } = useSelector(state => state.patientReducer);
  
  useEffect(() => {
    dispatch(getDoc2DocTransfers(id, i18n, doc2DocTransfers));
  }, [id]);
  
  return (
    <>
      <div className="manage-patient-table">
        <div className="table-heading"
             style={patientDoc2DocTransfers.length ?
               { borderBottom: "none" }
               :
               { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" }}
        >
          <div className="table-col">
            <Trans>Request</Trans>
          </div>
          <div className="table-col">
            <Trans>Appointment Type</Trans>
          </div>
          <div className="table-col">
            <Trans>Details</Trans>
          </div>
          <div className="table-col">
            <Trans>Notice</Trans>
          </div>
          <div className="table-col">
            <Trans>Date</Trans>
          </div>
          <div className="table-col table-col-status">
            <Trans>Status</Trans>
          </div>
        </div>
        {!patientDoc2DocTransfers.length ?
          <SearchNoFound/>
          :
          patientDoc2DocTransfers.map((item) =>
          <div className='card' key={item?.id}>
            <div className='card-header'>
              <div className="table-col">Doctor transfer #{item?.id}</div>
              <div className="table-col">Doc2Doc Transfer</div>
              <div className="table-col">
                {item?.doctor_notice}
              </div>
              <div className="table-col">
                {item?.notice}
              </div>
              <div className="table-col">
                {dayjs(item?.date).format("DD.MM.YYYY")}
              </div>
              <div className="table-col table-col-status">
                <StatusInfo statusId={item.status_id}/>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}