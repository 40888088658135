import "../../Assets/Styles/Login.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { ResetPasswordSchema } from "../../Utils/ValidationSchemas";
import { sendRecoverPwEmail } from "../../Store/actions/userActions";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { isProd } from "../../Utils/constants";
import { StartingLogo } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";

export default function ResetPassword() {
  const { i18n } = useTranslation();
  
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  
  const [captchaToken, setCaptchaToken] = useState(null);
  
  return (
    <main className="auth">
      <div className="starting-logo">
        <img alt="starting_logo"
             src={StartingLogo}
        />
      </div>
      <section className="auth-sect auth-login-sect">
        <h1 className="auth-heading">
          <Trans>Please enter your email</Trans>
        </h1>
        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={values => {
            dispatch(sendRecoverPwEmail(values.email, navigate, i18n))
          }}
        >
          {({ errors, touched, handleChange }) => (
            <Form autoComplete="off">
              <div className="input-container">
                <label>
                  <Trans>Email</Trans>
                </label>
                <Field type="text" name="email" maxLength="40" required
                       onChange={(event) => {
                         const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                         handleChange(event);
                         handleChange({ target: { name: 'email', value: cleanedValue } });
                       }}
                />
                <ValidationError errors={errors.email} touched={touched.email}/>
              </div>
              <div className="captcha-margin"/>
              <HCaptcha
                sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                onVerify={(token, ekey) => setCaptchaToken(token)}
              />
              <div className="button-container">
                <input type="submit"
                       disabled={!captchaToken}
                       value={i18n?.t("Recover")}
                       className="submit-form-button scale-btn"
                />
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </main>
  );
}
