import "../Assets/Styles/WorkingHours.css";
import { DayContainer } from "../Pages/InviteClinic/AddClinic/components/DayContainer";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { editClinicWorkingHours, getClinicById, reducer, setWorkingHours } from "../Store/actions/clinicActions";
import { useDispatch } from "react-redux";

export const EditWorkingHoursPopup = ({ clinic, show, handleClose }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [state, changeState] = useReducer(reducer, {
    days: {
      monday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.monday,
        time: clinic?.working_hours?.monday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.monday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      tuesday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.tuesday,
        time: clinic?.working_hours?.tuesday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.tuesday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      wednesday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.wednesday,
        time: clinic?.working_hours?.wednesday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.wednesday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      thursday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.thursday,
        time: clinic?.working_hours?.thursday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.thursday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      friday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.friday,
        time: clinic?.working_hours?.friday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.friday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      saturday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.saturday,
        time: clinic?.working_hours?.saturday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.saturday?.map(entry => entry.to)) || ["00:00", "23:59"]
      },
      sunday: {
        isShown: false,
        isChecked: !!clinic?.working_hours?.sunday,
        time: clinic?.working_hours?.sunday?.map(entry => entry.from)
          ?.concat(clinic?.working_hours?.sunday?.map(entry => entry.to)) || ["00:00", "23:59"]
      }
    }
  });
  
  return (
    <>
      <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
        <div className='popup-container working-hours-popup'>
          <div className='popup-clinic hours-popup'>
            <div className='hours-popup-heading'>{i18n.t("Edit Working Hours")}</div>
            <div className="clinic-date-box">
              <div className="clinic-date-container">
                <DayContainer days={state.days} day="Monday" name="monday"
                              setDays={changeState} isChecked={state.days.monday.isChecked}
                              hoursRangeLength={state.days.monday.time.length / 2}
                />
                <DayContainer days={state.days} day="Tuesday" name="tuesday"
                              setDays={changeState} isChecked={state.days.tuesday.isChecked}
                              hoursRangeLength={state.days.tuesday.time.length / 2}
                />
                <DayContainer days={state.days} day="Wednesday" name="wednesday"
                              setDays={changeState} isChecked={state.days.wednesday.isChecked}
                              hoursRangeLength={state.days.wednesday.time.length / 2}
                />
                <DayContainer days={state.days} day="Thursday" name="thursday"
                              setDays={changeState} isChecked={state.days.thursday.isChecked}
                              hoursRangeLength={state.days.thursday.time.length / 2}
                />
                <DayContainer days={state.days} day="Friday" name="friday"
                              setDays={changeState} isChecked={state.days.friday.isChecked}
                              hoursRangeLength={state.days.friday.time.length / 2}
                />
                <DayContainer days={state.days} day="Saturday" name="saturday"
                              setDays={changeState} isChecked={state.days.saturday.isChecked}
                              hoursRangeLength={state.days.saturday.time.length / 2}
                />
                <DayContainer days={state.days} day="Sunday" name="sunday"
                              setDays={changeState} isChecked={state.days.sunday.isChecked}
                              hoursRangeLength={state.days.sunday.time.length / 2}
                />
              </div>
            </div>
            <div className='hours-buttons'>
              <div className='hours-cancel-btn btn scale-btn' onClick={handleClose}>{i18n.t("Cancel")}</div>
              <div className='hours-save-btn'
                   onClick={() => {
                     let { objectWorkingHours } = setWorkingHours(state.days);
                     dispatch(editClinicWorkingHours(clinic?.id, {
                       ...objectWorkingHours
                     }, i18n));
                     setTimeout(() => {
                       dispatch(getClinicById(clinic?.uuid, i18n));
                       handleClose();
                     }, 150)
                   }}
              >
                {i18n.t("Save")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}