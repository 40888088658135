import "../../Assets/Styles/Login.css";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ChangePasswordSchema } from "../../Utils/ValidationSchemas";
import { updatePassword } from "../../Store/actions/userActions";
import { isProd } from "../../Utils/constants";
import { CrossedPasswordIco, PasswordIco, StartingLogo } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";

export default function NewPassword() {
  const { i18n } = useTranslation();
  
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  
  const [captchaToken, setCaptchaToken] = useState(null);
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  
  const toggleNewPassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };
  
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  
  if (token) {
    localStorage.setItem("accessToken", token);
  } else {
    return <Navigate to={'/login'} replace/>;
  }
  
  return (
    <main className="auth">
      <div className="starting-logo">
        <img alt="starting_logo"
             src={StartingLogo}
        />
      </div>
      <section className="auth-sect auth-login-sect">
        <h1 className="auth-heading">
          <Trans>Please enter your new password</Trans>
        </h1>
        <Formik
          initialValues={{
            newPassword: "",
            confirmPassword: ""
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={values => {
            dispatch(updatePassword(values.newPassword, captchaToken, navigate, i18n));
          }}
        >
          {({ errors, touched }) => (
            <Form autoComplete="off">
              <div className="input-container">
                <label>
                  <Trans>New Password</Trans>
                </label>
                <Field
                  type={newPasswordType}
                  name="newPassword"
                  maxLength="16" required
                />
                <img alt="show_pw"
                     src={newPasswordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className="input-end-img btn scale-btn" onClick={toggleNewPassword}
                />
                <ValidationError errors={errors.newPassword} touched={touched.newPassword}/>
              </div>
              <div className="input-container">
                <label>
                  <Trans>Confirm Password</Trans>
                </label>
                <Field
                  type={confirmPasswordType}
                  name="confirmPassword"
                  maxLength="16" required
                />
                <img alt="show_pw"
                     src={confirmPasswordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className="input-end-img btn scale-btn" onClick={toggleConfirmPassword}
                />
                <ValidationError errors={errors.confirmPassword} touched={touched.confirmPassword}/>
              </div>
              <div className="captcha-margin"/>
              <HCaptcha
                sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                onVerify={(token, ekey) => setCaptchaToken(token)}
              />
              <div className="button-container">
                <input type="submit"
                       disabled={!captchaToken}
                       value={i18n?.t("Reset Password")}
                       className="submit-form-button scale-btn"
                />
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </main>
  );
}
