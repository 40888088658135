import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AdditionalRoutesForAllPermissions,
  ClinicAdminRoutes,
  DoctorRoutes,
  GuestRoutes,
  ManagerRoutes,
  paths,
  PatientsRoutes,
  SuperAdminRoutes,
} from "./Utils/Routes";
import Loading from "./Layouts/Loading/Loading";
import { GetForPatientComponentByKey } from "./Utils/ConstantsForPatient";
import { GetInviteEmployeeComponentByKey } from "./Utils/ConctantsInviteEmployee";
import { GetInviteClinicComponentByKey } from "./Utils/ConstantsInviteClinic";
import { PageNotFound } from "./Layouts/PageNotFound/PageNotFound";
import { PermissionsArray, RoleArray } from "./Utils/constants";
import AllRequests from "./Pages/AllRequests/AllRequests";
import Feedback from "./Pages/Feedback/Feedback";


const AppRouter = () => {
  const { role, permissions } = useSelector((state) => state.userReducer.user);
  const { isReady } = useSelector((state) => state.userReducer);
  const ForPatientComponentKey = useSelector((state) => state.bookAppointmentReducer.component);
  const InviteEmployeeComponentKey = useSelector((state) => state.inviteEmployeeReducer.component);
  const InviteClinicComponentKey = useSelector((state) => state.inviteClinicReducer.component);
  
  const EmployeeHasOnePermission = (role, permissions) => {
    return role === RoleArray.EMPLOYEE && permissions.length === 1
  }
  
  if (!isReady) {
    return (
      <Loading/>
    );
  }
  
  return (
    <Routes>
      {/*Guest Routes*/}
      {GuestRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {/*Super Admin Routes*/}
      {role === RoleArray.SUPER_ADMIN && SuperAdminRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {/*Employee Routes if employee has only one permission*/}
      {(EmployeeHasOnePermission(role, permissions) && permissions?.some(obj => obj.id === PermissionsArray.DOCTOR)) &&
      DoctorRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {(EmployeeHasOnePermission(role, permissions) && permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER)) &&
      ManagerRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {(EmployeeHasOnePermission(role, permissions) && permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)) &&
      ClinicAdminRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {/*Employee has many permissions and is not a doctor*/}
      {(role === RoleArray.EMPLOYEE && !permissions?.some(obj => obj.id === PermissionsArray.DOCTOR)) &&
      <>
        <Route path={paths.main} element={<Navigate to={paths.allRequests} replace/>}/>
        {ClinicAdminRoutes.map(({ path, Component }) =>
          <Route path={path} element={Component} key={path}/>
        )}
        <Route path={paths.allRequests} element={<AllRequests/>}/>
      </>
      }
      {/*Employee has many permissions and is not a manager*/}
      {(role === RoleArray.EMPLOYEE && !permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER)) &&
      <>
        <Route path={paths.main} element={<Navigate to={paths.allRequests} replace/>}/>
        {ClinicAdminRoutes.map(({ path, Component }) =>
          <Route path={path} element={Component} key={path}/>
        )}
        <Route path={paths.allRequests} element={<AllRequests/>}/>
        <Route path={paths.submitFeedback} element={<Feedback/>}/>
      </>
      }
      {/*Employee has many permissions and is not a clinic admin*/}
      {(role === RoleArray.EMPLOYEE && !permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)) &&
      <>
        {ManagerRoutes.map(({ path, Component }) =>
          <Route path={path} element={Component} key={path}/>
        )}
        <Route path={paths.submitFeedback} element={<Feedback/>}/>
      </>
      }
      {/*Employee has all permissions*/}
      {(
        role === RoleArray.EMPLOYEE &&
        permissions?.some(obj => obj.id === PermissionsArray.DOCTOR) &&
        permissions?.some(obj => obj.id === PermissionsArray.REQUEST_MANAGER) &&
        permissions?.some(obj => obj.id === PermissionsArray.CLINIC_ADMIN)
      ) &&
      <>
        <Route path={paths.main} element={<Navigate to={paths.allRequests} replace/>}/>
        {ClinicAdminRoutes.map(({ path, Component }) =>
          <Route path={path} element={Component} key={path}/>
        )}
        {AdditionalRoutesForAllPermissions.map(({ path, Component }) =>
          <Route path={path} element={Component} key={path}/>
        )}
      </>
      }
      {/*Patient Routes*/}
      {role === RoleArray.PATIENT && PatientsRoutes.map(({ path, Component }) =>
        <Route path={path} element={Component} key={path}/>
      )}
      {/*Web For Patients Routes*/}
      <Route
        path={`${paths.clinics}/:uuid`}
        element={GetForPatientComponentByKey(ForPatientComponentKey)}
      />
      {/*Invite Clinic Routes*/}
      <Route path={`${paths.inviteEmployee}`}
             element={GetInviteEmployeeComponentByKey(InviteEmployeeComponentKey)}
      />
      {/*Invite Employee Routes*/}
      <Route path={`${paths.inviteClinic}`}
             element={GetInviteClinicComponentByKey(InviteClinicComponentKey)}
      />
      {/*Login*/}
      <Route path="/" element={<Navigate to={paths.login} replace/>}/>
      {/*404*/}
      <Route
        path="*"
        element={<PageNotFound/>}
      />
    </Routes>
  );
};

export default AppRouter;