import "../../Assets/Styles/ForPatients.css";
import '../../Assets/Styles/Breadcrumbs.css';
import React, { useEffect, useState } from "react";
import { hideEmail, isPreProd, isProd } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendOTP, verifyOtp } from "../../Store/actions/userActions";
import CountdownTimer from "../../Components/CountdownTimer";
import HCaptcha from "@hcaptcha/react-hcaptcha";


export default function UserVerify() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const [isAvailable, setIsAvailable] = useState(true);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [initialSeconds, setInitialSeconds] = useState(60);
  const [requestCaptchaToken, setRequestCaptchaToken] = useState('');
  
  const { user, captchaToken } = useSelector(state => state.inviteUserReducer);
  const { clinicInfo } = useSelector(state => state.inviteClinicReducer.data);
  const AppointmentData = useSelector(state => state.bookAppointmentReducer.data);
  
  useEffect(() => {
    if (requestCaptchaToken) {
      setIsAvailable(false);
      setInitialSeconds(60);
      dispatch(sendOTP(user.email, requestCaptchaToken, '', i18n));
    }
  }, [requestCaptchaToken]);
  
  const handleInputChange = (index, event) => {
    const inputValue = event.target.value;
    
    if (!Number.isInteger(Number(inputValue))) {
      setInputValues(['', '', '', '']);
      return;
    }
    
    if (inputValue.length === 1) {
      if (index < inputValues.length - 1) {
        const newInputValues = [...inputValues];
        newInputValues[index] = inputValue;
        setInputValues(newInputValues);
        document.getElementById(`input-${index + 1}`).focus();
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = inputValue;
        setInputValues(newInputValues);
      }
    } else {
      if (index < inputValues.length - 1) {
        const newInputValues = [...inputValues];
        let i = index;
        let inputIndex = 0;
        while (i < inputValues.length) {
          newInputValues[i] = inputValue.split('')[inputIndex];
          i++;
          inputIndex++;
        }
        setInputValues(newInputValues);
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = inputValue.split('')[0];
        setInputValues(newInputValues);
      }
    }
  };
  
  return (
    <>
      <div className="list">
        <div className="list-heading">
          <p>{i18n.t("Verify Request")}</p>
          <div className="list-subtitle">
            {`${i18n.t("Please type verification code sent to")} `}
            {hideEmail(user.email)}
          </div>
        </div>
        <div className="verify-items">
          {inputValues.map((value, index) => (
            <input
              key={index}
              type="text"
              min="0"
              max="9"
              step="1"
              id={`input-${index}`}
              value={value}
              onChange={(event) => handleInputChange(index, event)}
            />
          ))}
        </div>
        <div className="verify-subtitle"
        >
          {i18n.t("Didn't receive a code?")}
          <button disabled={(isProd || isPreProd) ? !(requestCaptchaToken && isAvailable) : !isAvailable}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCaptcha(true);
                  }}
          >
            {i18n.t("Request again")}
          </button>
          <CountdownTimer initialSeconds={initialSeconds} setInitialSeconds={setInitialSeconds}
                          setIsAvailable={setIsAvailable}
          />
        </div>
        {showCaptcha &&
          (
            <>
              <div className="captcha-margin"/>
              <HCaptcha
                sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                onVerify={(token) => {
                  setRequestCaptchaToken(token);
                  setIsAvailable(false);
                }}
              
              />
            </>
          )}
        <button className="for-patients-btn btn scale-btn"
                disabled={!inputValues.every(value => value !== '')}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(verifyOtp(captchaToken, inputValues.join(''), user, clinicInfo, AppointmentData, setIsAvailable, i18n));
                }}
        >
          {i18n.t("Confirm")}
        </button>
      </div>
    </>
  );
}
