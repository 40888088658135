import React, { useState } from "react";
import { ARROWS } from "../../../../Assets/Images/svg";
import { TimePicker } from "./TimePicker";
import { actionTimeTypes, addTime, checkDay, deleteTime, updateCheckedDay } from "../../../../Utils/constants";
import { useTranslation } from "react-i18next";

export const DayContainer = ({ day, days, name, setDays, isChecked, hoursRangeLength = 1 }) => {
  const { i18n } = useTranslation();
  const [fieldCount, setFieldCount] = useState(hoursRangeLength);
  
  return (
    <div className={!days[name].isShown ? "clinic-day-container" : "clinic-day-container-expanded"}>
      <div className="day-container-top">
        <div className="clinic-day-info">
          <label className="switch">
            <input type="checkbox" checked={isChecked}
                   onChange={() => updateCheckedDay(name, days, setDays)}
            />
            <span className="slider round"/>
          </label>
          <div className="clinic-day-title">{i18n.t(day)}</div>
          {!days[name].isShown &&
            <div className="clinic-day-hours-text">
              {days[name].time[0] + '-' + days[name].time[days[name].time.length - 1]}
            </div>
          }
        </div>
        <div className={days[name].isShown ? "clinic-arrow" : "clinic-arrow-rotate"}
             onClick={() => {
               setDays({ type: actionTimeTypes.CHANGE_IS_SHOWN, payload: name });
               checkDay(name, days, setDays);
             }}>
          {ARROWS.BOTTOM}
        </div>
      </div>
      {days[name].isShown && (
        <div className="clinic-time-picker-container">
          {Array(fieldCount).fill(0).map((el, index) => {
            return (
              <div className="time-picker-field" key={index}>
                <span className="">{i18n.t("from")}</span>
                <TimePicker value={days[name].time[index * 2] ? days[name].time[index * 2] : ''} index={index * 2}
                            name={name}
                            days={days} setDays={setDays}
                />
                <span className="">{i18n.t("to")}</span>
                <TimePicker value={days[name].time[index * 2 + 1] ? days[name].time[index * 2 + 1] : ''}
                            index={index * 2 + 1} name={name}
                            days={days} setDays={setDays}
                />
                {(index === 0) &&
                  <span className="clinic-plus"
                        onClick={() => addTime(fieldCount * 2 - 1, name, setDays, setFieldCount)}>+</span>}
                {(index > 0 && fieldCount > 1) &&
                  <span className="clinic-plus"
                        onClick={() => deleteTime(index * 2, name, setDays, setFieldCount)}>-</span>}
              </div>
            )
          })}
        </div>)}
    </div>
  )
};
