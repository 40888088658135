import "../Assets/Styles/ClinicEmployees.css";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { PermissionsArray, StatusArray } from "../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { editEmployeePermissions, editEmployeeStatus } from "../Store/actions/employeeActions";
import { useNavigate } from "react-router-dom";

export default function EmployeeDropdown({ item, isOpenDropdown, setIsOpenDropdown }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { id } = useSelector(state => state.userReducer.user);
  
  const [newPermissions, setNewPermissions] = useState(item?.permission_id_permissions.map(item => item.id));
  
  return (
    <div className="position-relative"
         style={isOpenDropdown === item?.id ? { display: "block" } : { display: "none" }}
    >
      <div className="item-dropdown employee-dropdown">
        <div className='employee-checkboxes white-checkboxes'>
          <div className="input-container checkbox-left-comment">
            <p>{i18n.t("Can work as a doctor")}</p>
            <label className="checkbox">
              <input type="checkbox" name="checkmark"
                     checked={newPermissions.includes(PermissionsArray.DOCTOR)}
                     onChange={(event) => {
                       if (event.target.checked) {
                         setNewPermissions([...newPermissions, PermissionsArray.DOCTOR])
                       } else {
                         setNewPermissions(newPermissions.filter(item => item !== PermissionsArray.DOCTOR))
                       }
                     }}
              />
              <span className="checkmark"/>
            </label>
          </div>
          <div className="input-container checkbox-left-comment">
            <p>{i18n.t("Can manage patient requests")}</p>
            <label className="checkbox">
              <input type="checkbox" name="checkmark"
                     checked={newPermissions.includes(PermissionsArray.REQUEST_MANAGER)}
                     onChange={(event) => {
                       if (event.target.checked) {
                         setNewPermissions([...newPermissions, PermissionsArray.REQUEST_MANAGER])
                       } else {
                         setNewPermissions(newPermissions.filter(item => item !== PermissionsArray.REQUEST_MANAGER))
                       }
                     }}
              />
              <span className="checkmark"/>
            </label>
          </div>
          <div className="input-container checkbox-left-comment">
            <p>{i18n.t("Has clinic administrator rights")}</p>
            <label className="checkbox">
              <input type="checkbox" name="checkmark"
                     checked={newPermissions.includes(PermissionsArray.CLINIC_ADMIN)}
                     onChange={(event) => {
                       if (event.target.checked) {
                         setNewPermissions([...newPermissions, PermissionsArray.CLINIC_ADMIN])
                       } else {
                         setNewPermissions(newPermissions.filter(item => item !== PermissionsArray.CLINIC_ADMIN))
                       }
                     }}
              />
              <span className="checkmark"/>
            </label>
          </div>
        </div>
        <div className="dropdown-btns employee-dropdown-btns">
          <div className="cancel-btn dropdown-approve-btn btn scale-btn"
               onClick={() => {
                 setIsOpenDropdown(null);
                 setNewPermissions(item?.permission_id_permissions.map(item => item.id))
               }}
          >
            <Trans>Cancel</Trans>
          </div>
          <div className="success-btn dropdown-approve-btn btn scale-btn"
               onClick={() => {
                 dispatch(editEmployeePermissions(item?.id, newPermissions, setIsOpenDropdown, id, i18n))
                 
                 if (item?.account_status !== StatusArray.APPROVED && item?.account_status !== StatusArray.CANCELED) {
                   dispatch(editEmployeeStatus(item?.id, StatusArray.APPROVED, navigate, i18n))
                 }
               }}
          >
            <Trans>Approve</Trans>
          </div>
        </div>
      </div>
    </div>
  );
}

