import { toast } from "react-toastify";
import { apiGet, apiPost, apiPut } from "../../http/headerPlaceholder.instance";
import { ACTIONS, actionTimeTypes, calculateOffset, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";
import { paths } from "../../Utils/Routes";
import { InviteClinicComponentKeys } from "../../Utils/ConstantsInviteClinic";
import { checkAuth } from "./userActions";

export const getClinicsList = (i18n, name = '', page = 0) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/clinics/all?name=${name}&limit=${LIMIT_ITEM_PER_PAGE}&offset=${calculateOffset(page)}`
      });
      if (name) {
        dispatch({
          type: ACTIONS.CLINIC.SET_FILTERED_ARRAY,
          payload: data
        });
      } else {
        dispatch({
          type: ACTIONS.CLINIC.SET_CLINICS,
          payload: data
        });
      }
    } catch (e) {
      toast.error(i18n.t("Something went wrong"));
    }
  };
};

export const getNewClinicsCount = (setIsNewClinic) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGet({
        url: `/manager/new-clinics-count`
      });
      if (data.count) {
        setIsNewClinic(true)
      }
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(checkAuth());
      }
    }
  };
};

export const getAppointmentTypesList = (i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/appointment-types/`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.CLINIC.SET_APPOINTMENT_TYPES_LIST,
          payload: data
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getClinicAppointmentTypesList = (clinicId, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/clinics/get-appointment-types?clinicId=${clinicId}`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.CLINIC.SET_CLINIC_APPOINTMENT_TYPES_LIST,
          payload: data
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const updateClinicAppointmentTypesList = (clinicId, data, i18n) => {
  return async () => {
    apiPut({
      url: `/clinics/update-appointment-types`,
      data: {
        clinicId,
        appointmentTypesList: data.map((el) => el.id)
      }
    })
      .then((res) => {
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

export const getClinicById = (clinicUUID, i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/clinics/${clinicUUID}`,
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.CLINIC.SET_CLINIC,
          payload: data
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const editClinicById = (clinicId, clinicUUID, data, i18n) => {
  const { description, name, email, telephone, location, slogan, website, bsnr, city, postal_code } = data;
  
  return async () => {
    apiPut({
      url: `/clinics/${clinicUUID}`,
      data: {
        id: clinicId,
        description,
        name,
        email,
        telephone,
        location,
        slogan,
        website,
        bsnr,
        city,
        postal_code
      }
    })
      .then((res) => {
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  }
}

export const editClinicWorkingHours = (clinicId, workingHours, i18n) => {
  return async () => {
    apiPut({
      url: `/clinics/working-hours`,
      data: {
        id: clinicId,
        workingHours
      }
    })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  }
}

export const updateClinicImage = (clinicId, clinicUUID, data, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/clinics/${clinicUUID}/update-clinic-image`,
      data: {
        clinicId,
        data
      }
    })
      .then((res) => {
        dispatch({
          type: ACTIONS.CLINIC.ADD_CLINIC_PHOTO,
          payload: res.data.photo_url
        });
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  }
}

export const updateClinicHoliday = (clinicId, clinicUUID, isWorking, workingStatus, i18n, formikRef) => {
  return async () => {
    apiPut({
      url: `/clinics/working-status`,
      data: {
        clinicId,
        isWorking,
        workingStatus
      }
    })
      .then((res) => {
        getClinicById(clinicUUID, i18n);
        if (isWorking) {
          formikRef.current?.resetForm();
        }
        if (res) {
          toast.success(i18n.t("Updated!"));
        }
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  }
}

export const setWorkingHours = (days) => {
  const stringWorkingHours = [];
  let objectWorkingHours = {};
  for (let day in days) {
    days[day].isChecked
    && stringWorkingHours.push(`${day}: ${days[day].time.map((el, index) =>
      (index % 2 === 0) ? el.concat('-') : el.concat(', ')).join('')}`);
    if (days[day].isChecked) {
      let dayArray = days[day].time.map((el, index, array) => (index % 2 === 0) ? {
        "from": array[index],
        "to": array[index + 1]
      } : null);
      let i = dayArray.length;
      while (i--) i % 2 === 1 && (dayArray.splice(i, 1));
      objectWorkingHours = {
        ...objectWorkingHours,
        [day]: dayArray
      };
    }
    stringWorkingHours.join(' ');
  }
  return { stringWorkingHours, objectWorkingHours }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTimeTypes.CHANGE_IS_SHOWN: {
      const name = action.payload;
      return {
        days: { ...state.days, [name]: { ...state.days[name], isShown: !state.days[name].isShown } }
      };
    }
    case actionTimeTypes.UPDATE_TIME: {
      const index = action.payload.index;
      const day = action.payload.day;
      const timeValue = action.payload.timeValue;
      const newTime = [...state.days[day].time];
      newTime[index] = timeValue;
      
      const [secondHour, secondMinute] = timeValue.split(':');
      const [nextHour, nextMinute] = (state.days[day].time.length > (index + 1)) ? state.days[day].time[index + 1].split(':') : [];
      
      if (index === 0) {
        if (nextHour && parseInt(secondHour) > parseInt(nextHour)) {
          return {
            days: { ...state.days, [day]: { ...state.days[day], time: [...state.days[day].time] } }
          }
        } else {
          return {
            days: { ...state.days, [day]: { ...state.days[day], time: newTime } }
          };
        }
      }
      const [firstHour, firstMinute] = state.days[day].time[index - 1].split(':');
      
      if (parseInt(firstHour) < parseInt(secondHour)) {
        if (nextHour && parseInt(secondHour) > parseInt(nextHour)) {
          return {
            days: { ...state.days, [day]: { ...state.days[day], time: [...state.days[day].time] } }
          }
        }
        return {
          days: { ...state.days, [day]: { ...state.days[day], time: newTime } }
        };
      } else if (parseInt(firstHour) === parseInt(secondHour)) {
        if (parseInt(firstMinute) <= parseInt(secondMinute)) {
          if (nextHour && parseInt(secondHour) > parseInt(nextHour)) {
            return {
              days: { ...state.days, [day]: { ...state.days[day], time: [...state.days[day].time] } }
            }
          }
          return {
            days: { ...state.days, [day]: { ...state.days[day], time: newTime } }
          };
        }
      }
      return {
        days: { ...state.days, [day]: { ...state.days[day], time: [...state.days[day].time] } }
      }
    }
    case actionTimeTypes.ADD_TIME: {
      const day = action.payload.day;
      const newTime = [...state.days[day].time];
      newTime.push('');
      newTime.push('');
      return {
        days: { ...state.days, [day]: { ...state.days[day], time: newTime } }
      };
    }
    case actionTimeTypes.DELETE_TIME: {
      const day = action.payload.day;
      const index = action.payload.index;
      let newTime = [...state.days[day].time];
      newTime.splice(index, 2);
      return {
        days: { ...state.days, [day]: { ...state.days[day], time: newTime } }
      };
    }
    case actionTimeTypes.UPDATE_CHECKED_DAY: {
      const day = action.payload;
      return {
        days: { ...state.days, [day]: { ...state.days[day], isChecked: !state.days[day].isChecked } }
      };
    }
    case actionTimeTypes.CHECK_DAY: {
      const day = action.payload;
      return {
        days: { ...state.days, [day]: { ...state.days[day], isChecked: true } }
      };
    }
  }
  throw Error();
}

export const editClinicStatus = (clinicId, statusId, navigate, i18n) => {
  return async (dispatch) => {
    apiPut({
      url: `/clinics/status`,
      data: {
        clinicId,
        statusId
      }
    })
      .then(async () => {
        dispatch({
          type: ACTIONS.CLINIC.CLEAR_FILTERED_ARRAY
        });
        navigate(`${paths.clinics}`);
        toast.success(i18n.t("Updated!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
}

export const submitClinic = (managerInfo, captchaToken, clinicInfo, i18n) => {
  const { text, ...workingHoursObject } = clinicInfo.workingHours;
  return async (dispatch) => {
    apiPost({
      url: `/clinics/create`,
      data: {
        clinic: {
          data: clinicInfo.clinicPhoto,
          name: clinicInfo.name,
          slogan: clinicInfo.slogan,
          location: clinicInfo.location,
          telephone: clinicInfo.telephone,
          website: clinicInfo.website,
          description: clinicInfo.description,
          email: clinicInfo.email,
          bsnr: clinicInfo.bsnr,
          workingHours: workingHoursObject,
          city: clinicInfo?.city,
          postal_code: clinicInfo?.postal_code
        },
        manager: {
          data: managerInfo.userPhoto,
          firstName: managerInfo.firstName,
          lastName: managerInfo.lastName,
          telephone: managerInfo.telephone,
          email: managerInfo.email,
          birthDate: managerInfo.birthDate,
          password: managerInfo.newPassword,
          lanr: managerInfo.lanr,
          primarySpecialty: managerInfo.primarySpecialty
        },
        token: captchaToken
      }
    })
      .then(() => {
        dispatch({
          type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
          payload: InviteClinicComponentKeys.CONFIRM_APPROVED
        });
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
        dispatch({
          type: ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT,
          payload: InviteClinicComponentKeys.CONFIRM_REJECTED
        });
      });
  }
}

export const inviteClinic = (email, setIsSent, i18n) => {
  return async () => {
    apiPost({
      url: `/clinics/send-invite?lang=${i18n.language}`,
      data: {
        email
      }
    })
      .then(() => {
        setIsSent(true);
        toast.success(i18n.t("Invite successfully sent!"));
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      });
  };
};

