import "../../Assets/Styles/ForPatients.css";
import "../InviteClinic/InviteClinicWelcomePage/index.css";
import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Field, Form, Formik } from "formik";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { EmployeeSignInSchema } from "../../Utils/ValidationSchemas";
import {
  ACTIONS,
  capitalizeFirstLetter,
  countDateForDatePicker,
  decodeURIFragments,
  inviteEmployeeReducerTypes,
  isPreProd,
  isProd,
  PermissionsArray,
  theme
} from "../../Utils/constants";
import {
  CrossedPasswordIco,
  EmptyUserProfileImg,
  FlagIco,
  InputWarningIco,
  PasswordIco
} from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";
import { sendOTP } from "../../Store/actions/userActions";
import { setState } from "../../Store/actions/employeeActions";
import { ARROWS, ValidationCheckmarkSVG } from "../../Assets/Images/svg";
import { getSpecialtiesList } from "../../Store/actions/specialtyActions";


export const InviteEmployeeForm = ({ setIsSubmit }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.inviteUserReducer);
  const specialties = useSelector(state => state.specialtyReducer.items);
  
  useEffect(() => {
    const encodedUrl = window.location.toString();
    const decodedUrl = decodeURIFragments(encodedUrl);
    if (window.location.search) {
      const query = decodedUrl.split("?")[1].split("=");
      if (query[0] === "token") {
        localStorage.setItem('accessToken', query[1]);
      }
    }
  }, [window.location.search]);
  
  useEffect(() => {
    if (!specialties.length) {
      dispatch(getSpecialtiesList(i18n));
    }
  }, [specialties]);
  
  const [state, changeState] = useReducer(setState, {
    userPhoto: user?.userPhoto,
    birthDate: user.birthDate || countDateForDatePicker(35),
    specialties: user.specialties,
    newPasswordType: 'password',
    confirmPasswordType: 'password',
    isShowAdditionalInfo: false,
    captchaToken: ''
  });
  
  const toggleNewPassword = () => {
    if (state.newPasswordType === "password") {
      changeState({
        type: inviteEmployeeReducerTypes.UPDATE_NEW_PASSWORD_TYPE,
        payload: 'text'
      });
      return;
    }
    changeState({
      type: inviteEmployeeReducerTypes.UPDATE_NEW_PASSWORD_TYPE,
      payload: 'password'
    });
  };
  
  const toggleConfirmPassword = () => {
    if (state.confirmPasswordType === "password") {
      changeState({
        type: inviteEmployeeReducerTypes.UPDATE_CONFIRM_PASSWORD_TYPE,
        payload: 'text'
      });
      return;
    }
    changeState({
      type: inviteEmployeeReducerTypes.UPDATE_CONFIRM_PASSWORD_TYPE,
      payload: 'password'
    });
  };
  
  return (
    <>
      <h1 className="h1-700"><Trans>Add Required Information about Doctor</Trans></h1>
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          birthDate: state.birthDate,
          telephone: user.telephone,
          primarySpecialty: user.primarySpecialty,
          lanr: user.lanr,
          email: user.email,
          newPassword: user?.newPassword,
          confirmPassword: user?.confirmPassword
        }}
        validationSchema={EmployeeSignInSchema}
        onSubmit={(values) => {
          dispatch({
            type: ACTIONS.INVITE_USER.SET_INVITE_USER,
            payload: {
              ...values,
              birthDate: state.birthDate,
              userPhoto: state.userPhoto,
              specialties: state.specialties
            }
          });
          dispatch({
            type: ACTIONS.INVITE_USER.SET_CAPTCHA_TOKEN,
            payload: state.captchaToken
          });
          
          dispatch(sendOTP(values?.email, state.captchaToken, setIsSubmit, i18n));
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="employee-form">
            <div className="clinic-input-container">
              <label><Trans>First Name</Trans></label>
              <Field
                type="text"
                name="firstName"
                maxLength="25" required
                className={`${(errors.firstName && touched.firstName) ? 'input-red-border' : ''}`}
              />
              <div className="input-icons">
                {(errors.firstName && touched.firstName) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.firstName} touched={touched.firstName}/>
            </div>
            <div className="clinic-input-container">
              <label><Trans>Last Name</Trans></label>
              <Field
                type="text"
                name="lastName"
                maxLength="25" required
                className={`${(errors.lastName && touched.lastName) ? 'input-red-border' : ''}`}
              />
              <div className="input-icons">
                {(errors.lastName && touched.lastName) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.lastName} touched={touched.lastName}/>
            </div>
            <div className="input-container">
              <label><Trans>Specialty</Trans></label>
              <div className="select-input clinic-select-input">
                <Field as="select" name="primarySpecialty"
                       className={`${(errors.primarySpecialty && touched.primarySpecialty) ? 'input-red-border' : ''}`}
                       onChange={(e) => {
                         handleChange(e);
                       }}
                >
                  {!values.primarySpecialty &&
                    <option value=""/>
                  }
                  {specialties.map(item =>
                    <option
                      value={item.id}
                      key={`${item.id}${item.description}`}
                    >
                      {capitalizeFirstLetter(i18n.t(`${item.description}`))}
                    </option>
                  )}
                </Field>
              </div>
              <div className="input-icons select-icons">
                {(errors.primarySpecialty && touched.primarySpecialty) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.primarySpecialty}
                               touched={values.primarySpecialty?.id ? true : touched.primarySpecialty}
              />
            </div>
            <div className="clinic-input-container">
              <label><Trans>LANR (lifelong doctor number)</Trans></label>
              <Field
                type="text" name="lanr"
                maxLength="9" required
                className={`${(errors.lanr && touched.lanr) ? 'input-red-border' : ''}`}
                onChange={(event) => {
                  const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                  handleChange(event);
                  handleChange({ target: { name: 'lanr', value: cleanedValue } });
                }}
              />
              <div className="input-icons">
                {(errors.lanr && touched.lanr) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.lanr} touched={touched.lanr}/>
            </div>
            <div className="clinic-input-container">
              <label><Trans>Email</Trans></label>
              <Field
                type="email"
                name="email"
                maxLength="40" required
                className={`${(errors.email && touched.email) ? 'input-red-border' : ''}`}
                onChange={(event) => {
                  const cleanedValue = event.target.value.replace(" ", "").toLowerCase();
                  handleChange(event);
                  handleChange({ target: { name: 'email', value: cleanedValue } });
                }}
              />
              <div className="input-icons">
                {(errors.email && touched.email) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.email} touched={touched.email}/>
            </div>
            <div className="clinic-input-container">
              <label>
                <Trans>Password</Trans>
              </label>
              <Field
                type={state.newPasswordType}
                name="newPassword"
                maxLength="16" required
                className={`${(errors.newPassword && touched.newPassword) ? 'input-red-border' : ''}`}
              />
              <div className="input-icons">
                <img alt="show_pw"
                     src={state.newPasswordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className=" btn scale-btn" onClick={toggleNewPassword}
                />
                {(errors.newPassword && touched.newPassword) &&
                  <img alt="show_pw" src={InputWarningIco}/>
                }
              </div>
              <ValidationError errors={errors.newPassword} touched={touched.newPassword}/>
            </div>
            {values.newPassword &&
              <div className="password-validation-container">
                <p><Trans>Recommendation for the most secure password:</Trans></p>
                <div className="password-validation-item">
                  {ValidationCheckmarkSVG(values.newPassword?.length >= 8 ? '#47C0C1' : '#AAABBA')}
                  <p><Trans>At least 8 symbols</Trans></p>
                </div>
                <div className="password-validation-item">
                  {ValidationCheckmarkSVG(/[A-Z]/.test(values.newPassword) ? '#47C0C1' : '#AAABBA')}
                  <p><Trans>At least 1 capital letter</Trans></p>
                </div>
                <div className="password-validation-item">
                  {ValidationCheckmarkSVG(/[a-z]/.test(values.newPassword) ? '#47C0C1' : '#AAABBA')}
                  <p><Trans>At least 1 lowercase letter</Trans></p>
                </div>
                <div className="password-validation-item">
                  {ValidationCheckmarkSVG(/\d/.test(values.newPassword) ? '#47C0C1' : '#AAABBA')}
                  <p><Trans>At least 1 number</Trans></p>
                </div>
                <div className="password-validation-item">
                  {ValidationCheckmarkSVG(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.newPassword) ?
                    '#47C0C1'
                    :
                    '#AAABBA'
                  )}
                  <p><Trans>At least 1 special symbols</Trans></p>
                </div>
              </div>
            }
            <div className="clinic-input-container">
              <label>
                <Trans>Confirm Password</Trans>
              </label>
              <Field
                type={state.confirmPasswordType}
                name="confirmPassword"
                maxLength="16" required
                className={`${(errors.confirmPassword && touched.confirmPassword) ? 'input-red-border' : ''}`}
              />
              <div className="input-icons">
                <img alt="show_pw"
                     src={state.confirmPasswordType === 'password' ? PasswordIco : CrossedPasswordIco}
                     className="btn scale-btn" onClick={toggleConfirmPassword}
                />
                {(errors.confirmPassword && touched.confirmPassword) &&
                  <img alt="show_pw" src={InputWarningIco} className="btn scale-btn"/>
                }
              </div>
              <ValidationError errors={errors.confirmPassword} touched={touched.confirmPassword}/>
            </div>
            <div className="clinic-header">
              <p>{i18n.t("Additional Information (You can skip it for now)")}</p>
              <div className={state.isShowAdditionalInfo ? "clinic-arrow" : "clinic-arrow-rotate"}
                   onClick={() => changeState({
                     type: inviteEmployeeReducerTypes.UPDATE_IS_SHOW_ADDITIONAL_INFO,
                     payload: !state.isShowAdditionalInfo
                   })}
              >
                {ARROWS.BOTTOM}
              </div>
            </div>
            <div className={state.isShowAdditionalInfo ? "clinic-form-container" : "clinic-form-container-expanded"}>
              <div className="clinic-logo">
                <div className="manage-img">
                  <div className="circle-crop-100">
                    <img alt="photo" src={state.userPhoto || EmptyUserProfileImg}/>
                  </div>
                  {user.userPhoto && <label htmlFor="files" className="manage-delete_file" onClick={() => changeState({
                    type: inviteEmployeeReducerTypes.UPDATE_USER_PHOTO,
                    payload: ''
                  })}/>}
                  <label htmlFor="files" className="manage-upload_file"/>
                  <input name="attachment" type="file"
                         id="files" accept="image/*" hidden
                         onChange={(e) => {
                           const reader = new FileReader();
                           reader.readAsDataURL(e.target.files[0]);
                           reader.onloadend = () => {
                             changeState({
                               type: inviteEmployeeReducerTypes.UPDATE_USER_PHOTO,
                               payload: reader.result
                             });
                           };
                         }}
                  />
                </div>
              </div>
              <div className="clinic-input-container">
                <label><Trans>Birth date</Trans></label>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <ThemeProvider theme={theme}>
                    <DatePicker
                      className="date-picker"
                      openTo={"year"}
                      views={['year', 'month', 'day']}
                      inputFormat="DD.MM.YYYY"
                      maxDate={countDateForDatePicker(16)}
                      disableFuture
                      value={state.birthDate}
                      onChange={(value) => {
                        changeState({
                          type: inviteEmployeeReducerTypes.UPDATE_USER_BIRTH_DATE,
                          payload: value.toDate()
                        });
                      }}
                      renderInput={(params) =>
                        <TextField {...params} helperText={null}/>
                      }
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              </div>
              <div className="clinic-input-container">
                <label><Trans>Telephone</Trans></label>
                <Field
                  type="tel"
                  name="telephone"
                  maxLength="20"
                  className={`${(errors.telephone && touched.telephone) ? 'input-red-border' : ''} input-phone`}
                  onChange={(event) => {
                    const cleanedValue = event.target.value.replace(/[^+\d]/g, '');
                    handleChange(event);
                    handleChange({ target: { name: 'telephone', value: cleanedValue } });
                  }}
                />
                <div className="input-icons-start">
                  <img alt="ico"
                       src={FlagIco}
                  />
                  <p>+49</p>
                </div>
                <div className="input-icons">
                  {(errors.telephone && touched.telephone) &&
                    <img alt="show_pw" src={InputWarningIco}/>
                  }
                </div>
                <ValidationError errors={errors.telephone} touched={touched.telephone}/>
              </div>
            </div>
            {(isProd || isPreProd) && (
              <>
                <div className="captcha-margin"/>
                <HCaptcha
                  sitekey={!isProd ? "4b6c1317-4c2a-4f1b-89c9-3e163fdf42aa" : "357f9afc-dfa6-4882-a907-89b4e7e62e82"}
                  onVerify={(token) => changeState({
                    type: inviteEmployeeReducerTypes.UPDATE_CAPTCHA_TOKEN,
                    payload: token
                  })}
                />
              </>
            )}
            <button className="welcome-button" type="submit"
                    disabled={!!Object.keys(errors).length}
            >
              {i18n.t(user.role === PermissionsArray.CLINIC_ADMIN ?
                "Continue"
                :
                "Create Account"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
