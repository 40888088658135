import { toast } from "react-toastify";
import { apiGet } from "../../http/headerPlaceholder.instance";
import { ACTIONS } from "../../Utils/constants";

export const getSpecialtiesList = (i18n) => {
  return async (dispatch) => {
    apiGet({
      url: `/specialties/all`
    })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS.SPECIALTiES.SET_SPECIALTiES,
          payload: data
        })
      })
      .catch(() => {
        toast.error(i18n.t("Something went wrong"));
      })
  }
}