import React, { useState } from 'react';
import UserRow from "./UserRow";

const RenderUsersHavingPending = ({ items, activeTab }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  
  if (!items) {
    return null;
  }
  
  return items.map((el, i) =>
    <UserRow item={el} key={`pending-user-row-${el?.id}`} openDropdown={openDropdown} setOpenDropdown={setOpenDropdown}
             i={i} activeTab={activeTab} usersLength={items.length}
    />
  )
};

export default RenderUsersHavingPending;