import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { paths } from "../../Utils/Routes";
import { useTranslation } from "react-i18next";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  
  const { role } = useSelector((state) => state.userReducer.user);
  
  return (
    <div>
      <Header/>
      <main>
        <section className="sect error-container">
          <div className="error-header">
            <div>{i18n.t("Ooops")}...</div>
            <div>{i18n.t("Page not found")}</div>
          </div>
          <div className="error-icon">404</div>
          <button type="button" className="welcome-button" onClick={() => navigate(role ? paths.main : paths.login)}>
            {i18n.t("Go Back")}
          </button>
        </section>
      </main>
    </div>
  )
};
