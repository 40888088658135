import "../../Assets/Styles/AllRequest.css";
import React, { useEffect, useState } from 'react';
import { getUserPendingAppointmentsList } from "../../Store/actions/appointmentActions";
import PendingAppointment from "./PendingAppointment";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import dayjs from "dayjs";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";

const UserRow = ({ item, openDropdown, setOpenDropdown, i, activeTab, usersLength }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const [pendingItems, setPendingItems] = useState({
    items: [],
    filteredItems: [],
  });
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const largestPriorityId = pendingItems.filteredItems.reduce((minPriority, currentObj) => {
    if (currentObj.priority_id !== undefined) {
      return Math.min(minPriority, currentObj.priority_id);
    }
    return minPriority;
  }, 4);
  
  const caregiver = pendingItems.filteredItems ? pendingItems.filteredItems[0]?.user?.caregivers[0] : null;
  
  const isAllReqToOneDoc = pendingItems.filteredItems.every(item => item?.doctor_id === pendingItems.filteredItems[0]?.doctor_id)
  
  const doctorAssignedTo = isAllReqToOneDoc && pendingItems.filteredItems.length ? pendingItems.filteredItems[0]?.doctor : null
  
  useEffect(() => {
    dispatch(getUserPendingAppointmentsList(item?.uuid, setPendingItems, i18n, setLoaded));
  }, []);
  
  useEffect(() => {
    setOpen(false);
    
    const filteredData = activeTab !== "allRequests" ?
      pendingItems.items?.filter(item =>
        activeTab === 'doctor transfer' ?
          item.type === activeTab && !item?.sender_id
          : activeTab === 'doc2Doc' ?
            item.type === activeTab && item?.sender_id
            :
            item.type === activeTab
      )
      :
      pendingItems.items
    
    setPendingItems({
      ...pendingItems,
      filteredItems: filteredData
    })
  }, [activeTab])
  
  useEffect(() => {
    if (pendingItems.filteredItems.length === 1) {
      setOpen(true)
    }
  }, [pendingItems.filteredItems.length])
  
  if (!loaded) {
    return <Loader/>;
  }
  
  if (loaded && !pendingItems.filteredItems.length) return usersLength === 1 ? <SearchNoFound/> : <div/>;
  
  return (
    <div key={`${item?.id}card`}
         className={`request-card ${largestPriorityId && largestPriorityId !== 4 ? `urgency-card-marker urgency-marker-${largestPriorityId}` : ''}`}
    >
      <div className='request-card-header' key={`${item?.id}card`}>
        <div className='request-card-header-users'>
          <Link to={`/patients/${item?.uuid}`}>
            <div className='request-card-text'><Trans>Patient</Trans>:</div>
            <div className="request-card-user">
              <div className="circle-crop">
                <img alt='avatar' src={item?.photo_url || EmptyUserProfileImgForTable(2)}/>
              </div>
              <div className="request-card-user-info">
                <div className='request-card-text'>{`${item?.firstName} ${item?.lastName}`}</div>
                <div className='request-card-text'>
                  <span><Trans>Date of Birth</Trans>: </span>
                  {dayjs(new Date(item?.birth_date)).format("DD.MM.YYYY")}
                </div>
                <div className='request-card-text'>
                  <span><Trans>Mobile</Trans>: </span>
                  {item?.telephone}
                </div>
              </div>
            </div>
          </Link>
          {!!caregiver &&
            <div className='request-card-user-wrap'>
              <div className='request-card-text'><Trans>Caregiver</Trans>:</div>
              <div className="request-card-user">
                <div className="circle-crop">
                  <img alt='avatar'
                       src={caregiver?.photo_url || EmptyUserProfileImgForTable(2)}
                  />
                </div>
                <div className="request-card-user-info">
                  <div className='request-card-text'>
                    {`${caregiver?.firstName} ${caregiver?.lastName}`}
                  </div>
                  <div className='request-card-text'>
                    <span><Trans>Date of Birth</Trans>: </span>
                    {dayjs(new Date(caregiver?.birth_date)).format("DD.MM.YYYY")}
                  </div>
                  <div className='request-card-text'>
                    <span><Trans>Mobile</Trans>: </span>
                    {caregiver?.telephone}
                  </div>
                </div>
              </div>
            </div>
          }
          {!!doctorAssignedTo &&
            <div className='request-card-user-wrap request-card-doctor-assigned'>
              <div className='request-card-text'><Trans>Doctor</Trans>:</div>
              <div className="request-card-user">
                <div className="circle-crop">
                  <img alt='avatar'
                       src={doctorAssignedTo?.user?.photo_url || EmptyUserProfileImgForTable(2)}
                  />
                </div>
                <div className="request-card-user-info">
                  <div className='request-card-text'>{doctorAssignedTo.publicName}</div>
                  <div className='request-card-text'>
                    <div>
                      <span><Trans>Specialty</Trans>:</span>
                    </div>
                    <div>
                      {doctorAssignedTo?.specialtyToDoctors.map(el =>
                        <div><Trans>{` ${el.specialty.description}`}</Trans></div>
                      )}
                    </div>
                  </div>
                  <div className='request-card-text'>
                    <span><Trans>BSNR</Trans>: </span>
                    {doctorAssignedTo?.bsnr}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <p>{pendingItems.filteredItems.length} <Trans>request(s)</Trans></p>
      </div>
      <div className='request-card-table-heading'>
        <div className='request-card-col'><Trans>Type</Trans></div>
        <div className='request-card-col'><Trans>Request</Trans></div>
        <div className='request-card-col'><Trans>Reason</Trans></div>
        <div className='request-card-col'><Trans>Notice</Trans></div>
        <div className='request-card-col'><Trans>Date/Time</Trans></div>
        <div className='request-card-col-btns'><Trans>Action</Trans></div>
      </div>
      {pendingItems.filteredItems?.map((el, index) => {
        return (
          <PendingAppointment
            key={`pending-${el?.type}-${el?.id}`}
            item={el} i={index}
            pendingItems={pendingItems}
            setPendingItems={setPendingItems}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            open={open} setOpen={setOpen}
          />
        );
      })}
    </div>
  );
};

export default UserRow;
