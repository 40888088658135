import "../../Assets/Styles/WorkingHours.css";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { getAllEmployeesList } from "../../Store/actions/employeeActions";
import ValidationError from "../../Components/ValidationError";
import { DocToDocSchema } from "../../Utils/ValidationSchemas";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { appointmentsTypes, capitalizeFirstLetter, StatusArray, theme, timeBlocks } from "../../Utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { approveDoc2DocTransfer, editStatus } from "../../Store/actions/appointmentActions";

export const Doctor2DoctorDropdown = ({ openDropdown, setOpenDropdown, item, pendingItems, setPendingItems }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { filteredItems } = useSelector(state => state.employeesReducer);
  
  const [activeButton, setActiveButton] = useState(item?.priority_id || 4);
  const [doctorId, setDoctorId] = useState(-1);
  const [date, setDate] = useState(new Date(item?.date));
  const [time, setTime] = useState(timeBlocks[item?.day_block_id].time);
  
  const doctorsList = filteredItems.filter((employee) => {
    const hasSpecialty = employee?.doctor?.specialty_id_specialties.some(obj => Number(obj.id) === Number(item?.specialty_id))
    
    return !!employee.firstName && hasSpecialty
  });
  
  useEffect(() => {
    if (!filteredItems.length) {
      dispatch(getAllEmployeesList(i18n));
    }
  }, [])
  
  return (
    <>
      <div className="position-relative"
           style={
             openDropdown === item?.id ? { display: "block" } : { display: "none" }
           }
      >
        <div className="item-dropdown">
          <div className='list-heading'>
            <p>{item ? i18n.t("Make Request") : i18n.t("Request an Appointment")}</p>
            <div className='list-subtitle'>
              {i18n.t("Please fill the form to make specialist transfer request")}
            </div>
          </div>
          <Formik
            initialValues={{
              specialty: item?.specialty?.description || '',
              doctor: '',
              reason: item?.reason || ''
            }}
            validationSchema={DocToDocSchema}
            onSubmit={(values, { resetForm }) => {
              if (Number(doctorId) === -1) {
                
                const hours = time.slice(0, 2);
                const min = time.slice(3);
                
                const newDate = new Date(date);
                newDate.setHours(hours, min);
                
                setTimeout(() => {
                  dispatch(editStatus(
                    appointmentsTypes[item?.type],
                    item?.id,
                    StatusArray.APPROVED,
                    item.user_id,
                    pendingItems,
                    setPendingItems,
                    newDate,
                    values?.reason,
                    i18n
                  ));
                }, 150);
              } else {
                dispatch(approveDoc2DocTransfer(item?.id, activeButton, values, i18n));
                
                const newArr = pendingItems.filteredItems.map((obj) => {
                  if (Number(obj.id) === Number(item?.id)) {
                    return {
                      ...obj,
                      priority_id: Number(activeButton),
                      sender_id: Number(doctorId),
                      doctor_notice: values.reason,
                      doctor_id: null,
                      doctor: null
                    };
                  }
                  
                  return obj;
                })
                
                setPendingItems({
                  ...pendingItems,
                  filteredItems: newArr
                })
              }
              setActiveButton('')
              setDoctorId(-1)
              resetForm()
              setOpenDropdown(null)
            }}
            enableReinitialize={true}
          >
            {({ errors, touched, handleChange, resetForm }) => (
              <Form className="transfer-form">
                <div className="input-container">
                  <label><Trans>Specialist</Trans></label>
                  <Field
                    type="text"
                    name="specialty"
                    maxLength="150" required disabled
                  />
                  <ValidationError errors={errors.specialty} touched={touched.specialty}/>
                </div>
                <div className="input-container">
                  <label><Trans>Reason of Transfer</Trans></label>
                  <Field
                    type="text"
                    name="reason"
                    maxLength="150" required
                  />
                  <ValidationError errors={errors.reason} touched={touched.reason}/>
                </div>
                <div className="input-container">
                  <label><Trans>Choose Doctor</Trans></label>
                  <div className="input-container select-input for-patient-select">
                    <Field as="select" name="doctor"
                           onChange={(event) => {
                             handleChange(event)
                             setDoctorId(event.target.value)
                           }}
                    >
                      <option value={-1}/>
                      {doctorsList.map((item) =>
                        <option value={item?.doctor?.user_id} key={item?.doctor?.publicName}>
                          {capitalizeFirstLetter(i18n.t(item?.doctor?.publicName))}
                        </option>
                      )}
                    </Field>
                  </div>
                </div>
                {Number(doctorId) === -1 &&
                <Fragment>
                  <div className="input-container">
                    <label>
                      <Trans>Choose date</Trans>:
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          className='date-picker light-date-picker doc2doc-picker'
                          views={['day']}
                          openTo={"year"}
                          inputFormat="DD.MM.YYYY"
                          disablePast
                          minDate={new Date(Date.now())}
                          value={date}
                          onChange={(value) => {
                            setDate(new Date(value));
                          }}
                          renderInput={(params) =>
                            <TextField {...params} helperText={null} sx={{ svg: { color: '#47C0C1' } }}/>
                          }
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                    <p className="dropdown-error">
                      {date.getTime() < new Date(Date.now()).getTime() && i18n.t("Selected date has already passed")}
                    </p>
                  </div>
                  <div className="input-container">
                    <label>
                      {i18n.t(timeBlocks[item?.day_block_id].message)}
                    </label>
                    <ThemeProvider theme={theme}>
                      <TextField
                        id="time"
                        type="time"
                        defaultValue={time}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-input ': {
                            padding: '11.5px 10px !important',
                            height: 39,
                          },
                        }}
                        onChange={(event) => {
                          setTime(event.target.value);
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </Fragment>
                }
                <div className='doc2doc-buttons-wrap'>
                  <label><Trans>Urgency</Trans></label>
                  <div className='doc2doc-buttons'>
                    <button className={`${activeButton === 1 ? `active-urgent-btn1` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(1)
                            }}
                    >
                      <Trans>ASAP</Trans> 10 <Trans>Days</Trans>
                    </button>
                    <button className={`${activeButton === 2 ? `active-urgent-btn2` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(2)
                            }}
                    >
                      <Trans>ASAP</Trans> 20 <Trans>Days</Trans>
                    </button>
                    <button className={`${activeButton === 3 ? `active-urgent-btn3` : ''}`}
                            disabled={Number(doctorId) === -1}
                            onClick={(event) => {
                              event.preventDefault()
                              setActiveButton(3)
                            }}
                    >
                      <Trans>ASAP</Trans> 30 <Trans>Days</Trans>
                    </button>
                  </div>
                </div>
                <button type="submit" className='doc2doc-btn1'>
                  <Trans>Send Request to Doctor</Trans>
                </button>
                <div className='doc2doc-btn2'
                     onClick={() => {
                       setOpenDropdown(false);
                       setActiveButton('')
                       setDoctorId(-1)
                       resetForm()
                     }}
                >
                  <Trans>Cancel</Trans>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
