import { ACTIONS, LIMIT_ITEM_PER_PAGE } from "../../Utils/constants";

const initialState = {
  users: {
    items: [],
    page: 0,
    loadNext: true,
    isReady: false,
  },
  pending: {
    items: [],
    filteredItems: [],
    page: 0,
    loadNext: true,
    isReady: false
  },
  done: {
    items: [],
    filteredItems: [],
    page: 0,
    loadNext: true,
    isReady: false
  },
  patient: null,
  appointmentTypeList: []
};


const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.APPOINTMENT.SET_USERS_HAVING_PENDING: {
      if (action.payload.data.length < LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          users: {
            ...state.users,
            items: state.users.items.concat(action.payload.data),
            isReady: true,
            loadNext: false
          }
        }
      }
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.concat(action.payload.data),
          isReady: true,
          loadNext: true,
          page: state.users.page + 1
        }
      }
    }
    case ACTIONS.APPOINTMENT.DELETE_USER_WITHOUT_PENDING: {
      return {
        ...state,
        users: {
          ...state.users,
          items: state.users.items.filter((el) => el.user_id !== action.payload.id)
        }
      }
    }
    case ACTIONS.APPOINTMENT.SET_PENDING_APPOINTMENTS: {
      if (action.payload.length < LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          pending: {
            ...state.pending,
            items: state.pending.items.concat(action.payload),
            isReady: true,
            loadNext: false
          }
        }
      }
      return {
        ...state,
        pending: {
          ...state.pending,
          items: state.pending.items.concat(action.payload),
          isReady: true,
          loadNext: true,
          page: state.pending.page + 1
        }
      }
    }
    case ACTIONS.APPOINTMENT.SET_DONE_APPOINTMENTS: {
      if (action.payload.length < LIMIT_ITEM_PER_PAGE) {
        return {
          ...state,
          done: {
            ...state.done,
            items: state.done.items.concat(action.payload),
            isReady: true,
            loadNext: false
          }
        }
      }
      return {
        ...state,
        done: {
          ...state.done,
          items: state.done.items.concat(action.payload),
          isReady: true,
          loadNext: true,
          page: state.done.page + 1
        }
      }
    }
    case ACTIONS.APPOINTMENT.SET_FILTERED_DONE_APPOINTMENTS: {
      return {
        ...state,
        done: {
          ...state.done,
          filteredItems: action.payload,
        }
      }
    }
    case ACTIONS.APPOINTMENT.SET_PAGE: {
      if (action.payload.type === 'pending') {
        return {
          ...state,
          pending: {
            ...state.pending,
            page: action.payload.value
          }
        }
      }
      return {
        ...state,
        done: {
          ...state.done,
          page: action.payload.value
        }
      }
    }
    case ACTIONS.APPOINTMENT.UPDATE_NOTICE: {
      const newArr = state.pending.items.map(obj => {
        if (obj.id === action.payload.id) {
          return { ...obj, notice: action.payload.notice };
        }
        
        return obj;
      });
      
      return {
        ...state,
        pending: {
          ...state.pending,
          items: newArr
        }
      };
    }
    case ACTIONS.APPOINTMENT.SET_PATIENT: {
      return {
        ...state,
        patient: action.payload
      }
    }
    case ACTIONS.APPOINTMENT.REMOVE_FROM_LIST: {
      const id = action.payload;
      const newArr = state.users.items.filter((item) => item.id !== id);
      
      return {
        ...state,
        users: {
          ...state.users,
          items: newArr
        }
      }
    }
    case ACTIONS.APPOINTMENT.CLEAR_ARRAY: {
      return {
        ...state,
        pending: {
          items: [],
          page: 0,
          loadNext: true,
          isReady: false
        },
        done: {
          items: [],
          filteredItems: [],
          page: 0,
          loadNext: true,
          isReady: false
        },
      }
    }
    case ACTIONS.APPOINTMENT.CLEAR_USERS: {
      return {
        ...state,
        users: {
          items: [],
          page: 0,
          loadNext: true,
          isReady: false
        }
      }
    }
    case ACTIONS.APPOINTMENT.SET_APPOINTMENT_TYPES_LIST: {
      return {
        ...state,
        appointmentTypeList: action.payload
      }
    }
    default:
      return state;
  }
};

export default appointmentReducer;