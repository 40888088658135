import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import ConfirmCanceled from "../Confirm/ConfirmCanceled";
import ConfirmPending from "../Confirm/ConfirmPending";
import { DayBlocks } from "../../../Utils/ConstantsForPatient";
import { Trans, useTranslation } from "react-i18next";
import { postAppointment } from "../../../Store/actions/appointmentActions";
import dayjs from "dayjs";

const ConfirmSect = ({ confirmStatus, setShow, setConfirmStatus }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const { data } = useSelector(state => state.bookAppointmentReducer);
  const { user } = useSelector(state => state.userReducer);
  const clinic = useSelector(state => state.clinicReducer.clinic);
  const patient = useSelector(state => state.inviteUserReducer.user);
  
  switch (confirmStatus) {
    case '':
      return (
        <div className='list'>
          <div className='list-heading'>
            <p>{i18n.t("Appointments details")}</p>
            <div className='list-subtitle'>{i18n.t("Please, confirm an appointment")}</div>
          </div>
          <div className='list-details'>
            <p><span>{i18n.t("Patient")}: </span>
              {user?.firstName ?? patient?.firstName} {user?.lastName ?? patient?.lastName}
            </p>
            <p><span>{i18n.t("Appointment")}: </span>{i18n.t(data?.appointmentType.description)}</p>
            <p>
              <span>{i18n.t("Date")}: </span>
              {dayjs(data?.date).format('dddd, D MMMM')}, {i18n.t(DayBlocks[dayjs(data?.date).format('HH')])}
            </p>
            <p><span>{i18n.t("Clinic")}: </span>{clinic?.name}</p>
          </div>
          <button className='for-patients-btn btn scale-btn'
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(postAppointment(data, clinic?.id, setConfirmStatus, i18n))
                  }}
          >
            {i18n.t("Confirm Appointment")}
          </button>
          <button className='for-patients-cancel-btn btn scale-btn'
                  onClick={(event) => {
                    event.preventDefault();
                    setShow(true)
                  }}
          >
            {i18n.t("Cancel Appointment")}
          </button>
        </div>
      )
    case 'pending':
      return <ConfirmPending type='appointment'/>;
    case 'canceled':
      return <ConfirmCanceled type='appointment'/>;
  }
}


export default function ConfirmAppointment() {
  const { i18n } = useTranslation();
  
  const [confirmStatus, setConfirmStatus] = useState('');
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            <div className='breadcrumbs-item confirmation-breadcrumbs-item'><Trans>Confirmation</Trans></div>
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='confirm' type='appointment'/>
            <ConfirmSect confirmStatus={confirmStatus} setConfirmStatus={setConfirmStatus}
                         setShow={setShow}/>
          </div>
        </section>
      </main>
      
      <div className='popup' style={show ? { display: 'flex' } : { display: 'none' }}>
        <div className='popup-container'>
          <div className="popup-approve-heading">{i18n.t("Cancel Appointment")}</div>
          <div>{i18n.t("Do you really want to cancel your appointment?")}</div>
          <div className="popup-btns">
            <div className="popup-approve-btn"
                 onClick={() => {
                   handleClose();
                 }}
            >
              {i18n.t("No")}
            </div>
            <div className="popup-delete-btn popup-delete-btn-2"
                 onClick={() => {
                   handleClose();
                   setConfirmStatus('canceled')
                 }}
            >
              {i18n.t("Yes")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
