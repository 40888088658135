import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/Feedback.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Header from "../../Layouts/Header/Header";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import { ACTIONS, RoleArray } from "../../Utils/constants";
import { getFeedbackList } from "../../Store/actions/feedbackActions";
import { Trans, useTranslation } from "react-i18next";
import { EmptyUserProfileImgForTable, StarIco } from "../../Assets/Images/img";
import PaginationButton from "../../Components/PaginationButton";
import dayjs from "dayjs";

export default function ListOfFeedbacks() {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const { role, id, uuid, clinic_id_clinics_clinicEmployees } = useSelector(state => state.userReducer.user);
  const { ascOrder, descOrder } = useSelector(state => state.feedbackReducer);
  
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState("DESC");
  const selectedList = order === 'DESC' ? descOrder : ascOrder;
  
  useEffect(() => {
    if (query) {
      dispatch({
        type: ACTIONS.FEEDBACK.CLEAR_ARRAY
      });
      dispatch(getFeedbackList(order, query, 0, i18n, role, role !== RoleArray.SUPER_ADMIN && clinic_id_clinics_clinicEmployees[0].ClinicEmployees?.clinic_id));
      setTimeout(() => {
        dispatch(getFeedbackList( order, query, 1, i18n, role, role !== RoleArray.SUPER_ADMIN && clinic_id_clinics_clinicEmployees[0].ClinicEmployees?.clinic_id));
      }, 150);
    }
  }, [order, query, clinic_id_clinics_clinicEmployees]);
  
  useEffect(() => {
    if (!selectedList.isReady && !query) {
      if (role === RoleArray.SUPER_ADMIN) {
        dispatch(getFeedbackList(order, query, 0, i18n, role));
      } else {
        if (clinic_id_clinics_clinicEmployees) {
          dispatch(getFeedbackList(order, query, 0, i18n, role, clinic_id_clinics_clinicEmployees[0].ClinicEmployees?.clinic_id));
        }
      }
      setTimeout(() => {
        if (role === RoleArray.SUPER_ADMIN) {
          dispatch(getFeedbackList(order, query, 1, i18n, role));
        }
      }, 150);
    }
  }, [order, query, clinic_id_clinics_clinicEmployees]);
  
  const changeHandler = (event) => {
    if (!event.target.value) {
      dispatch({
        type: ACTIONS.FEEDBACK.CLEAR_ARRAY
      });
    } else {
      dispatch({
        type: ACTIONS.FEEDBACK.SET_PAGE,
        payload: {
          page: 0,
          order
        }
      });
    }
    setQuery(event.target.value.trim().toLowerCase());
  };
  
  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 300);
  }, []);
  
  const handleNextFeedbacks = useCallback(() => {
    dispatch(getFeedbackList(order, query, selectedList.page, i18n, role, role === RoleArray.SUPER_ADMIN && clinic_id_clinics_clinicEmployees[0].ClinicEmployees?.clinic_id));
  }, [query, order, selectedList.page]);
  
  const toggleOrder = () => {
    setOrder(order === 'ASC' ? 'DESC' : 'ASC');
  };
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading">
            <h1>{role === 1 ? "Application" : "Doctor's"} Feedbacks</h1>
            <div className="search">
              <div className="search-input-wrap">
                <input className="search-input"
                       ref={inputRef}
                       placeholder={i18n.t("Search")}
                       maxLength="30"
                       onChange={debouncedChangeHandler}
                />
                {query &&
                <div className="search-clear"
                     onClick={() => {
                       dispatch({
                         type: ACTIONS.FEEDBACK.CLEAR_ARRAY
                       });
                       setQuery("");
                       inputRef.current.value = "";
                     }}
                >
                  ✕
                </div>
                }
              </div>
            </div>
          </div>
          <div className="manage-patient-table">
            <div
              className="table-heading"
              style={!selectedList?.filteredItems.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
            >
              <div className="table-col-user"><Trans>User</Trans></div>
              <div className="table-col table-col-feedback"><Trans>Feedback</Trans></div>
              <div className={`table-col table-col-date ${order === 'ASC' ? 'rotate-arrow' : ''}`}
                   onClick={() => {
                     toggleOrder();
                   }}
              >
                <Trans>Date</Trans>
                <span className="arrow-icon">
                  <img alt='arrow'
                       src="https://res.cloudinary.com/loksblnine/image/upload/v1663757246/PatientApp/assets_front/arrow_down_vd5hmu.svg"/>
                </span>
              </div>
            </div>
            {!selectedList?.filteredItems.length ? <SearchNoFound/> :
              selectedList.filteredItems.map((item, i) =>
                <div className='card' key={`${item.id} ${item?.date}`}>
                  <div className='card-header'>
                    <div className="table-col-user">
                      <img alt="avatar"
                           src={item?.user?.photo_url || EmptyUserProfileImgForTable(i)}
                      />
                      <div className="table-col ml-10">
                        <div>{item.user?.firstName} {item.user?.lastName}</div>
                      </div>
                    </div>
                    <div className="table-col table-col-feedback">
                      <div className="star-rating">
                        {[...Array(item?.stars)].map((star, index) => {
                          index += 1;
                          return (
                            <img alt="star" key={index} src={StarIco}/>
                          );
                        })}
                      </div>
                      <div>{item?.message}</div>
                    </div>
                    <div className="table-col">
                      <div>{dayjs(item.date).format("DD/MM/YYYY")}</div>
                      <div>{dayjs(item.date).format("HH:mm")}</div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          {(!!selectedList.length && selectedList.loadNext) &&
          <PaginationButton handleNext={handleNextFeedbacks}/>
          }
        </section>
      </main>
    </>
  )
}