import "../../Assets/Styles/ManagePatient.css";
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
  ACTIONS,
  AppointmentTypeText,
  DoctorInfoBlockCol,
  RequestText,
  StatusInfo,
  UrgencyArray
} from "../../Utils/constants";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import PaginationButton from "../../Components/PaginationButton";
import dayjs from "dayjs";
import { getAppointmentsList } from "../../Store/actions/appointmentActions";

export default function AllRequestsDone({ activeTab }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const { items, filteredItems, page, loadNext, isReady } = useSelector(state => state.appointmentReducer.done);
  
  useEffect(() => {
    if (items.length) {
      dispatch({
        type: ACTIONS.APPOINTMENT.SET_FILTERED_DONE_APPOINTMENTS,
        payload: activeTab !== "allRequests" ?
          items?.filter(item =>
            activeTab === 'doctor transfer' ?
              item.type === activeTab && !item?.doctor_id
              : activeTab === 'doc2Doc' ?
                item.type === activeTab && item?.doctor_id
                :
                item.type === activeTab
          )
          :
          items
      });
    }
  }, [activeTab, page, items]);
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.APPOINTMENT.CLEAR_ARRAY
    })
  }, [])
  
  useEffect(() => {
    if (!isReady) {
      dispatch(getAppointmentsList(page, "1,2,6", i18n));
    }
  }, [isReady]);
  
  const handleNextAppointments = useCallback(() => {
    dispatch(getAppointmentsList(page, "1,2,6", i18n));
  }, [page]);
  
  return (
    <>
      {!filteredItems.length ?
        <SearchNoFound/>
        :
        filteredItems.map((item, i) =>
          <div
            className={`request-card ${item?.priority_id ? `urgency-card-marker urgency-marker-${item?.priority_id}` : ''}`}
            key={`${item?.id}-done-card`}
          >
            <div className='request-card-header' key={`${item?.id}card`}>
              <div className='request-card-header-users'>
                <Link to={`/patients/${item?.user?.uuid}`}>
                  <div className='request-card-text'><Trans>Patient</Trans>:</div>
                  <div className="request-card-user">
                    <div className="circle-crop">
                      <img alt='avatar'
                           src={item?.user?.photo_url || EmptyUserProfileImgForTable(2)}
                      />
                    </div>
                    <div className="request-card-user-info">
                      <div className='request-card-text'>{`${item?.user?.firstName} ${item?.user?.lastName}`}</div>
                      <div className='request-card-text'>
                        <span><Trans>Date of Birth</Trans>: </span>
                        {dayjs(new Date(item?.user?.birth_date)).format("DD.MM.YYYY")}
                      </div>
                      <div className='request-card-text'>
                        <span><Trans>Mobile</Trans>: </span>
                        {item?.user?.telephone}
                      </div>
                    </div>
                  </div>
                </Link>
                {!!(item?.user?.caregivers?.length) &&
                  <div className='request-card-user-wrap'>
                    <div className='request-card-text'><Trans>Caregiver</Trans>:</div>
                    <div className="request-card-user">
                      <div className="circle-crop">
                        <img alt='avatar'
                             src={item?.user?.caregivers[0]?.photo_url || EmptyUserProfileImgForTable(2)}
                        />
                      </div>
                      <div className="request-card-user-info">
                        <div className='request-card-text'>
                          {`${item?.user?.caregivers[0]?.firstName} ${item?.user?.caregivers[0]?.lastName}`}
                        </div>
                        <div className='request-card-text'>
                          <span><Trans>Date of Birth</Trans>: </span>
                          {dayjs(new Date(item?.user?.caregivers[0]?.birth_date)).format("DD.MM.YYYY")}
                        </div>
                        <div className='request-card-text'>
                          <span><Trans>Mobile</Trans>: </span>
                          {item?.user?.caregivers[0]?.telephone}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {!!(item?.doctor) &&
                  <div className='request-card-user-wrap request-card-doctor-assigned'>
                    <div className='request-card-text'><Trans>Doctor</Trans>:</div>
                    <div className="request-card-user">
                      <div className="circle-crop">
                        <img alt='avatar'
                             src={item?.doctor?.user?.photo_url || EmptyUserProfileImgForTable(2)}
                        />
                      </div>
                      <div className="request-card-user-info">
                        <div className='request-card-text'>{item?.doctor?.publicName}</div>
                        <div className='request-card-text'>
                          <div>
                            <span><Trans>Specialty</Trans>:</span>
                          </div>
                          <div>
                            {item?.doctor?.specialtyToDoctors.map(el =>
                              <div><Trans>{` ${el.specialty.description}`}</Trans></div>
                            )}
                          </div>
                        </div>
                        <div className='request-card-text'>
                          <span><Trans>BSNR</Trans>: </span>
                          {item?.doctor?.bsnr}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='request-card-table-heading'>
              <div className='request-card-col'><Trans>Type</Trans></div>
              <div className='request-card-col'><Trans>Request</Trans></div>
              <div className='request-card-col'><Trans>Reason</Trans></div>
              <div className='request-card-col'><Trans>Notice</Trans></div>
              <div className='request-card-col'><Trans>Date/Time</Trans></div>
              <div className='request-card-col-btns'><Trans>Status</Trans></div>
            </div>
            <div className='request-card-body-wrap'>
              <div className='request-card-body' key={`${i}card`}>
                <div className='request-card-col'>{AppointmentTypeText(item)}</div>
                <div className='request-card-col'>
                  {item?.type === "drug order" ?
                    <div className='request-card-col-drugs'>
                      <div className='request-card-info-label'>
                        <Trans><Trans>Medicine</Trans>
                          {` (${item?.drugToOrders.length ?? 0} `}
                          <Trans>items</Trans>)
                        </Trans>
                      </div>
                      <div className='request-card-info-value'>
                        {item?.drugToOrders?.map((el, i) =>
                          <p key={`${i}-drug`}>{el.drug?.description}</p>
                        )}
                      </div>
                    </div>
                    :
                    RequestText(item)
                  }
                </div>
                <div className='request-card-col'>
                  {(!item?.reason || item?.reason === "") ?
                    '-'
                    :
                    item?.reason
                  }
                </div>
                <div className="request-card-col">
                  {item?.type !== "drug order" ?
                    (!item?.doctor_notice || item?.doctor_notice === "") ?
                      i18n.t("Without notice")
                      :
                      item?.doctor_notice
                    : item?.notice ?? i18n.t("Without notice")
                  }
                </div>
                <div className='request-card-col'>{dayjs(item?.date).format("DD.MM.YYYY HH:mm")}</div>
                <div className='request-card-col-btns'>
                  <StatusInfo statusId={item?.status_id}/>
                </div>
              </div>
            </div>
            <div className='request-card-body' key={`${i}card`}>
              <div className='request-card-col request-card-col-info'>
                <div className='request-card-info-label'>ID</div>
                <div className='request-card-info-value'>{item?.id}</div>
              </div>
              <div className='request-card-col request-card-col-info'>
                <div className='request-card-info-label'><Trans>Date / Time of sent request</Trans></div>
                <div className='request-card-info-value'>{dayjs(item?.created_at).format("DD.MM.YYYY hh:mm")}</div>
              </div>
              <div className='request-card-col'>
                <DoctorInfoBlockCol doctor={item?.type === "doctor transfer" ? item?.sender : item.doctor}
                                    appointmentType={item?.type}
                                    doctorType={item?.type === 'doctor transfer' ? 'Sender' : null}
                />
              </div>
              <div className="request-card-col request-card-col-info">
                <div className='request-card-info-label'>
                  <Trans>
                    {item?.type === "drug order" ?
                      'Urgency'
                      : 'Doctor Comment'
                    }
                  </Trans>
                </div>
                <div className='request-card-info-value'
                     style={item?.type === "drug order" ?
                       { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                       :
                       { color: '#343760' }}
                >
                  {item?.type === "drug order" ?
                    item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'
                    : (!item?.notice || item?.notice === "") ?
                      '-'
                      :
                      item?.notice
                  }
                </div>
              </div>
              {item?.type === "drug order" ?
                <div className='request-card-col-drugs'/>
                :
                <div className={`request-card-col ${item?.type === 'appointment' ? 'request-card-col-info' : ''}`}>
                  {item?.type === "doctor transfer" ?
                    <DoctorInfoBlockCol
                      doctor={item?.type === 'doctor transfer' && item?.doctor}
                      appointmentType={item?.type}
                      doctorType={item?.type === 'doctor transfer' ? 'Target' : null}
                      specialty={item?.type === 'doctor transfer' ? item?.specialty : null}
                    />
                    :
                    <>
                      <div className='request-card-info-label'>
                        <Trans>Urgency</Trans>
                      </div>
                      <div className='request-card-info-value'
                           style={item?.type !== "drug order" ?
                             { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                             :
                             { color: '#343760' }}
                      >
                        {item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'}
                      </div>
                    </>
                  }
                </div>
              }
              {item?.type === "doctor transfer" ?
                <div className='request-card-col-btns request-card-col-info'>
                  <div className='request-card-info-label'><Trans>Urgency</Trans></div>
                  <div className='request-card-info-value'
                       style={item?.type !== "drug order" ?
                         { color: item?.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.color.toString() : '#343760' }
                         :
                         { color: '#343760' }}
                  >
                    {item.priority_id && item.priority_id !== 4 ? UrgencyArray[item?.priority_id]?.label : 'None'}
                  </div>
                </div>
                : item?.type === "appointment" ?
                  <div className='request-card-col-btns request-card-col-info'/>
                  :
                  null
              }
            </div>
          </div>
        )
      }
      
      {(!!filteredItems.length && loadNext) &&
        <PaginationButton handleNext={handleNextAppointments}/>
      }
    </>
  );
}
