import "../../../Assets/Styles/ForPatients.css";
import '../../../Assets/Styles/Breadcrumbs.css';
import Header from "../../../Layouts/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ACTIONS, capitalizeFirstLetter } from "../../../Utils/constants";
import AppointmentStepChain from "../../../Components/AppointmentStepChain";
import ForPatientsDoctorInfo from "../ForPatientsDoctorInfo";
import { Trans, useTranslation } from "react-i18next";
import { ForPatientComponentKeys } from "../../../Utils/ConstantsForPatient";


export default function ChooseDoctor() {
  const { i18n } = useTranslation()
  const dispatch = useDispatch();
  
  const { breadcrumbs, data } = useSelector(state => state.bookAppointmentReducer);
  const specialties = useSelector(state => state.specialtyReducer.items);
  
  const [notice, setNotice] = useState(data?.notice || '');
  
  useEffect(() => {
    dispatch({
      type: ACTIONS.BOOK_APPOINTMENT.SET_BREADCRUMBS,
      payload: [
        {
          component: ForPatientComponentKeys.CLINIC,
          label: 'Clinic'
        },
        {
          component: ForPatientComponentKeys.DOCTOR,
          label: 'Doctor'
        },
        {
          component: ForPatientComponentKeys.CHOOSE_SPECIALIST,
          label: 'Specialist Transfer'
        }
      ]
    });
  }, [])
  
  return (
    <>
      <Header isHeaderHidden/>
      <main>
        <section className="sect">
          <div className='breadcrumbs'>
            {breadcrumbs.map((item, i) =>
              <div className='breadcrumbs-item' key={item + i}
                   onClick={() => {
                     dispatch({
                       type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                       payload: item.component
                     });
                   }}
              >
                {i18n.t(item.label)}
              </div>
            )}
          </div>
          <div className='for-patients-sect'>
            <ForPatientsDoctorInfo/>
            <AppointmentStepChain active='select-doctor' type='doctor-transfer'/>
            <div className='list'>
              <div className='list-heading'>
                <p>{i18n.t("Select Specialist")}</p>
                <div className='list-subtitle'>
                  {i18n.t("Please type your MD`s specialty and select it from the list")}
                </div>
              </div>
              <div className="input-container select-input for-patient-select">
                <select name="clinic" value={data?.specialist?.id}
                        onChange={(event) => {
                          dispatch({
                            type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                            payload: {
                              specialist: specialties.find(item => item?.id === parseInt(event.target.value))
                            }
                          });
                        }}
                >
                  <option value=""/>
                  {specialties.map(item =>
                    <option value={item?.id} key={item?.id}>
                      {capitalizeFirstLetter(i18n.t(item?.description))}
                    </option>
                  )}
                </select>
              </div>
              <div className='list-heading'>
                <p>{i18n.t("Reason for transfer")}</p>
                <div className='list-subtitle'>
                  {i18n.t("Please enter reason you need to be transferred")}
                </div>
              </div>
              <div className="input-container list-input">
                <label>
                  <Trans>{i18n.t("Request")}</Trans>
                </label>
                <input type="text" name="notice" maxLength="100" required value={notice}
                       onChange={(event) => setNotice(event.target.value)}
                />
              </div>
              <button className='for-patients-btn btn scale-btn' disabled={!data?.specialist || !notice}
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_DATA,
                          payload: {
                            notice: notice,
                            isRegistered: null,
                          }
                        });
                        dispatch({
                          type: ACTIONS.BOOK_APPOINTMENT.SET_COMPONENT,
                          payload: ForPatientComponentKeys.PATIENT_DETAILS
                        });
                      }}
              >
                {i18n.t("Continue")}
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
