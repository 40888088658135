import "dayjs/locale/de";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { partialApproval } from "../../Store/actions/appointmentActions";
import { StatusArray, theme } from "../../Utils/constants";

export default function PartialApprove({ item, pendingItems, setPendingItems, openDropdown, setOpenDropdown }) {
  const { i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const [date, setDate] = useState(new Date(item?.date));
  const [rejectMessage, setRejectMessage] = useState("");
  const [drugsList, setDrugsList] = useState(pendingItems.items.find(el => el.id === item.id)?.drugToOrders);
  
  const pendingDrugsList = pendingItems.items.find(el => el.id === item?.id)?.drugToOrders;
  
  //todo input label is not allowed here
  return (
    <div className="position-relative"
         style={
           openDropdown === item?.id ? { display: "block" } : { display: "none" }
         }
    >
      <div className="item-dropdown dropdown-reject">
        <div className="approve-cancel btn"
             onClick={(event) => {
               event.preventDefault();
          
               setOpenDropdown(null);
             }}
        >
          ✖
        </div>
        <div className="approve-heading">
          <div className="approve-subtitle">
            <Trans>Medicine</Trans>
          </div>
          <div className="approve-date-wrap">
            <div className="approve-date">
              <div className="approve-subtitle">
                <Trans>Choose date</Trans>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <ThemeProvider theme={theme}>
                  <DatePicker
                    views={['day']}
                    openTo={"year"}
                    inputFormat="DD.MM.YYYY"
                    disablePast
                    minDate={new Date(Date.now())}
                    value={date}
                    onChange={(value) => {
                      setDate(new Date(value));
                    }}
                    renderInput={(params) =>
                      <TextField {...params} helperText={null}/>
                    }
                  />
                </ThemeProvider>
              </LocalizationProvider>
            </div>
            <p className="dropdown-error">
              {date.getTime() < new Date(Date.now()).getTime() && i18n.t("Selected date has already passed")}
            </p>
          </div>
        </div>
        <div className="approve-checkboxes partial-approve-checkboxes">
          {pendingDrugsList?.map((item) =>
            <div>
              <label className="checkbox">
                <input type="checkbox" name="checkmark"
                       checked={drugsList.some(el => el.drug.id === item?.drug.id)}
                       onChange={(e) => {
                         if (!e.target.checked) {
                           setDrugsList(drugsList.filter(el => el.drug.id !== item?.drug.id))
                         }
                  
                         let inArr = drugsList.find(o => o.drug.id === item?.drug.id);
                  
                         if (e.target.checked && !inArr) {
                           setDrugsList([...drugsList, item])
                         }
                       }}
                       label={`${item?.drug.description}: ${item?.number}`}
                />
                <span className="checkmark"/>
              </label>
              <label className='checkbox-text'>{`${item?.drug.description}: ${item?.number}`}</label>
            </div>
          )}
        </div>
        <textarea
          name="reject_message"
          value={rejectMessage}
          maxLength="100"
          placeholder={`${i18n.t("Add notice")}...`}
          onChange={(event) =>
            setRejectMessage(event.target.value)
          }
        />
        <div className="dropdown-btns">
          <div className="dropdown-btns-column">
            <button className="success-btn dropdown-approve-btn btn scale-btn"
                    style={i18n.language === "de" ? { width: "180px" } : { width: "140px" }}
                    onClick={(event) => {
                      event.preventDefault();
              
                      const newDate = new Date(date);
              
                      if (drugsList.length !== pendingDrugsList.length) {
                        dispatch(partialApproval(
                          item?.id,
                          StatusArray.PART_APPROVE,
                          newDate,
                          rejectMessage,
                          drugsList,
                          pendingItems.items[0].user_id,
                          pendingItems,
                          setPendingItems,
                          i18n
                        ))
                      } else {
                        dispatch(partialApproval(
                          item?.id,
                          StatusArray.APPROVED,
                          newDate,
                          rejectMessage,
                          pendingDrugsList,
                          pendingItems.items[0].user_id,
                          pendingItems,
                          setPendingItems,
                          i18n
                        ))
                      }
              
                      setOpenDropdown(null);
                      setPendingItems({
                        items: pendingItems.items.filter((el) => el.id !== item.id),
                        filteredItems: pendingItems.filteredItems.filter((el) => el.id !== item.id)
                      })
                    }}
            >
              <Trans>Approve Selected</Trans>
            </button>
            <button className="success-btn dropdown-approve-btn approve-accept-btn w-110 btn scale-btn"
                    onClick={(event) => {
                      event.preventDefault();
              
                      const newDate = new Date(date);
              
                      dispatch(partialApproval(
                        item?.id,
                        StatusArray.APPROVED,
                        newDate,
                        rejectMessage,
                        pendingDrugsList,
                        pendingItems.items[0].user_id,
                        pendingItems,
                        setPendingItems,
                        i18n
                      ))
              
                      setOpenDropdown(null);
                      setPendingItems({
                        items: pendingItems.items.filter((el) => el.id !== item.id),
                        filteredItems: pendingItems.filteredItems.filter((el) => el.id !== item.id)
                      })
                    }}
            >
              <Trans>Accept All</Trans>
            </button>
          </div>
          <div
            className="dropdown-reject-btn btn scale-btn"
            style={i18n.language === "de" ? { width: "140px" } : { width: "111px" }}
            onClick={() => {
              const newDate = new Date(date);
              
              dispatch(partialApproval(
                item?.id,
                StatusArray.CANCELED,
                newDate,
                rejectMessage,
                pendingDrugsList,
                pendingItems.items[0].user_id,
                pendingItems,
                setPendingItems,
                i18n
              ))
              
              setOpenDropdown(null);
              setPendingItems({
                items: pendingItems.items.filter((el) => el.id !== item.id && item.type === el.type),
                filteredItems: pendingItems.filteredItems.filter((el) => el.id !== item.id && item.type === el.type)
              })
            }}
          >
            <Trans>Reject All</Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
