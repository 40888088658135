import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/ManageOffice.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import Header from "../../Layouts/Header/Header";
import Loading from "../../Layouts/Loading/Loading";
import { ActiveBtn } from "../../Components/ActiveBtn";
import { getClinicById, updateClinicHoliday } from "../../Store/actions/clinicActions";
import { PencilImg } from "../../Assets/Images/img";
import ValidationError from "../../Components/ValidationError";
import { ACTIONS, RoleArray } from "../../Utils/constants";
import { WorkingStatusOnHoliday, WorkingStatusWork } from "../../Utils/ValidationSchemas";

export default function ClinicSettings() {
  const { i18n } = useTranslation();
  
  const { uuid } = useParams();
  
  const dispatch = useDispatch();
  
  const formikRef = useRef();
  
  const user = useSelector(state => state.userReducer.user);
  const mangersClinic = useSelector(state => state.userReducer.user?.clinic_id_clinics_clinicEmployees)
  const clinic = useSelector(state => state.clinicReducer.clinic)
  
  const [isWorking, setIsWorking] = useState(clinic?.is_working);
  
  useEffect(() => {
    if (user?.role === RoleArray.SUPER_ADMIN) {
      dispatch(getClinicById(uuid, i18n))
    }
    
    if (mangersClinic) {
      dispatch(getClinicById(mangersClinic[0]?.uuid, i18n))
    }
  }, [uuid, mangersClinic]);
  
  if (!clinic) {
    return <Loading/>
  }
  
  return (
    <>
      <Header/>
      <main>
        <section className="sect manage-patient-sect">
          <div className="manage-patient-heading manage-office-heading">
            <h1>
              {user.role === RoleArray.SUPER_ADMIN ?
                `${clinic.name} ${i18n.t("Clinic Settings")}`
                :
                i18n.t("Clinic Settings")
              }
            </h1>
            <div className="manage-patient-buttons_group">
              <div>
                <h3 className="manage-patient-buttons_heading mb-20"><Trans>On holiday</Trans></h3>
                <div className="manage-patient-buttons">
                  <div
                    id="yes"
                    className={`manage-patient-btn manage-patient-btn-50 ${!clinic?.is_working ? "active-btn" : null}`}
                    onClick={(event) => {
                      ActiveBtn(event);
                      setIsWorking(false);
                    }}
                  >
                    <Trans>Yes</Trans>
                  </div>
                  <div
                    id="no"
                    className={`manage-patient-btn manage-patient-btn-50 ${clinic?.is_working ? "active-btn" : null}`}
                    onClick={(event) => {
                      ActiveBtn(event);
                      setIsWorking(true);
                    }}
                  >
                    <Trans>No</Trans>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  working_status: clinic?.working_status
                }}
                innerRef={formikRef}
                validationSchema={!isWorking ? WorkingStatusOnHoliday : WorkingStatusWork}
                onSubmit={(values) => {
                  if (!(values.working_status === clinic?.working_status && clinic?.is_working === isWorking)) {
                    dispatch({
                      type: ACTIONS.CLINIC.SET_CLINIC,
                      payload: {
                        ...clinic,
                        is_working: isWorking,
                        working_status: values.working_status
                      }
                    });
                    if (isWorking) {
                      dispatch(updateClinicHoliday(clinic?.id, clinic?.uuid, isWorking, "", i18n, formikRef))
                    } else {
                      dispatch(updateClinicHoliday(clinic?.id, clinic?.uuid, isWorking, values.working_status, i18n, formikRef))
                    }
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="manage-form-col">
                    {!isWorking &&
                      <div className="input-container manage-patient-input">
                        <label><Trans>Description</Trans></label>
                        <Field
                          className="holiday-input"
                          type="text"
                          name="working_status"
                          maxLength="100"
                        />
                        <ValidationError errors={errors.working_status} touched={touched.working_status}/>
                        <img alt="pencil" className="input-img-right" src={PencilImg}/>
                      </div>
                    }
                    <div className="button-container manage-btn m-0">
                      <input type="submit"
                             value={i18n.t("Save Changes")}
                             className="submit-form-button scale-btn"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}