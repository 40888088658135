import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ACTIONS } from "../Utils/constants";

export default function ToggleSwitch({ element, checked }) {
  const { i18n } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  let isChecked = checked;
  
  const dispatch = useDispatch();
  
  const handleChange = () => {
    if (!isChecked) {
      setIsActive(!isActive);
      dispatch({
        type: ACTIONS.DOCTOR.ADD_TO_DOCTOR_APPOINTMENT_TYPES_LIST,
        payload: element
      });
    } else {
      dispatch({
        type: ACTIONS.DOCTOR.REMOVE_FROM_DOCTOR_APPOINTMENT_TYPES_LIST,
        payload: element
      });
    }
  };
  
  return (
    <>
      <label className="toggle-label">
        <div className="non-copy">{i18n.t(element.description)}</div>
        <div className={`toggle-switch ${checked ? 'active' : ''}`}
             onClick={() => handleChange()}
        >
          <div className="switch big-switch"/>
        </div>
      </label>
    </>
  );
}