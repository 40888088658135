import { ACTIONS } from "../../Utils/constants";
import { InviteClinicComponentKeys } from "../../Utils/ConstantsInviteClinic";

const initialState = {
  component: InviteClinicComponentKeys.WELCOME_CLINIC,
  data: {
    clinicInfo: {
      clinicPhoto: "",
      name: "",
      slogan: "",
      location: "",
      email: "",
      telephone: "",
      bsnr: "",
      website: "",
      description: "",
      workingHours: null,
    }
  }
};


const inviteClinicReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.INVITE_CLINIC.SET_CLINIC_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    }
    case ACTIONS.INVITE_CLINIC.SET_CLINIC_COMPONENT: {
      return {
        ...state,
        component: action.payload
      }
    }
    
    default:
      return state;
  }
};

export default inviteClinicReducer;
