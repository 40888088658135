import "../../Assets/Styles/ManagePatient.css";
import "../../Assets/Styles/PatientInfo.css";
import "dayjs/locale/de";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import SearchNoFound from "../../Layouts/SearchNoFound/SearchNoFound";
import { useNavigate } from "react-router-dom";
import { EmptyUserProfileImgForTable } from "../../Assets/Images/img";
import { RoleArray } from "../../Utils/constants";
import React from "react";


export default function PatientsInfoDoctors() {
  const navigate = useNavigate();
  
  const role = useSelector((state) => state.userReducer.user.role);
  const doctors = useSelector(state => state.patientReducer?.patientAdditionalInfo.doctors);
  
  return (
    <>
      <div className="manage-patient-table">
        <div className="table-heading"
             style={!doctors.length ? { borderBottom: "1px solid rgba(0, 0, 0, 0.175)" } : { border: "none" }}
        >
          <div className="table-col-user">
            <Trans>Doctor</Trans>
          </div>
          <div className="table-col">
            <Trans>Primary Specialty</Trans>
          </div>
          <div className="table-col">
            <Trans>Secondary Specialty</Trans>
          </div>
          <div className="table-col"/>
        </div>
        {!doctors.length ?
          <SearchNoFound/>
          :
          doctors.map((doctor, i) =>
            <div className='card' key={`${doctor?.user_id}-doctor`}>
              <div className='card-header'>
                <div className="table-col-user"
                     style={role === RoleArray.SUPER_ADMIN ? { cursor: "pointer" } : { cursor: "auto" }}
                     onClick={() => {
                       if (role === RoleArray.SUPER_ADMIN) {
                         navigate(`/doctors/${doctor?.user_id}/edit`)
                       }
                     }}
                >
                  <img alt='avatar'
                       src={doctor?.user.photo_url || EmptyUserProfileImgForTable(i)}
                  />
                  <div className="table-col ml-10">
                    <div>{doctor?.publicName}</div>
                  </div>
                </div>
                <div className="table-col">
                  {doctor?.specialtyToDoctors.map((item) =>
                    item?.is_main && <div>{item?.specialty?.description}</div>
                  )}
                </div>
                <div className="table-col">
                  {doctor?.specialtyToDoctors.map((item) =>
                    !item?.is_main && <div>{item?.specialty?.description}</div>
                  )}
                </div>
                <div className="table-col"/>
              </div>
            </div>
          )}
      </div>
    </>
  )
}
