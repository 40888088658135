export const ActiveBtn = event => {
  const elements = document.getElementsByClassName("active-btn");
  for (let el of elements) {
    el.classList.remove("active-btn")
  }
  event.currentTarget.classList.add("active-btn");
}

export const ActiveLangBtn = event => {
  const elements = document.getElementsByClassName("active-lang-button");
  for (let el of elements) {
    el.classList.remove("active-lang-button")
  }
  event.currentTarget.classList.add("active-lang-button");
}

export const ActiveTab = (event = null, id = null) => {
  const elements = document.getElementsByClassName("active-tab");
  for (let el of elements) {
    el.classList.remove("active-tab")
  }
  
  if (id) {
    document.getElementById(id).classList.add("active-tab");
  } else {
    event.currentTarget.classList.add("active-tab");
  }
}